import React from 'react';
import RoutingModules from './components/RoutingModules';

import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/style.css';
import './styles/Common.css';

function App() {
  return (
    <RoutingModules />
  );
}

export default App;
