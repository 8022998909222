import React from "react";
import Title from "./Title";
import ClientOne from "../../images/client-1.png";
import ClientTwo from "../../images/client-2.png";
import ClientThree from "../../images/client-3.png";
import { NavLink } from "react-router-dom";
import { Fade } from "react-reveal";
import Linedin from "../../images/Linkedin.png"

const OurClients_Components = () => {
    return (
        <>
            <section id="sec-5">
                <div className="sec-5-main ">
                    <div className="section-title">
                        <Title Title="Our Respected Clients" Description=" " />
                    </div>
                </div>

                <div className="container OurClients">
                    <div className="col-xl-10 col-lg-10 col-md-12 col-sm-12 mx-auto">
                        <div className="row justify-content-between">
                            {Data.map((key, index) => {
                                return (
                                    <Fade key={index}>
                                        <div className={key.Class} >
                                            <div className="card-header">
                                                <img className="card-img-top" src={key.Image} alt="Card image cap" height="180px" />
                                            </div>

                                            <div className="card-body  sec-5-title">
                                                <div className="row justify-content-between">
                                                    <div className="col-10">
                                                        <h5 className="card-title fw-bold">{key.ClientName} </h5>
                                                    </div>
                                                    <div className="col-2">
                                                    <a href={key.linkedin} target="_blank"> <img src={Linedin} alt="linkedin" className="img-fluid " height="20px" /></a>
                                                    </div>

                                                </div>

                                                <p className="card-text sec-5-subtitle mt-2">{key.Para}</p>

                                            </div>
                                        </div>
                                    </Fade>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
export default OurClients_Components;


const Data = [
    {
        Image: ClientOne,
        Class: "col-xl-3 col-lg-4 col-md-5 col-sm-10 px-0 sec-5-card cent my-3 card ",
        ClientName: 'Mr. M V Sreenivas',
        Para: 'It is a real pleasure to get into this platform of Trade Reboot and the procedure involved in getting the approvals are really hassle free procedures and I am amazed that the time taken to get this process sanctioned is absolutely much much better than other financial services.',
        linkedin: 'https://www.linkedin.com/in/m-v-sreenivas-6b982137/',
    },
    {
        Image: ClientTwo,
        Class: "col-xl-3 col-lg-4 col-md-5 col-sm-10 px-0 sec-5-card cent my-3 card",
        ClientName: 'Mr. Aditya Bhoot',
        Para: `Very user friendly platform. Most of the information is easily accessible. No need to followup with team as the required information is timely updated.`,
        linkedin: 'https://www.linkedin.com/in/aditya-bhoot-5a493715/',
    },
    {
        Image: ClientThree,
        Class: "col-xl-3 col-lg-4 col-md-5 col-sm-10 px-0 sec-5-card align-self-end cent my-3 card",
        ClientName: 'Mr. Sekar Kandaswami',
        Para: 'We have been working with you few days only &  the experience was good with you @ TradeReboot.',
        linkedin: '',
    },
];
