import React from "react";
import CompAdavatage_Component from "../CompAdavtage_Component";
import Benefits_Component from "./Benefits_Component";
import InsureBanner_Component from "./InsureBanner_Component";
import Document_Component from "../Document_Component";
import ImageTwo from "../../../images/Insure-tech/Image-1.png";
import ImageOne from "../../../images/Insure-tech/Image-2.png";
import EasySteps_Component from "./EasySteps_Component";

const List1 = [
    "Tradereboot is a global leader in insurance broking and innovative risk management solutions, awarded by Global Trade Review as the winner for the Best Credit and Political Risk Global Broker Award in 2020 and Leaders in Trade for Sustainability Award in 2021.",
    "Insure Tech is a software platform that connects insurance buyers, brokers and reinsurers and supports the placement, administration and servicing of specialty insurance contracts.",
    "Our aim is to streamline the insurance-buying process, increasing transparency and efficiency for all involved, as well as substantially reducing admin costs."
];


const List2 = [
    "With a global network of 400 dedicated and award-winning trade credit specialists spanning across 52 countries.",
    "Tradereboot Specialty Trade Credit Practice has experts who understand the culture, language, regulations, industry practices and economic landscape in every country where business is established to help companies manage receivables risk by providing access to credit insurance solutions.",
    "For multinational companies, our internationally coordinated Global Client Group’s team facilitates credit placements across the 52 countries."
];

const InsureTechLanding_Component = () => {
    return (
        <>
            <InsureBanner_Component />
            <Benefits_Component />
            <CompAdavatage_Component CompImage={ImageTwo} Data_List={List1}>
                <h2 className="fw-bold text-capitalize mb-4">Recognized Practice <span className="text-info"> Credit Specialty</span> in the industry </h2>
            </CompAdavatage_Component>
            <Document_Component Image={ImageOne} Data_List={List2}>
                <h2 className="fw-bold text-capitalize mb-4">  <span className="text-info">Global footprint</span> to enable ease of business</h2>
            </Document_Component>
            <EasySteps_Component />
        </>
    );
}
export default InsureTechLanding_Component;