import React from "react";


const CardsManage_Component = ({CardImage, Heading, Paragraph,ClassName}) => {
    return (
        <>
            <div className="col-xl-6 col-lg-6 col-sm-10 col-md-10 mx-auto my-4">
                <div className={ClassName}>
                    <div className="card-header CardHeader ">
                        <img src={CardImage} alt="Image" height="360px" width="360px" />
                    </div>
                    <div className="card-body InsureCardBody">
                        <h4 className="fw-bold text-capitalize">{Heading}</h4>
                        <p>{Paragraph}</p>
                    </div>
                </div>
            </div>
        </>
    );
}
export default CardsManage_Component;