import React from "react";
import { Link } from "react-router-dom";
import CommentImg from "../../../images/News Blog Page/Comment-img.png";
import CommentSent from "../../../images/News Blog Page/Comment-sent.svg";

const Comment_Component = ({ name, date, comment, collapseid }) => {
  return (
    <>
      <div className="d-flex  border-bottom mb-4">
        <div>
          <img src={CommentImg} alt="" />
        </div>
        <div className="ms-3">
          <p className="mb-0 fw-bold">{name}</p>
          <small className="text-info">{date}</small>
          <p className="mt-2">{comment}</p>

          <p>
            <Link
              className="text-decoration-none text-info"
              data-bs-toggle="collapse"
              to={`#${collapseid}`}
              role="button"
              aria-expanded="false"
              data-bs-target={`#${collapseid}`}
            >
              Reply
            </Link>
          </p>
          <div className="collapse mb-5" id={collapseid}>
            <div className="card card-body ">
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <p className="mb-0">Add a comment...</p>
                </div>
                <div>
                  <img src={CommentSent} alt="" height="20px" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Comment_Component;
