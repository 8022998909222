import React from "react";
import { NavLink } from "react-router-dom";
import Banner from '../../images/Home1.png';
import { Fade } from "react-reveal";

const Banner_Component = () => {
    return (
        <>
            <div id="HomeBg" className="container-fluid pb-3">
                <div className="container p-3">
                    <div className="row sec-cen">
                        <Fade left>
                            <div className="col-xl-7 col-lg-7 col-sm-10 col-md-10 home-sec m-auto">
                                <h1>Digital Ecosystem for <span style={{ color: "#65E4FD" }}>International Commodity</span> Trade</h1>
                                <h5 className="head-but">Tap into the worlds largest <span style={{ color: "#65E4FD" }}>one stop blockchain network</span> for commodity trade</h5>
                                <div className="  head-but pt-4">
                                    <NavLink to="/coming_soon">
                                        <button className="btn Seemore my-2 my-sm-0 ml-3" type="submit">Market place</button></NavLink>
                                    <NavLink to="/solutions">
                                        <button className="btn Seemore-outline my-2 my-sm-0" type="submit">Solution</button></NavLink>
                                </div>
                            </div>
                        </Fade>
                        <Fade right>
                            <div className="col-xl-5 col-lg-5 col-sm-10 col-md-10 home-img">
                                <img src={Banner} width="550" height="550" alt="Banner Image" className="img-fluid" />
                            </div>
                        </Fade>
                    </div>
                </div>
            </div>
        </>
    );
}
export default Banner_Component;