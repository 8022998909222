/* eslint-disable react/jsx-pascal-case */
import React from "react";
import {
    HashRouter as Router,
    Route,
    Switch,
    Redirect,
    BrowserRouter,
} from "react-router-dom";
import Footer_Component from "./Footer/Footer_Component";
import HomeComponent from "./Home";
import HeaderNav_Component from "./Header/HeaderNav_Component";
import AboutUsMain_Component from "./About Us/AboutUsMain_Component";
import MarketPlace_Component from "./Home/MarketPlace_Component";
// import Shipping_Component from "./MarketPlace/Shipping_Component";
import BookAppoinmentLanding_Component from "./Home/BookAppointment/BookAppointmentLanding_Component";
import ContactUsLanding_Component from "./Contact Us/ContactUsLanding_Component";
import NewEventsMain_Component from "./NewsEvents/NewEventsMain_Component";
import BlogTemplates_Component from "./Home/BlogTemplates_Component";
import SolutionsLanding_Component from "./solutions/SolutionsLanding_Component";
import LogiLanding_Component from "./solutions/Logi-Tech-component/LogiLanding_Component";
import InsureTechLanding_Component from "./solutions/Insure-Tech-component/InsureTechLanding_Component";
import InspectLanding_Component from "./solutions/Inspect-Tech-component/InspectLanding_Component";
import FinTechLanding_Component from "./solutions/Fin-Tech-component/FinTechLanding_Component";
// import InsuranceMainLanding_Component from "./MarketPlace/Insurance/InsuranceMainLanding_Component";
import InsuranceMainLanding_Component from "./MarketPlaceLatest/Insurance/Insurance_Main_Component"
// import Insurance_Main_Component from "./MarketPlaceLatest/Insurance/Insurance_Main_Component";
import HelpDeskLanding_Component from "./Help Desk/HelpDeskLanding_Component";
import PageNotFound_Component from "./Page-Not found/PageNotFound_Component";
import SolutionLanding_Component from "./solutions/Solution-Page-component/SolutionLanding_Component";
import TradetechLanding_Component from "./solutions/Trade-Tech-component/TradetechLanding_Component";
import MarketPlaceLMain_Component from "./MarketPlaceLatest/MarketPlaceLMain_Component";
import BlogPageMain_Component from "./NewsEvents/BlogPage_Component/BlogPageMain_Component";
import ComingSoon_Component from "./Coming_Soon/Coming_Soon";
import Inspection_Landing_Component from "./MarketPlaceLatest/Inspection/Inspection_Landing_Component";

const RoutingModules = () => {
    return (
        <>
            <BrowserRouter basename="/">
                {/* <HeaderNav_Component /> */}
                <Switch>
                    <Route exact path="/">
                        <Redirect to="/home" />
                    </Route>
                    <Route exact path="/home">
                        <HeaderNav_Component />
                        <HomeComponent />
                    </Route>
                    <Route path="/home/solution">
                        <HeaderNav_Component />
                        <SolutionsLanding_Component />
                    </Route>
                    <Route path="/home/book-appointment">
                        <HeaderNav_Component />
                        <BookAppoinmentLanding_Component />
                    </Route>

                    <Route path="/home/template">
                        <HeaderNav_Component />
                        <BlogTemplates_Component />
                    </Route>

                    <Route path="/about-us">
                        <HeaderNav_Component />
                        <AboutUsMain_Component />
                    </Route>
                    <Route exact path="/market-place">
                        <HeaderNav_Component />
                        <MarketPlace_Component />
                    </Route>
                    <Route path="/market-place/shipping">
                        <HeaderNav_Component />
                        <MarketPlaceLMain_Component />
                    </Route>
                    <Route path="/market-place/insurance">
                        <HeaderNav_Component />
                        <InsuranceMainLanding_Component />
                    </Route>
                    <Route path="/market-place/inspection">
                        <HeaderNav_Component />
                        <Inspection_Landing_Component />
                    </Route>
                    {/* <Route path="/marketplace/insurance">
                        <HeaderNav_Component />
                        <Insurance_Main_Component />
                    </Route> */}
                    <Route exact path="/solutions">
                        <HeaderNav_Component />
                        <SolutionLanding_Component />
                    </Route>
                    <Route path="/solutions/logi-tech">
                        <HeaderNav_Component />
                        <LogiLanding_Component />
                    </Route>
                    <Route path="/solutions/insure-tech">
                        <HeaderNav_Component />
                        <InsureTechLanding_Component />
                    </Route>
                    <Route path="/solutions/fin-tech">
                        <HeaderNav_Component />
                        <FinTechLanding_Component />
                    </Route>
                    <Route path="/solutions/inspect-tech">
                        <HeaderNav_Component />
                        <InspectLanding_Component />
                    </Route>
                    <Route path="/solutions/trade-tech">
                        <HeaderNav_Component />
                        <TradetechLanding_Component />
                    </Route>
                    <Route exact path="/NewEvents">
                        <HeaderNav_Component />
                        <NewEventsMain_Component />
                    </Route>
                    <Route path="/NewEvents/blog-events">
                        <BlogPageMain_Component />
                    </Route>
                    <Route exact path="/contact-us">
                        <HeaderNav_Component />
                        <ContactUsLanding_Component />
                    </Route>
                    <Route path="/help-desk">
                        <HeaderNav_Component />
                        <HelpDeskLanding_Component />
                    </Route>
                    <Route path="/coming_soon">
                        <HeaderNav_Component />
                        <ComingSoon_Component />
                    </Route>
                </Switch>
                <Route path="/page-not-found">
                    <PageNotFound_Component />
                </Route>

                <Footer_Component />
            </BrowserRouter>
        </>
    );
};
export default RoutingModules;
