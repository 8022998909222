/* eslint-disable react/jsx-pascal-case */
import React from "react";
import CarosuelItem_Component from "./CarosuelItem_Component";
import Image1 from "../../../images/Fintech/paperless-process.png";
import Image2 from "../../../images/Fintech/cost-reduction.png";
import Image3 from "../../../images/Fintech/document-verification.png";
import Image4 from "../../../images/Fintech/Kyc.png";
import Image5 from "../../../images/Fintech/digital-sign.png";
import Image6 from "../../../images/Fintech/monitoring.png";

const para1 =
  "The main cost associated with trade finance lies in document handling. With digitalization, this cost is expected to come down drastically (e.g. lower cost of storage), making it possible for banks to bring down the cost, as well as processing time of trade finance substantially. – Digitizing the process will also allow better record keeping as paperwork is less likely to be misplaced.";

const para2 =
  "Blockchain technology has the potential to achieve efficiencies in transactional lifecycle of trade finance products. This is expected to help banks in reducing turnaround time as well as operational costs (reduced compliance costs, elimination of paper, improving TAT).";

const para3 =
  "Distributed ledger allows for real time confirmation, authentication, and monitoring across multiple participants like banks, shippers, insurers, regulators and importer/ exporters. – Operational risk will be lowered, from standardized, objective and rule-based automation. – Manual handling and duplication of verification processes across importer and exporters banks, will also be avoided. ";

const para4 =
  "There is a digital identity generated from the importer, exporter, shipper etc. This will increase creditworthiness of the participants of the trade, reducing counterparty risk. – We provide third party  KYC and over a period of time, there will be plenty of past data available on the various parties involved. ";

const para5 =
  "Smart contracts (automated contracts with digital signatures) will trigger periodic payments to shippers and insurance companies in real time as the prerequisites of the contract are fulfilled. The Digital signature provides legal enforceability for real time information flow between banks. – This provides a seamless automated integration between systems unlike current fragmented systems, and reduces manual intervention in release of payments. – The smart contract will automatically trigger red flags to meet various compliance requirements such as financial crime, AML, etc. ";

const para6 =
  "Blockchain creates an immutable record of transactions. This helps local regulators track transactions and receive reports on AML and Antiterrorist financing purposes. – This automatic process reduces the cost of regulatory adherence and reporting. An audit trail of transactions is established which allows the source and end use of the funds to be tracked. This helps in AML compliance.";

const FeaturesCarosuel_Component = () => {
  return (
    <>
      <div className="container-fluid ">
        <div className="py-5 text-center">
          <h3 className="fw-bold">Features</h3>
        </div>
        <div className="col-xl-9 col-md-10 col-lg-7 col-sm-10 mx-auto">
          <div
            id="carouselExampleControls"
            className="carousel slide"
            data-bs-ride="carousel"
          >
            <div className="carousel-inner">
              <div className="carousel-item active ">
                <CarosuelItem_Component
                  Heading="Paperless Process"
                  Paragraph={para1}
                  CarosuelImage={Image1}
                />
              </div>
              <div className="carousel-item">
                <CarosuelItem_Component
                  Heading="Cost reduction "
                  Paragraph={para2}
                  CarosuelImage={Image2}
                />
              </div>
              <div className="carousel-item">
                <CarosuelItem_Component
                  Heading="Document verification and authentication "
                  Paragraph={para3}
                  CarosuelImage={Image3}
                />
              </div>
              <div className="carousel-item">
                <CarosuelItem_Component
                  Heading="KYC "
                  Paragraph={para4}
                  CarosuelImage={Image4}
                />
              </div>
              <div className="carousel-item">
                <CarosuelItem_Component
                  Heading="Smart contracts with digital signatures"
                  Paragraph={para5}
                  CarosuelImage={Image5}
                />
              </div>
              <div className="carousel-item">
                <CarosuelItem_Component
                  Heading="Monitoring Transactions and Regulatory reporting "
                  Paragraph={para6}
                  CarosuelImage={Image6}
                />
              </div>
            </div>
            <button
              className="carousel-control-prev"
              type="button"
              data-bs-target="#carouselExampleControls"
              data-bs-slide="prev"
            >
              <span
                className="iconify carousel-control-prev-icon text-dark"
                data-icon="bi:arrow-left-circle"
                aria-hidden="true"
              ></span>
            </button>
            <button
              className="carousel-control-next"
              type="button"
              data-bs-target="#carouselExampleControls"
              data-bs-slide="next"
            >
              <span
                className=" iconify carousel-control-next-icon text-dark"
                data-icon="bi:arrow-right-circle"
                aria-hidden="true"
              ></span>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
export default FeaturesCarosuel_Component;
