import React from "react";
import { Fade } from "react-reveal";
import LogiTechImage from "../../../images/INSURE-TECH-IMG.gif";

const BannerPara = "Leveraging Tradereboot longstanding global relationships with insurers, Tradereboot Specialty Trade Credit Practice can provide insurance terms and develop program structures that maximize your protection against uninsured non-payment. In addition, our multinational service team provides claims management and advocacy services that help to ensure compliance with local insurance laws.";

const InsureBanner_Component = () => {
    return (
        <>

            <div className="container-fluid p-0" id="InsureBanner-Bg" >
                <div className="col-xl-10 col-md-10 col-lg-10 col-sm-10 mx-auto py-5 ">
                    <div className="row">
                        <div className="col-xl-6 col-lg-6 col-md-10 col-sm-10 m-auto text-center my-5">
                            <Fade left>
                                <img src={LogiTechImage} alt="Competetive Image" className="img-fluid" />
                            </Fade>
                        </div>
                        <Fade right>
                            <div className="col-xl-6 col-lg-6 col-md-10 col-sm-10 m-auto">
                                <h4 className="text-light fw-bold text-uppercase">Insure-Tech</h4>
                                <h3 className="text-light fw-bold"><span className="TextColor">Longstanding </span>carrier relationships</h3>
                                <ul className="ListStyleImage mt-4">
                                    <li className="text-light fs-5">Tradereboot Insure-Tech enables the Insurance Agency to process and approve insurance applications, risk analysis, tracking of trade process, receive claims & verification of claims and settlements.</li>
                                    {/* <li className="text-light fs-5 mt-3">In addition, our multinational service team provides claims management and advocacy services that help to ensure compliance with local insurance laws.</li> */}
                                </ul>
                                <div className="pt-4">
                                    <a href="https://tech.tradereboot.com/login" target="_blank"> <button className="btn Seemore my-2 my-sm-0 ml-3" type="submit">Get Started</button></a>
                                </div>
                            </div>
                        </Fade>

                    </div>
                </div>
            </div>
        </>
    );
}
export default InsureBanner_Component;