import React from "react";
import { Fade } from "react-reveal";
import LogiTechImage from "../../../images/TRADE-TECH-IMG.gif";

const TradeTechBanner_Component = () => {
    return (
        <>
            <div className="container-fluid p-0 " id="TradeTech-Bg" >
                <div className="col-xl-10 col-md-10 col-lg-10 col-sm-10 mx-auto py-5">
                    <div className="row">
                        <Fade left>
                            <div className="col-xl-6 col-lg-6 col-md-10 col-sm-10 m-auto ">
                                <h4 className="text-light fw-bold text-uppercase">Trade-Tech</h4>
                                <h3 className="text-light fw-bold">End to End<span className="TextColor">  Integrated Solution </span>  for Traders</h3>
                                <p className="text-light fs-5">TradeReboot Trade-Tech enables the buyers and sellers to create contract between themselves, adopt payment method and if require apply for it, manage networks (partner seller or buyers), tracking goods, get shipping & inspection services, get insurance services</p>
                                {/* <ul className="ListStyleImage mt-4">
                                    <li className="text-light fs-5">TradeReboot Trade-Tech enables the buyers and sellers to create contract between themselves, adopt payment method and if require apply for it, manage networks (partner seller or buyers), tracking goods, get shipping & inspection services, get insurance services</li>
                                </ul> */}
                                <div className="pt-4">
                                    <a href="https://tech.tradereboot.com/login" target="_blank"> <button className="btn Seemore my-2 my-sm-0 ml-3" type="submit">Get Started</button></a>
                                </div>
                            </div>
                        </Fade>

                        <div className="col-xl-6 col-lg-6 col-md-10 col-sm-10 text-center m-auto my-3">
                            <Fade right>
                                <img src={LogiTechImage} alt="Competetive Image" className="img-fluid" />
                            </Fade>
                        </div>
                    </div>
                </div>
                <div className="container-fluid pb-5 " style={{ marginTop: "-50px", }}>
                    <div className="row">
                        <Fade left>
                            <div type="button" className="col-xl-2 col-lg-2  col-md-4 col-sm-4 text-center me-3 p-2 buttonOne my-2">
                                <p className="mb-0 fs-5">12</p>
                                <p>Total Contract</p>
                            </div>
                        </Fade>
                        <Fade cascade>
                            <div type="button" className="col-xl-2 col-lg-2 col-md-4 col-sm-4 text-center p-2 me-3 bg-success text-light my-2 ">
                                <p className="mb-0 fs-5">0</p>
                                <p>Contract Completed</p>
                            </div>
                        </Fade>
                        <Fade cascade>
                            <div type="button" className="col-xl-2 col-lg-2 col-md-4 col-sm-4  text-center p-2 me-3 buttonThree my-2">
                                <p className="mb-0 fs-5">12</p>
                                <p>Total Contract</p>
                            </div>
                        </Fade>
                        <Fade right>
                            <div type="button" className="col-xl-2 col-lg-2  col-md-4 col-sm-4  text-center p-2 me-3 buttonFour my-2">
                                <p className="mb-0 fs-5">12</p>
                                <p>Total Contract</p>
                            </div>
                        </Fade>
                    </div>
                </div>

            </div>

        </>
    );
}
export default TradeTechBanner_Component;