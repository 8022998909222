import React from "react";
import { Fade } from "react-reveal";

const Document_Component = ({ Image, Data_List, children }) => {
    return (
        <>
            <div className="container my-5">
                <div className="row">
                    <Fade left>
                        <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 scrollbar2 m-auto" id="style-4">
                            {children}
                            <p>{Data_List}</p>
                            {/* <ul className="ListStyleImage  force-overflow ">
                                {Data_List.map((key, index) => {
                                    return (
                                        <li className="mt-3" key={index}>{key}</li>
                                    );
                                })}
                            </ul> */}
                        </div>
                    </Fade>
                    <Fade right>
                        <div className="col-xl-4 col-lg-4 col-sm-12 col-md-4 Doc-Img">
                            <img src={Image} alt="Image" className="img-fluid" />
                        </div>
                    </Fade>

                </div>
            </div>
        </>
    );
}

export default Document_Component;

