import React from "react";
import SelectInput_Component from "../../Home/BookAppointment/SelectInput_Component";
import TextInput_Component from "../../Home/BookAppointment/TextInput_Component";

const GstTradedetails_Component = ({ taxDetails }) => {
    return (
        <>
            <h6 className="mx-4 my-4">GST Total Details:</h6>
            <div className="row">
                <div className="col-xl-3 col-lg-3 col-md-6 col-sm-10 ">
                    <TextInput_Component Type="number" placeholder="Total IGST Amount" label="Total IGST Amount" id="totalIGSTAmount" name="totalIGSTAmount" value={taxDetails.gstTotalDetails.totalIGSTAmount} onChange="" astrix="" disabledText='true' />
                </div>
                <div className="col-xl-3 col-lg-3 col-md-6 col-sm-10 ">
                    <TextInput_Component Type="number" placeholder="Total GST Amount" label="Total GST Amount" id="totalGSTAmount" name="totalGSTAmount" value={taxDetails.gstTotalDetails.totalGSTAmount} onChange="" astrix="" disabledText='true' />
                </div>
                <div className="col-xl-3 col-lg-3 col-md-6 col-sm-10 ">
                    <TextInput_Component Type="number" placeholder="Total Tax Block Level" label="Total Tax Block Level" id="totalTaxBlockLevel" name="totalTaxBlockLevel" value={taxDetails.gstTotalDetails.totalTaxBlockLevel} onChange="" astrix="" disabledText='true' />
                </div>
                <div className="col-xl-3 col-lg-3 col-md-6 col-sm-10 ">
                    <TextInput_Component Type="number" placeholder="Final Amount Block Level" label="Final Amount Block Level" id="finalAmountBlockLevel" name="finalAmountBlockLevel" value={taxDetails.gstTotalDetails.finalAmountBlockLevel} onChange="" astrix="" disabledText='true' />
                </div>
            </div>
        </>
    );
}
export default GstTradedetails_Component;