/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { NavLink } from "react-router-dom";
import Logo from "../../images/LogoWithName.png";
import Icon1 from "../../images/Navbar Icons/call.svg";
import Iconhome from "../../images/Navbar Icons/home.svg";
import Iconnews from "../../images/Navbar Icons/notification-status.svg";
import IconAbout from "../../images/Navbar Icons/profile-tick.svg";
import IconMarketplace from "../../images/Navbar Icons/Marketplace.svg";
import IconSolution from "../../images/Navbar Icons/solution.svg";
import IconShipping from "../../images/Navbar Icons/shipping.svg";
import Iconinsurance from "../../images/Navbar Icons/shield-search.svg";
import Iconinspection from "../../images/Navbar Icons/user-search.svg";
import Iconinsuretech from "../../images/Navbar Icons/insure-tech.svg";
import Iconfintech from "../../images/Navbar Icons/fin-tech.svg";
import Iconlogitech from "../../images/Navbar Icons/logi-tech.svg";
import Iconinspecttech from "../../images/Navbar Icons/inspecttech.svg";
import Icontradetech from "../../images/Navbar Icons/tradetech.svg";

const HeaderNav_Component = () => {
    return (
        <>
            <nav
                id="sec-1"
                className="navbar px-4 pt-3 navbar-expand-lg d-flex justify-content-between "
            >
                <NavLink className="navbar-brand" to="/">
                    <img src={Logo} width="142" height="70" alt="Logo TradeReboot" />
                </NavLink>
                <button
                    className="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                >
                    <span className="iconify iconToggle" data-icon="el:lines"></span>
                </button>
                <div
                    className="collapse navbar-collapse justify-content-end "
                    id="navbarSupportedContent"
                >
                    <ul className="navbar-nav mr-auto mt-2 align-items-center Navstyle">
                        <li className="nav-item">
                            <NavLink
                                className="nav-link  nav-menu"
                                aria-current="page"
                                to="/home"
                            >
                                {" "}
                                <img src={Iconhome} alt="" /> Home
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link nav-menu" to="/about-us">
                                {" "}
                                <img src={IconAbout} alt="" /> About Us
                            </NavLink>
                        </li>
                        <li className="nav-item dropdown">
                            <a
                                className="nav-link dropdown-toggle nav-menu text-decoration-none"
                                id="navbarDropdown"
                                data-toggle="dropdown"
                                aria-expanded="false"
                                aria-haspopup="true"
                            >
                                <img src={IconMarketplace} alt="" /> Market Place
                            </a>
                            <ul className="dropdown-menu  w-100  cursor DropdownScroll">
                                <div className="container text-center ">
                                    <ul className="row justify-content-between list-unstyled">
                                        <li className="mt-3  col-xl-3 col-lg-4  col-md-6 col-sm-10 ">
                                            <NavLink
                                                to="/market-place/shipping"
                                                className="card mx-1 p-3 cardhover  ParaHeight"
                                            >
                                                <NavLink
                                                    className="dropdown-item DropDownItem"
                                                    to="/market-place/shipping"
                                                >
                                                    <img src={IconShipping} alt="" /> Shipping
                                                </NavLink>
                                                <p className="my-3 ">
                                                    TradeReboot helps you simplify partner collaboration
                                                    through a shared, common view of trusted shipment
                                                    data.
                                                </p>
                                            </NavLink>
                                        </li>
                                        <li className="mt-3  col-xl-3 col-lg-4  col-md-6 col-sm-10 ">
                                            <NavLink
                                                to="/coming_soon"
                                                className="card mx-1 p-3 cardhover  ParaHeight"
                                            >
                                                <NavLink
                                                    className="dropdown-item DropDownItem"
                                                    to="/coming_soon"
                                                >
                                                    {" "}
                                                    <img src={Iconinsurance} alt="" /> Insurance
                                                </NavLink>
                                                <p className="my-3 ">
                                                    TradeReboot helps you simplify partner collaboration
                                                    through a shared, common view of trusted network of
                                                    Insurance Brokers.
                                                </p>
                                            </NavLink>
                                        </li>
                                        <li className="mt-3 col-xl-3 col-lg-4  col-md-6 col-sm-10 ">
                                            <NavLink
                                                to="/market-place/inspection"
                                                className="card mx-1 p-3 cardhover ParaHeight"
                                            >
                                                <NavLink className="dropdown-item DropDownItem" to="/market-place/inspection">
                                                    {" "}
                                                    <img src={Iconinspection} alt="" /> Inspection
                                                </NavLink>
                                                <p className="my-3">
                                                    Our platform is built to allow traders to send
                                                    inspection nominations to surveyors and for the latter
                                                    to contribute their results and invoice their clients
                                                    seamlessly.
                                                </p>
                                            </NavLink>
                                        </li>
                                        <li className="mt-3 col-xl-3 col-lg-4  col-md-6 col-sm-10 ">
                                            <a
                                                href="https://tradereboot.com/" target='_blank'
                                                className="card mx-1 p-3 cardhover ParaHeight"
                                            >
                                                <a className="dropdown-item DropDownItem" href="https://tradereboot.com/" target='_blank'>
                                                    {" "}
                                                    <img src={Iconinspection} alt="" /> Trade Finance
                                                </a>
                                                <p className="my-3">
                                                    Trade Finance Reforming Export Liquidity and Working Capital one step at a time. We help MSME to forecast, monitor and free up cash through our innovative, robust trade finance platform.
                                                </p>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </ul>
                        </li>
                        <li to="/" className="nav-item dropdown">
                            <a
                                className="nav-link dropdown-toggle nav-menu text-decoration-none"
                                id="navbarSolutionDropdown"
                                data-toggle="dropdown"
                                aria-expanded="false"
                                aria-haspopup="true"
                            >
                                <img src={IconSolution} alt="" /> Solution
                            </a>
                            <ul className="dropdown-menu  w-100 cursor DropdownScroll">
                                <div className=" text-center ">
                                    <ul className="row justify-content-around list-unstyled">
                                        <li className="mt-3 col-xl-2 col-lg-3 col-md-6 col-sm-10 ">
                                            <NavLink
                                                to="/solutions/trade-tech"
                                                className="card mx-1 p-3 cardhover ParaHeight"
                                            >
                                                <NavLink
                                                    className="dropdown-item DropDownItem"
                                                    to="/solutions/trade-tech"
                                                >
                                                    {" "}
                                                    <img src={Icontradetech} alt="" />
                                                    Trade-Tech
                                                </NavLink>
                                                <p className="my-3 ">
                                                    TradeReboot Trade-Tech enables the buyers and sellers to create contract between themselves, adopt payment method and if require apply for it, manage networks (partner seller or buyers), tracking goods, get shipping & inspection services, get insurance services
                                                </p>
                                            </NavLink>
                                        </li>
                                        <li className="mt-3 col-xl-2 col-lg-3 col-md-6 col-sm-10 ">
                                            <NavLink
                                                to="/solutions/logi-tech"
                                                className="card mx-1 p-3 cardhover ParaHeight"
                                            >
                                                <NavLink
                                                    className="dropdown-item DropDownItem"
                                                    to="/solutions/logi-tech"
                                                >
                                                    <img src={Iconlogitech} alt="" />
                                                    Logi-Tech
                                                </NavLink>
                                                <p className="my-3 ">
                                                    TradeReboot helps you simplify partner collaboration
                                                    through a shared, common view of trusted shipment
                                                    data.
                                                </p>
                                            </NavLink>
                                        </li>
                                        <li className="mt-3  col-xl-2 col-lg-3 col-md-6 col-sm-10 ">
                                            <NavLink
                                                to="/solutions/insure-tech"
                                                className="card mx-1 p-3 cardhover  ParaHeight"
                                            >
                                                <NavLink
                                                    className="dropdown-item DropDownItem"
                                                    to="/solutions/insure-tech"
                                                >
                                                    {" "}
                                                    <img src={Iconinsuretech} alt="" />
                                                    Insure-Tech
                                                </NavLink>
                                                <p className="my-3 ">
                                                    TradeReboot brings together leading insurance
                                                    companies from across the globe.
                                                </p>
                                            </NavLink>
                                        </li>
                                        <li className="mt-3 col-xl-2 col-lg-3  col-md-6 col-sm-10 ">
                                            <NavLink
                                                to="/solutions/inspect-tech"
                                                className="card mx-1 p-3 cardhover ParaHeight"
                                            >
                                                <NavLink
                                                    className="dropdown-item DropDownItem"
                                                    to="/solutions/inspect-tech"
                                                >
                                                    {" "}
                                                    <img src={Iconinspecttech} alt="" /> Inspect-Tech
                                                </NavLink>
                                                <p className="my-3 ">
                                                    Our platform is built to allow traders to send
                                                    inspection nominations to surveyors and for the latter
                                                    to contribute their results and invoice their clients
                                                    seamlessly.
                                                </p>
                                            </NavLink>
                                        </li>
                                        <li className="mt-3 col-xl-2 col-lg-3 col-md-6 col-sm-10 ">
                                            <NavLink
                                                to="/solutions/fin-tech"
                                                className="card mx-1 p-3 cardhover ParaHeight"
                                            >
                                                <NavLink
                                                    className="dropdown-item DropDownItem"
                                                    to="/solutions/fin-tech"
                                                >
                                                    {" "}
                                                    <img src={Iconfintech} alt="" />
                                                    Fin-Tech
                                                </NavLink>
                                                <p className="my-3 ">
                                                    TradeReboot FinTech enables the banks and NBFCs to
                                                    access exporters and shipping companies to provide
                                                    export financing LC Discounting .
                                                </p>
                                            </NavLink>
                                        </li>
                                    </ul>
                                </div>
                            </ul>
                        </li>

                        <li className="nav-item">
                            <NavLink className="nav-link nav-menu" to="/NewEvents">
                                {" "}
                                <img src={Iconnews} alt="" /> News/Events
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link nav-menu" to="/contact-us">
                                {" "}
                                <img src={Icon1} alt="" /> Contact Us
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/contact-us" target="_blank">
                                <button className="my-2 my-sm-0 head-button2" type="submit">
                                    Lets Talk
                                </button>
                            </NavLink>
                        </li>
                        <li>
                            <a
                                href="https://tech.tradereboot.com/login"
                                target="_blank"
                                rel="noreferrer"
                            >
                                {" "}
                                <button className="my-2 my-sm-0 head-button mx-2" type="submit">
                                    Login
                                </button>
                            </a>
                        </li>
                        {/* <li className="nav-item">
                            <NavLink className="nav-link nav-menu" to="/home/template">Template</NavLink>
                        </li> */}
                    </ul>
                </div>
            </nav>
        </>
    );
};
export default HeaderNav_Component;
