import React, { useRef } from "react";
import CountUp from "react-countup";


const Statics_Component = () => {
    return (
        <>
            <section id="sec-4" className="mt-5">
                <div className="container">
                    <div className="col-10 mx-auto">
                        <div className="row sec-4-box pb-4 pt-3">
                            {StaticsData.map((key, index) => {
                                return (

                                    <div className="col sec-4-mini-box text-center" key={index}>
                                        <div className="sec-4-icon mx-auto my-3" >
                                            <span className="iconify Icons_Color" data-icon={key.Icon}></span>
                                        </div>
                                        <div className="sec-4-text" >
                                            <h5 className="card-title sec-4-title">{key.Number}+</h5>
                                            <p className="card-text sec-4-subtitle">{key.Title}</p>
                                        </div>
                                    </div>

                                );
                            })}
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Statics_Component;



const StaticsData = [
    {
        Number: <CountUp delay={0.1} duration={2} end={950} />,
        Title: "Logins",
        Icon: 'ant-design:login-outlined',
        //Background: "#E3F2FD",
    },
    {
        Number: <CountUp delay={0.1} duration={3} end={85} />,
        Title: "Countries",
        Icon: 'gis:search-country',
        // Background: "#e8f5ea",
    },
    {
        Number: <CountUp delay={0.1} duration={3} end={150} />,
        Title: "Customers",
        Icon: 'uil:users-alt',
        // Background: "#ffcdd299",
    },
    {
        Number: <CountUp delay={0.1} duration={3} end={60} />,
        Title: "Meetings",
        Icon: 'uil:calender',
        //Background: "#FCE4EC",
    },
];