import React from "react";
import TextInput_Component from "../../Home/BookAppointment/TextInput_Component";

const GstServiceDetails_Component = ({ taxDetails }) => {
    return (
        <>
            <h6 className="mx-4 my-4">GST Service Details:</h6>
            <div className="row">
                <div className="col-xl-3 col-lg-3 col-md-6 col-sm-10 mx-auto">
                    <TextInput_Component Type="text" placeholder="Party State Name" label="Party State Name" id="partyStateName" name="partyStateName" value={taxDetails.gstServiceDetails.partyStateName} onChange="" astrix="" disabledText='true' />
                </div>
                <div className="col-xl-3 col-lg-3 col-md-6 col-sm-10 mx-auto">
                    <TextInput_Component Type="text" placeholder="Item Type" label="Item Type" id="itemType" name="itemType" value={taxDetails.gstServiceDetails.itemType} onChange="" astrix="" disabledText='true' />
                </div>
                <div className="col-xl-3 col-lg-3 col-md-6 col-sm-10 mx-auto">
                    <TextInput_Component Type="number" placeholder="Total Premium" label="Total Premium" id="totalPremium" name="totalPremium" value={taxDetails.gstServiceDetails.totalPremium} onChange="" astrix="" disabledText='true' />
                </div>
                <div className="col-xl-3 col-lg-3 col-md-6 col-sm-10 mx-auto">
                    <TextInput_Component Type="number" placeholder="HSNSAC Code" label="HSNSAC Code" id="hsnsacCode" name="hsnsacCode" value={taxDetails.gstServiceDetails.hsnsacCode} onChange="" astrix="" disabledText='true' />
                </div>
                <div className="col-xl-3 col-lg-3 col-md-6 col-sm-10 mx-auto">
                    <TextInput_Component Type="number" placeholder="Gross Amount" label="Gross Amount" id="grossAmount" name="grossAmount" value={taxDetails.gstServiceDetails.grossAmount} onChange="" astrix="" disabledText='true' />
                </div>
                <div className="col-xl-3 col-lg-3 col-md-6 col-sm-10 mx-auto">
                    <TextInput_Component Type="text" placeholder="Tax Exempted" label="Tax Exempted" id="taxExempted" name="taxExempted" value={taxDetails.gstServiceDetails.taxExempted} onChange="" astrix="" disabledText='true' />
                </div>
                <div className="col-xl-3 col-lg-3 col-md-6 col-sm-10 mx-auto">
                    <TextInput_Component Type="text" placeholder="HSNSAC Description" label="HSNSAC Description" id="hsnsacDescription" name="hsnsacDescription" value={taxDetails.gstServiceDetails.hsnsacDescription} onChange="" astrix="" disabledText='true' />
                </div>
                <div className="col-xl-3 col-lg-3 col-md-6 col-sm-10 mx-auto">
                    <TextInput_Component Type="text" placeholder="GST IN" label="GST IN" id="gstin" name="gstin" value={taxDetails.gstServiceDetails.gstin} onChange="" astrix="" disabledText='true' />
                </div>
                <div className="col-xl-3 col-lg-3 col-md-6 col-sm-10 mx-auto">
                    <TextInput_Component Type="text" placeholder="State Name" label="State Name" id="stateName" name="stateName" value={taxDetails.gstServiceDetails.stateName} onChange="" astrix="" disabledText='true' />
                </div>
                <div className="col-xl-3 col-lg-3 col-md-6 col-sm-10 mx-auto">
                    <TextInput_Component Type="number" placeholder="State Code" label="State Code" id="stateCode" name="stateCode" value={taxDetails.gstServiceDetails.stateCode} onChange="" astrix="" disabledText='true' />
                </div>
                <div className="col-xl-3 col-lg-3 col-md-6 col-sm-10 mx-auto">
                    <TextInput_Component Type="text" placeholder="Type Of State" label="Type Of State" id="typeOfState" name="typeOfState" value={taxDetails.gstServiceDetails.typeOfState} onChange="" astrix="" disabledText='true' />
                </div>
                <div className="col-xl-3 col-lg-3 col-md-6 col-sm-10 mx-auto">
                    <TextInput_Component Type="text" placeholder="GST Reg Br Name" label="GST Reg Br Name" id="gstRegBrName" name="gstRegBrName" value={taxDetails.gstServiceDetails.gstRegBrName} onChange="" astrix="" disabledText='true' />
                </div>
                <div className="col-xl-3 col-lg-3 col-md-6 col-sm-10 mx-auto">
                    <TextInput_Component Type="text" placeholder="GST Reg Br Address" label="GST Reg Br Address" id="gstRegBrAddress" name="gstRegBrAddress" value={taxDetails.gstServiceDetails.gstRegBrAddress} onChange="" astrix="" disabledText='true' />
                </div>
                <div className="col-xl-3 col-lg-3 col-md-6 col-sm-10 mx-auto">
                    <TextInput_Component Type="number" placeholder="SGST Amount" label="SGST Amount" id="sgstAmount" name="sgstAmount" value={taxDetails.gstServiceDetails.sgstAmount} onChange="" astrix="" disabledText='true' />
                </div>
                <div className="col-xl-3 col-lg-3 col-md-6 col-sm-10 mx-auto">
                    <TextInput_Component Type="number" placeholder="IGST Rate" label="IGST Rate" id="igstRate" name="igstRate" value={taxDetails.gstServiceDetails.igstRate} onChange="" astrix="" disabledText='true' />
                </div>
                <div className="col-xl-3 col-lg-3 col-md-6 col-sm-10 mx-auto">
                    <TextInput_Component Type="number" placeholder="IGST Amount" label="IGST Amount" id="igstAmount" name="igstAmount" value={taxDetails.gstServiceDetails.igstAmount} onChange="" astrix="" disabledText='true' />
                </div>

                <div className="col-xl-3 col-lg-3 col-md-6 col-sm-10 mx-auto">
                    <TextInput_Component Type="number" placeholder="GST Net Rate" label="GST Net Rate" id="gstNetRate" name="gstNetRate" value={taxDetails.gstServiceDetails.gstNetRate} onChange="" astrix="" disabledText='true' />
                </div>
                <div className="col-xl-3 col-lg-3 col-md-6 col-sm-10 mx-auto">
                    <TextInput_Component Type="number" placeholder="GST Amount" label="GST Net Amount" id="gstAmount" name="gstAmount" value={taxDetails.gstServiceDetails.gstAmount} onChange="" astrix="" disabledText='true' />
                </div>
                <div className="col-xl-3 col-lg-3 col-md-6 col-sm-10 ">
                    <TextInput_Component Type="number" placeholder="Total Tax" label="Total Tax" id="totalTax" name="totalTax" value={taxDetails.gstServiceDetails.totalTax} onChange="" astrix="" disabledText='true' />
                </div>
                <div className="col-xl-3 col-lg-3 col-md-6 col-sm-10">
                    <TextInput_Component Type="number" placeholder="Final Amount" label="Final Amount" id="finalAmount" name="finalAmount" value={taxDetails.gstServiceDetails.finalAmount} onChange="" astrix="" disabledText='true' />
                </div>
            </div>
        </>
    );
}
export default GstServiceDetails_Component;