import axios from "axios";
import React, { useState, useEffect } from "react";
import { ToastContainer } from "react-toastify";
import { platformBackendURL, platformURL, websiteBackendURL } from "../../../config";
import LogoImage from '../../../images/logo_mini.png';
import { avatarUrl, validate_text_field } from "../../../utils/myFunctions";
import toastDisplay from "../../ToastNotification";

const PopupForEnquiry = (props) => {
  const [data, setData] = useState({})
  const [error, setError] = useState({})
  const [currencies, setCurrencies] = useState([])
  const [commKeys, setCommKeys] = useState([])
  const [commSpecs, setCommSpecs] = useState({})
  const [updatedValues, setUpdatedValues] = useState({})

  useEffect(() => {
    axios.get(platformBackendURL + "/getcurrencylist", {}).then(result => {
      if (result.data && result.data.message) {
        setCurrencies(result.data.message)
      }
    })
    axios.post(websiteBackendURL + "/inspection/getSpecificationByCommId", { commodityId: props.data.commodityId }).then(result => {
      console.log("getSpecificationByCommId", Object.keys(result.data.specifications), result.data.specifications);
      if (result.data && result.data.specifications && Object.keys(result.data.specifications).length)
        setCommKeys(Object.keys(result.data.specifications))
      setCommSpecs(result.data.specifications)
    })
  }, [])

  useEffect(() => {
    if (data.price && data.priceType && data.currency && data.quantity) {
      setData({ ...data, "totalAmount": (data.price * data.quantity) + " " + data.currency })
    }
    else {
      setData({ ...data, "totalAmount": "" })
    }
  }, [data.price, data.quantity, data.priceType, data.currency])

  const handleChange = (event) => {
    event.persist()
    if (event.target.name === "quantity" && event.target.value) {
      event.target.value = Number.parseInt(event.target.value)
    }
    setData({ ...data, [event.target.name]: validate_text_field(event.target.value) })
    setError({ ...error, [event.target.name]: event.target.value ? "" : " is required" })
  }

  const validateFields = () => {
    // let validate = ["price", "priceType", "currency", "quantity"]
    let validate = ["quantity", "laycan_start_date"]
    let err = {}
    validate.map(item => {
      if (!data[item]) {
        err[item] = "is required"
      }
      // if (data["price"] / 1 == 0) {
      //   err["price"] = "is invalid"
      // }
      if (data["quantity"] / 1 == 0) {
        err["quantity"] = "is invalid"
      }
    })
    setError(err)
    if (!Object.keys(err).length) {
      axios.post(websiteBackendURL + "/inspection/saveInspectionInquiry",
        {
          commodityId: props.data.commodityId,
          portId: props.data.portId,
          quantity: data.quantity,
          laycan_start_date: data.laycan_start_date,
          commoditySpecs: commSpecs,
          // totalAmount: data.totalAmount,
          userId: props.data.tbl_user_id
        }).then(result => {
          props.close()
          if (result.data.status) {
            toastDisplay("Inspection query raised successfully", "success")
            setTimeout(() => {
              window.open(platformURL + "/login?mkt_inspection_ref=" + result.data.id, "_blank")
            }, 2000);
          }
          else {
            toastDisplay("Something went wrong", "error")
          }
        });
    }
  }

  function addSpec() {
    let specObj = {
      name: data.specName,
      rejectionSymbol: data.rejAt,
      rejectionValue: data.rejVal,
      value: data.typicalVal
    }
    let specShortName = (data.specName).split(/\s/).join('')
    let tempCommKeys = commKeys
    tempCommKeys.push(specShortName)
    setCommKeys(tempCommKeys)
    setCommSpecs({ ...commSpecs, [specShortName]: specObj })
    setData({ ...data, "specName": "", "rejAt": "", "rejVal": "", "typicalVal": "" })
  }

  function deleteSpec(delIndex) {
    let tempCommSpecs = commSpecs
    delete tempCommSpecs[commKeys[delIndex]]
    setCommSpecs(tempCommSpecs)
    setCommKeys(commKeys.filter((item, index) => {
      if (index != delIndex) {
        return item
      }
    }))
  }

  const handleAddedSpecs = (event, item) => {
    event.persist()
    let tempCommSpecs = commSpecs
    tempCommSpecs[item][event.target.name] = event.target.value
    setUpdatedValues({ ...updatedValues, [event.target.name + ":" + item]: event.target.value })
    setCommSpecs(tempCommSpecs)
  }

  const astrix = <span className="required-field text-danger">*</span>

  return (
    <div className={"modal modalshow"}
    >
      <div className="modal-dialog modal-xl border-inner" id="parent_class">
        <div className="modal-content">
          <div className="modal-header primary">
            <h4 className="modal-title text-white px-2">Tell us more about your requirements</h4>
            <button type="button" className="close"
              onClick={props.close}>×</button>
          </div>
          {/* <h5 className="modal-title text-black px-4 pt-2">{"Quantity & price"}</h5> */}
          <div className="modal-body calc-inner-modal cursor">
            <div className="modal-padding px-2">
              <div className="row g-4">
                {/* <div className="col-md-5">
                  <label className="mb-2">Commodity Price/mt (metric tonne*) {astrix}</label>
                  <input className="form-control"
                    placeholder="Enter price"
                    name="price" value={data.price} onChange={handleChange} />
                  {error.price ? <span className="text-danger fontValid">{"Price " + error.price}</span> : ''}
                </div> */}
                {/* <div className="col-md-5">
                  <label className="mb-2">Price Type {astrix}</label>
                  <select className="form-select" name="priceType" value={data.priceType} id="priceType" onChange={handleChange}>
                    <option value="" selected>Select Price Type</option>
                    <option value={"fixed_price"}>{"Fixed Price"}</option>
                    <option value={"index_price"}>{"Index Price"}</option>
                  </select>
                  {error.priceType ? <span className="text-danger fontValid">{"Price Type " + error.priceType}</span> : ''}
                </div> */}
                {/* <div className="col-md-5">
                  <label className="mb-2">Currency {astrix}</label>
                  <select className="form-select" name="currency" value={data.currency} id="currency" onChange={handleChange}>
                    <option value="" selected>Select Currency</option>
                    {currencies.map(val =>
                      <option value={val.symbol} >{val.name + " (" + val.code + ") (" + val.symbol + ")"}</option>
                    )}
                  </select>
                  {error.currency ? <span className="text-danger fontValid">{"Currency " + error.currency}</span> : ''}
                </div> */}

                <div className="col-md-5">
                  <label className="mb-2">Laycan Start Date {astrix}</label>
                  <input type="date" className="form-control"
                    placeholder=""
                    name="laycan_start_date" value={data.laycan_start_date} onChange={handleChange} />
                  {error.laycan_start_date ? <span className="text-danger fontValid">{"Laycan start date " + error.laycan_start_date}</span> : ''}
                </div>
                <div className="col-md-5">
                  <label className="mb-2">Total Quantity (mt) {astrix}</label>
                  <input type="number" className="form-control"
                    placeholder="Enter Quantity"
                    name="quantity" value={data.quantity} onChange={handleChange} />
                  {error.quantity ? <span className="text-danger fontValid">{"Quantity " + error.quantity}</span> : ''}
                </div>

                <h5 className="mb-0">{"Specification (" + props.data.commPrettyName + ")"}</h5>
                {commKeys.length ? commKeys.map((item, index) => {
                  console.log("updateddd", item, commSpecs[item] && commSpecs[item]["value"]);

                  return (
                    <>
                      <div className="col-md-3">
                        <label className="mb-2">{commSpecs[item] && commSpecs[item]["name"] + " - (Typical Value)"}</label>
                        <input name={'value'} className="form-control" onChange={(event) => handleAddedSpecs(event, item)}
                          value={updatedValues["value:" + item] || (commSpecs[item] && commSpecs[item]["value"])}
                        />
                      </div>
                      <div className="col-md-3">
                        <label className="mb-2">Rejection At </label>
                        <select name={"rejectionSymbol"} className="form-select" onChange={(event) => handleAddedSpecs(event, item)}
                          value={updatedValues["rejectionSymbol:" + item] || (commSpecs[item] && commSpecs[item]["rejectionSymbol"])}
                        >
                          <option value="" selected>Select</option>
                          <option value={"!"}>{"NA"}</option>
                          <option value={"<"}>{"Below"}</option>
                          <option value={">"}>{"Above"}</option>
                        </select>
                      </div>
                      <div className="col-md-3">
                        <label className="mb-2">Rejection Value</label>
                        <input name={"rejectionValue"} className="form-control" onChange={(event) => handleAddedSpecs(event, item)}
                          value={updatedValues["rejectionValue:" + item] || (commSpecs[item] && commSpecs[item]["rejectionValue"])}
                        />
                      </div>
                      <div className="col-md-3 mb-1 align-self-end">
                        <button type="button "
                          className="btn btn-danger btn-sm" onClick={() => deleteSpec(index)}>Delete</button>
                      </div>
                    </>
                  )
                }) :
                  <label className="">{"No specifications added"}</label>}

                <h5 className="mb-0">{"Add New Specification "}</h5>
                <div className="col-md-2">
                  <label className="mb-2">Specification Name {astrix}</label>
                  <input className="form-control"
                    name="specName" value={data.specName} onChange={handleChange} />
                </div>

                <div className="col-md-2">
                  <label className="mb-2">Typical Value {astrix}</label>
                  <input className="form-control"
                    name="typicalVal" value={data.typicalVal} onChange={handleChange} />
                </div>

                <div className="col-md-2">
                  <label className="mb-2">Rejection At {astrix}</label>
                  <select className="form-select" name="rejAt" value={data.rejAt} id="rejAt" onChange={handleChange}>
                    <option value="" selected>-Select-</option>
                    <option value={"!"}>{"NA"}</option>
                    <option value={"<"}>{"Below"}</option>
                    <option value={">"}>{"Above"}</option>
                  </select>

                </div>

                <div className="col-md-2">
                  <label className="mb-2">Rejection Value {astrix}</label>
                  <input className="form-control"
                    name="rejVal" value={data.rejVal} onChange={handleChange} />
                </div>

                <div className="col-md-2 mb-1 align-self-end">
                  <button type="button" disabled={!(data.specName && data.typicalVal && data.rejAt && data.rejVal)}
                    style={{ "background-color": "#138496", color: '#fff' }}
                    className="btn btn-sm" onClick={() => addSpec()}>Add +</button>

                </div>



                {/* <div className="col-md-5">
                  <label className="mb-2">Total Amount = Commodity Price/mt x Total Quantity</label>
                  <input disabled className="form-control"
                    name="totalAmount" value={data.totalAmount} onChange={handleChange} />
                </div> */}
              </div>
            </div>
          </div>
          <div className="modal-footer"
          >
            <button type="button" className="btn btn-danger btn-sm" onClick={props.close}>Cancel</button>
            <button type="button"
              style={{ "background-color": "#138496", color: '#fff' }}
              className="btn btn-sm" onClick={() => validateFields()}>Submit</button>
          </div>
        </div>
      </div>
    </div >
  )

}

const Inspection_Search_Component = () => {

  const [data, setData] = useState({});
  const [error, setError] = useState({});
  const [showLoader, setShowLoader] = useState(false);
  const [commType, setcommType] = useState([])
  const [commName, setcommName] = useState([])
  const [country, setcountry] = useState([])
  const [ports, setPorts] = useState([])
  const [dbData, setdbData] = useState([])
  const [showDataGrid, toggleDataGrid] = useState(false)
  const [showInquiryPopup, toggleInquiryPopup] = useState(false);
  const [selectedAgency, setSelectedAgency] = useState({})


  useEffect(() => {
    setShowLoader(true);
    axios.get(websiteBackendURL + "/commodity/getCommodityCategory", {}).then(result => {
      if (result.data && result.data.length) {
        setcommType(result.data)
      }
      axios.get(websiteBackendURL + "/shippingMarketPlace/getCountry", {}).then(result => {
        setShowLoader(false);
        setcountry(result.data)
      });
    });
  }, [])

  useEffect(() => {
    setData({ ...data, "commName": "" })
    setcommName([])
    if (data.commType) {
      setShowLoader(true);
      axios.get(websiteBackendURL + "/commodity/getCommodityByCategoryId", {
        params: {
          "commCatId": data.commType
        }
      }).then(result => {
        setShowLoader(false);
        if (result.data && result.data.length) {
          setcommName(result.data)
        }
      });
    }
  }, [data.commType])


  useEffect(() => {
    setData({ ...data, "port": "" })
    if (data.country) {
      setShowLoader(true);
      axios.post(websiteBackendURL + "/shippingMarketPlace/getCountryPorts", { country_id: data.country }).
        then(result => {
          setShowLoader(false);
          setPorts(result.data)
        });
    }
  }, [data.country])


  const onSubmit = () => {
    let validateFields = ["commType", "commName", "country", "port"]
    let err = {}
    validateFields.map(item => {
      if (!data[item]) {
        err[item] = "is required"
      }
    })
    if (!Object.keys(err).length) {
      setShowLoader(true);
      axios.post(websiteBackendURL + "/inspection/getInspectionAgencies",
        { commName: data.commName, port: data.port }).then(result => {
          setShowLoader(false);
          toggleDataGrid(true);
          if (result.data && result.data.length) {
            setdbData(result.data)
          }
          else {
            setdbData([])
          }
        });
    }
    setError(err)
  }

  const handleChange = (event) => {
    event.persist();
    setdbData([])
    toggleDataGrid(false)
    setData({ ...data, [event.target.name]: event.target.value });
    setError({ ...error, [event.target.name]: event.target.value ? "" : "is required" })
  }

  return (
    <>
      <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
      {showInquiryPopup ? (
        <PopupForEnquiry close={() => { setSelectedAgency({}); toggleInquiryPopup(false) }}
          data={selectedAgency}
        />
      ) : null}
      {showLoader && (<div className="loading-overlay"><span><img className="" src={process.env.PUBLIC_URL + "/assets/images/loader.gif"}
        alt="description" /></span></div>)}
      <div className="searchId py-4">
        <h4 className="text-dark fw-bold text-center mb-4"><span className="">Search Inspection Agencies</span></h4>
        <form className="row g-4 mx-3 justify-content-center">
          <div className="col-md-2">
            <div className="input-group">
              <select className="form-select p-3" name="commType" value={data.commType} id="commType" onChange={handleChange}>
                <option value="" selected>Select Commodity Type</option>
                {commType.map(item => (
                  <option value={item.id}>{item.category}</option>
                ))}
              </select>
            </div>
            {error.commType ? <span className="text-danger fontValid">{"Commodity Type " + error.commType}</span> : ''}
          </div>

          <div className="col-md-2">
            <div className="input-group ">
              <select className="form-select p-3" name="commName" value={data.commName} id="commName" onChange={handleChange}>
                <option value="" selected>Select Commodity Name</option>
                {commName.map(item => (
                  <option value={item.id}>{item.commodity_pretty_name}</option>
                ))}
              </select>
            </div>
            {error.commName ? <span className="text-danger fontValid">{"Commodity Name " + error.commName}</span> : ''}
          </div>

          <div className="col-md-2">
            <div className="input-group">
              <select className="form-select p-3" name="country" value={data.country} id="country" onChange={handleChange}>
                <option value="" selected>Select Country</option>
                {country.map(item => (
                  <option value={item.id}>{item.name}</option>
                ))}
              </select>
            </div>
            {error.country ? <span className="text-danger fontValid">{"Country " + error.country}</span> : ''}
          </div>

          <div className="col-md-2">
            <div className="input-group">
              <select className="form-select p-3" name="port" value={data.port} id="port" onChange={handleChange}>
                <option value="" selected>Select Port</option>
                {ports.map(item => (
                  <option value={item.name}>{item.name}</option>
                ))}
              </select>
            </div>
            {error.port ? <span className="text-danger fontValid">{"Port " + error.port}</span> : ''}
          </div>


          <div className="col-md-2 d-flex">
            <button className="my-2 my-sm-0 head-button2" type="button" onClick={() => onSubmit()}> Submit
              <span className="iconify ms-2 fs-6" data-icon="akar-icons:search" ></span>
            </button>
          </div>
        </form >

        {showDataGrid ? dbData.length ? (
          <h4 className="text-light fw-bold text-center mt-4 pt-4"><span className="TextColor">{dbData.length + " Record" +
            (dbData.length > 1 ? "s" : "") + " Found"}</span></h4>
        ) : (
          <>
            <h4 className="text-light fw-bold text-center mt-4 pt-4"><span className="TextColor">No Records Found</span></h4>
            <h4 className="text-dark text-center mt-4">Try Searching With Different Selection</h4>
          </>
        ) : null}

        <div className="row justify-content-center">
          {dbData.map(item => (
            <div className="col-md-3 p-3 m-3">
              <div className="row text-center justify-content-center">
                <div>
                  <img src={item.user_avatar ? avatarUrl(item.user_avatar) : LogoImage} alt="Client Image" height={'75px'} width={'85px'} />
                </div>
                <label style={{ color: '#0075b8' }} className="form-check-label fw-bold">
                  {item.company_name || "NA"}
                </label>
                <label className="form-check-label mb-2">
                  {item.user_address || "NA"}
                </label>
                <label className="form-check-label">
                  {"Contact - " + (item.contact_number || "NA")}
                </label>
                <label className="form-check-label">
                  {"Email Id - " + (item.email_id || "NA")}
                </label>
                <button className="head-button my-3 w-auto" type="button" onClick={() => {
                  setSelectedAgency({
                    ...item, "commodityId": data.commName,
                    commPrettyName: commName.filter(val => { if (val.id == data.commName) { return val } })[0]["commodity_pretty_name"],
                    portId: ports.filter(val => { if (val.name === data.port) { return val } })[0]["id"]
                  }); toggleInquiryPopup(true)
                }}>Book Now
                </button>
              </div>
            </div>
          ))}
        </div>


      </div>
    </>
  )



};
export default Inspection_Search_Component;
