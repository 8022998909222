export default function validate(values) {

  let errors = {};

  if (!values.firstName) {
    errors.firstName = 'Field Missing'
  }
  if (!values.lastName) {
    errors.lastName = 'Field Missing'
  }
  if (!values.email) {
    errors.email = 'Field Missing';
  }
  if (!values.telephone) {
    errors.telephone = 'Field Missing';
  }
  if (!values.subject) {
    errors.subject = 'Field Missing';
  }
  console.log(errors);
  return errors;
}