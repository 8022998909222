
import React from "react";
import { NavLink } from "react-router-dom";
import ShippingImage from '../../images/sec-2a.png';
import InsuranceImage from '../../images/sec-2b.png';
import InspectionImage from '../../images/sec-2c.png';
import TradeFinanceImage from '../../images/trade_finance.png'
import Title from "./Title";
import { Fade } from "react-reveal";

const MarketPlace_Component = () => {
    return (
        <>
            <div className="my-5">
                <Title Title="Our Marketplace" Description=" " />
            </div>
            <div className="container text-center">
                <div className="row">
                    {Data.map((key, index) => {
                        return (
                            <Fade key={index}>
                                <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                                    <div className="card MarketCard">
                                        <div className="card-header CardHeader">
                                            <img className="img-fluid" src={key.Image} width="" height="" alt="" />
                                        </div>
                                        <div className="card-body scroll scroll1">
                                            <h4 className="fw-bold">{key.Heading}</h4>
                                            <p className="" >{key.Para}</p>
                                        </div>
                                        <div className="card-footer CardFooter">
                                            {key.Link === 'https://tradereboot.com/' ?
                                                <a href={key.Link} target="_blank">
                                                    <button className="btn Seemore my-2 my-sm-0" type="submit">Read more</button>
                                                </a>
                                                :
                                                <NavLink to={key.Link}>
                                                    <button className="btn Seemore my-2 my-sm-0" type="submit">Read more</button>
                                                </NavLink>}
                                        </div>
                                    </div>
                                </div>

                            </Fade>
                        );
                    })}

                </div>

            </div>
        </>
    );
}
export default MarketPlace_Component;


const Data = [
    {
        Heading: 'Shipping',
        Para: 'TradeReboot helps you simplify partner collaboration through a shared, common view of trusted shipment data. With data published to the platform, permissioned parties can view and act on information in near real time, benefitting from more granular and detailed analytics up and down the supply chain.',
        Image: ShippingImage,
        Link: '/market-place/shipping'
    },
    {
        Heading: 'Insurance',
        Para: 'TradeReboot helps you simplify partner collaboration through a shared, common view of trusted network of Insurance  Brokers. At TradeReboot, you can choose and compare quotations for trade credit insurance and maritime insurance from a wide network of leading insurance providers.',
        Image: InsuranceImage,
        Link: '/coming_soon'
    },
    {
        Heading: 'Inspection',
        Para: 'Our platform is built to allow traders to send inspection nominations to surveyors and for the latter to contribute their results and invoice their clients seamlessly. Inspection agencies through our platform can issue digital certificates online which reduces the possibility of any misstatement in the verification of goods by the inspection agencies and also reduces the hassles of paperwork.',
        Image: InspectionImage,
        Link: '/coming_soon'
    },
    {
        Heading: 'Trade Finance',
        Para: 'Trade Finance Reforming Export Liquidity and Working Capital one step at a time. We help MSME to forecast, monitor and free up cash through our innovative, robust trade finance platform.',
        Image: TradeFinanceImage,
        Link: 'https://tradereboot.com/'
    },
];


