import React, { Children } from "react";
import { Fade } from "react-reveal";

const CompAdavatage_Component = ({ CompImage, children, Data_List, Points }) => {
    return (
        <>
            <div className="container py-5 ">
                <div className="">
                    <div className="row">
                        <Fade left>
                            <div className="col-xl-4 col-lg-4 col-sm-12 col-md-4 CompImage">
                                <img src={CompImage} alt="Competetive Image" className="img-fluid" />
                            </div>
                        </Fade>
                        <Fade right>
                            <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 scrollbar2 m-auto" id="style-4">
                                {children}
                                <p>{Data_List}</p>
                                {Points ? <ul className="ListStyleImage  force-overflow ">
                                    {Points.map((key, index) => {
                                        return (
                                            <li className="mt-3" key={index}>{key}</li>
                                        );
                                    })}
                                </ul> : ''}
                            </div>
                        </Fade>
                    </div>
                </div>
            </div>
        </>
    );
}
export default CompAdavatage_Component;