import React from "react";
import { Fade } from "react-reveal";

const CardComponentRightImg = ({ Image, Data_List, children }) => {
  return (
    <>
      <div className="container py-5">
        <div className="row">
          <Fade left>
            <div
              className="col-xl-6 col-lg-6 col-md-6 col-sm-12 m-auto"
              id="style-4"
            >
              {children}
              <ul className="ListStyleImage  force-overflow ">
                {Data_List.map((key, index) => {
                  return (
                    <li className="mt-3" key={index}>
                      {key}
                    </li>
                  );
                })}
              </ul>
            </div>
          </Fade>
          <Fade right>
            <div className="col-xl-6 col-lg-6 col-sm-12 col-md-6 CompImage text-end">
              <img src={Image} alt="Document" className="img-fluid" />
            </div>
          </Fade>
        </div>
      </div>
    </>
  );
};

export default CardComponentRightImg;
