import React from "react";
import Banner_Component from "./Banner_Component";
import BlogTemplate_Component from "./NewsEventsCards_Component";
import MarketPlace_Component from "./MarketPlace_Component";
import MeetOurTeam_Component from "./MeetOurTem_Component";
import OurClients_Components from "./OurClients_Component";
import OurSolution_Component from "./OurSolution_Component";
import Statics_Component from "./Statics_Component";
import NewsEventsCards_Component from "./NewsEventsCards_Component";

const HomeComponent = () => {
    return (
        <>
            <Banner_Component />
            <MarketPlace_Component />
            <OurSolution_Component />
            <Statics_Component />
            <OurClients_Components />
            <MeetOurTeam_Component />
            <NewsEventsCards_Component />
        </>
    );
}
export default HomeComponent;