import React from "react";
import Title from "../Home/Title";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import useNumber from "../About Us/useNumber";
import ImageOne from '../../images/team/Dheeraj-Khandelwal.jpg';
import ImageTwo from '../../images/team/chia-scaled.jpg';
import ImageThree from '../../images/team/Ravi-Chamria.png';
import ImageFour from '../../images/team/Shikha-ji.jpg';
import ImageFive from '../../images/team/Vikram-Pandya.jpg';
import ImageSix from '../../images/team/Sonia-Khandelwal.jpg';
import ImageSeven from '../../images/team/jatin-1.png';
import ImageEight from '../../images/team/suprita-sinha.jpeg'

export const Chevron_Right = ({ color, size }) => {
    return (
        <svg
            height={size || "24px"}
            width={size || "24px"}
            fill={color || "#aaa"}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 256 512"
        >
            {" "}
            <path d="M17.525 36.465l-7.071 7.07c-4.686 4.686-4.686 12.284 0 16.971L205.947 256 10.454 451.494c-4.686 4.686-4.686 12.284 0 16.971l7.071 7.07c4.686 4.686 12.284 4.686 16.97 0l211.051-211.05c4.686-4.686 4.686-12.284 0-16.971L34.495 36.465c-4.686-4.687-12.284-4.687-16.97 0z" />
        </svg>
    );
};
export const Chevron_Left = ({ color, size }) => {
    return (
        <svg
            height={size || "24px"}
            width={size || "24px"}
            fill={color || "#aaa"}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 256 512"
        >
            {" "}
            <path d="M238.475 475.535l7.071-7.07c4.686-4.686 4.686-12.284 0-16.971L50.053 256 245.546 60.506c4.686-4.686 4.686-12.284 0-16.971l-7.071-7.07c-4.686-4.686-12.284-4.686-16.97 0L10.454 247.515c-4.686 4.686-4.686 12.284 0 16.971l211.051 211.05c4.686 4.686 12.284 4.686 16.97-.001z" />
        </svg>
    );
};

const OurTeam = () => {
    return (
        <>
            <div className="TeamSection">
                <div className="">
                    <div className="text-center">
                        <Title
                            Title={"Our Core Team"}
                            Description={
                                " "
                            }
                        />
                        <RenderInPc />
                    </div>

                </div>
            </div>
        </>
    );
}
export default OurTeam;

const RenderInPc = () => {
    const { number, increase_Handler, decrease_Handler } = useNumber(
        TeamData.slice(0, 9).length - 1
    );
    console.log(number);

    return (
        <Carousel
            autoPlay={true}
            emulateTouch={true}
            infiniteLoop={true}
            showStatus={false}
            showArrows={false}
            interval={1000 * 5}
            centerMode={false}
            showIndicators={false}
            selectedItem={number}
        >
            {TeamData.slice(0, 9).map((data, index) => {
                return (
                    <div key={index} className="TeamSection_Box ">
                        <Team
                            PersonName={data.PersonName}
                            Position={data.Position}
                            About={data.About}
                            src={data.Src}
                            increase_Handler={increase_Handler}
                            decrease_Handler={decrease_Handler}
                            linkdin={data.linkdin}
                        />
                    </div>
                );
            })}
        </Carousel>
    );
};
const Team = ({
    src,
    PersonName,
    About,
    Position,
    increase_Handler,
    decrease_Handler,
    linkdin
}) => {
    return (
        <div className="TeamSection_Card">
            <div className="TeamSection_Card_Left">
                <img src={src} alt="Hello World " />
                <div className="Card_Left">

                    <button onClick={decrease_Handler}>
                        <Chevron_Left color="#fff" size={"17px"} />
                    </button>
                    <button onClick={increase_Handler}>
                        <Chevron_Right color="#fff" size={"17px"} />
                    </button>
                </div>
            </div>
            <div className="TeamSection_Card_Right">
                <div>
                    <h2>{PersonName.toLowerCase()}</h2>
                    <a target="_blank" href={linkdin}>
                        <span className="iconify mx-2" data-icon="akar-icons:linkedin-fill" style={{ color: "black" }}></span>
                    </a>

                </div>
                <div>
                    <span>{Position}</span>
                </div>
                <div>
                    <p>{About}</p>
                </div>
                <div className="">
                    {/* <Link to="/home/book-appointment" target="_blank"> <button className="btn body-button">Book Appointment</button> </Link> */}
                </div>
            </div>
        </div>
    );
};

export const TeamData = [
    {
        PersonName: `RAVI CHAMRIA`,
        Position: `Blockchain Advisor`,
        About: `An ITpreneur and Blockchain expert  with 20+ years of experience , he is an early adopter of Blockchain Technology designing various products and solutions.  As a founder of Sofocle Technology, Ravi has an experience of 3+ years in Blockchain and Smart Contracts and also is a keynote speaker for Blockchain in various conferences and seminars across the globe.`,
        Src: ImageThree,
        linkdin: 'https://www.linkedin.com/in/rchamria/'
    },
    {
        PersonName: `Shikha Bhardwaj`,
        Position: `Director`,
        About: `Serial Entrepreneur and backed with 15 years+ International Experience as Technocrat. Hands-on domestic & overseas experience in Business development & technology implementation. She has worked for fortune 500 companies on senior position in Retails, Banking, Construction, Automotive components, Conglomerate, FMCG & Food processing, Telecom Industries.`,
        Src: ImageFour,
        linkdin: 'https://www.linkedin.com/in/shikha-bhardwaj-76b6b913'
    },
    {
        PersonName: `SONIA KHANDELWAL`,
        Position: `Director`,
        About: `Sonia has an experience of 19+ years in trade of industrial commodities and minerals She also set up a platform to ensure retail and business credit availability like Home Loan, Personal Loan etc. to people who have difficulty in accessing credit.`,
        Src: ImageSix,
        linkdin: 'https://www.linkedin.com/in/sonia-khandelwal-a508411b6/'
    },
    {
        PersonName: `CHIRAG AGARWAL`,
        Position: `Director`,
        About: `Chirag has been instrumental in the expansion of this company. He is an expert in Corporate Outsourced CFO services, Risk Advisory Services and VAT Consultancy and has a pro-active approach to problems and customer-centricity which resulted in a transparent and strong bond with all clients.He firmly believes in risk management strategies and implements them on a daily basis.`,
        Src: ImageTwo,
        linkdin: 'https://www.linkedin.com/in/ca-chirag-agarwal-6b077313b/'
    },
    {
        PersonName: `DHEERAJ KHANDELWAL`,
        Position: `Promoter and Investor`,
        About: `A Chartered Accountant having a practice in accounting and auditing for 20+ years, he is a proactive and influential member of various professional and social bodies. He was elected as Member of Central Council of The Institute of Chartered Accountants of India for a term of 2016-2019 and was re-elected for a term of 2019-2021. He is also an active social worker. Dheeraj also has an experience of 10+ years in the trade business of industrial commodities. `,
        Src: ImageOne,
        linkdin: 'https://www.linkedin.com/in/dhiraj-khandelwal-6306a416/'
    },
    {
        PersonName: `VIKRAM PANDYA`,
        Position: `Advisor`,
        About: `Vikram is a Director at SP Jain School of Global Management and has designed Asia’s first interactive classroom. He has authored several white papers and articles on FinTech domain. And also has an extensive experience in banking, financial services, Fintech, consultancy and training domain. One of his major accomplishments is being a Fintech ambassador for Maharashtra Govt.’s UdayFintech platform.He also heads the research arm of Fintech VC Fund Varanium. Vikram also has a successful banking career for more than 10+ years and is also a visiting faculty at various prestigious global institutes.`,
        Src: ImageFive,
        linkdin: 'https://www.linkedin.com/in/vikrampandya/'
    },
    {
        PersonName: `Jatin Sharma`,
        Position: `Technical Project Manager`,
        About: `Having experience of 14+ years in Real Time Software Development, Media Asset Management, Logic design, building highly efficient teams and delivering innovative business solutions. Having skill set covers software architecture, project management, development of use cases, researching for technical approaches to build the solution, software programming & engineering and web application development. Also have an expertise in telecom and media industry process.`,
        Src: ImageSeven,
        linkdin: 'https://www.linkedin.com/in/jatin-sharma/'
    },
    {
        PersonName: `Suprita Sinha`,
        Position: `Software Engineer`,
        About: `A diligent and skilled developer with 2+ years' experience. Seeking a role to utilise and culminate existing skill set at an organization of repute.`,
        Src: ImageEight,
        linkdin: 'https://www.linkedin.com/in/suprita-sinha-0421bb171/'
    }
];