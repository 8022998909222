import React from "react";
import CompAdavatage_Component from "../CompAdavtage_Component";
import Document_Component from "../Document_Component";
import InspectBanner_Component from "./InspectBanner_Component";
import ImageOne from "../../../images/Inspect-tech/Image-1.png";
import ImageTwo from "../../../images/Inspect-tech/Image-2.png";
import ImageThree from "../../../images/Inspect-tech/Image-3.png";

const InspectLanding_Component = () => {
    return (
        <>
            <InspectBanner_Component />
            <CompAdavatage_Component CompImage={ImageOne} Data_List={List1}>
                <h2 className="fw-bold text-capitalize mb-4">Migrating to <span className="text-info">API</span></h2>
            </CompAdavatage_Component>
            <Document_Component Image={ImageTwo} Data_List={List2}>
                <h2 className="fw-bold text-capitalize mb-4"> Enhance  <span className="text-info">partner</span> collaboration </h2>
            </Document_Component>
            <CompAdavatage_Component CompImage={ImageThree} Data_List={List3}>
                <h2 className="fw-bold text-capitalize mb-4">Get <span className="text-info">Digital Certificate </span> from Inspection Agencies</h2>
            </CompAdavatage_Component>

        </>
    );
}

export default InspectLanding_Component;



const List1 = [
    "In the face of today’s business realities, legacy EDI systems continue to be costly for all participants in point-to-point communications.",
    "Significant savings can be realized when enterprises migrate to API machine-to-machine communications. ",
    "TradeReboot open APIs offer permissioned and direct access to data from more than half of the world’s containerized trade.",
];

const List2 = [
    "Thirty percent of companies work with twice as many partners – suppliers, logistics providers and others – than they did two years ago.",
    "Coordinating this new influx of supply chain information can be cumbersome with legacy technologies and systems.",
    "TradeReboot helps you simplify partner collaboration through a shared, common view of trusted shipment data. ",
    "Permissioned access for customs brokers, 3PLs, carriers and others streamlines workflows, reduces manual effort and helps eliminate errors.",
    "With data published to the platform, permissioned parties can view and act on information in near real time, benefitting from more granular and detailed analytics up and down the supply chain."
];

const List3 = [
    "Our platform is built to allow traders to send inspection nominations to surveyors and for the latter to contribute their results and invoice their clients seamlessly. ",
    "Inspection agencies through our platform can issue digital certificates online which reduces the possibility of any misstatement in the verification of goods by the inspection agencies and also reduces the hassles of paperwork. ",
    "This digital certificate will be authenticated by the authorised personnel by a digital signature thus ensuring the quality of commodities inspection services is the next logical step to eliminating.",
];
