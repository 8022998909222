/* eslint-disable react/jsx-pascal-case */
import React, { useState, useEffect } from "react";
import BlogNav_Component from "./BlogNav_Component";
import BlogBreadCrump_Component from "./BlogBreadCrump_Component";
import Blog_Component from "./Blog_Component";
import BlogImg from "../../../images/News Blog Page/Blog-Img.png";
import axios from "axios";
import { websiteBackendURL } from "../../../config";

const BlogPageMain_Component = () => {

  const [data, setData] = useState([]);

  useEffect(() => {

    window.onload = function () {
      try {
        var url_string = (window.location.href);
        var url = new URL(url_string);
        var id = url.searchParams.get('id');

        console.log(id);
        axios.post(websiteBackendURL + '/getNewsDataWithId', { 'id': id }).then(response => {
          console.log(response.data)
          setData(response.data);
        });

      } catch (error) {
        console.log('error in getNewsDataWithId ', error);
      }
    }
  }, []);

  return (
    <>
      <BlogNav_Component />
      <BlogBreadCrump_Component />
      {data.length > 0 && data.map((key, value) => {
        return (<>
          <Blog_Component
            heading={key.heading}
            articledate={key.created_at}
            fileHash={key.fileHash}
            DataList={key.description}
            fileName={key.file_name}
          />
        </>)
      })}
    </>
  );
};
export default BlogPageMain_Component;
