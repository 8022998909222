import React from "react";
import LogiBanner_Component from "./LogiBanner_Component";
import CompAdavatage_Component from "../CompAdavtage_Component";
import SetProcess_Component from "./SetProcess_Component";
import Image7 from "../../../images/Logi-tech/Image-7.png";
import Image8 from "../../../images/Logi-tech/Image-8.png";
import FeaturesCarosuel_Component from "./FeaturesCarosuel_Component";

const List = [
    "Trade reboot is emerging to give solutions for the challenges in supply chain management by creating an encrypted digital record that tracks the shipment at every stage. ",
    "Our solution is automated so it makes the whole process easier to verify goods, it reduces paperwork and supporting end to end traceability. ",
    "LOGI-TECH provides complete shipping management software and solution to all the stake owners."
];

const LogiLanding_Component = () => {
    return (
        <>
            <LogiBanner_Component />
            <CompAdavatage_Component CompImage={Image7} Data_List={List} >
                <h2 className="fw-bold text-capitalize mb-4">Logitech</h2>
            </CompAdavatage_Component>
            <SetProcess_Component />
            <FeaturesCarosuel_Component />
        </>
    );
}
export default LogiLanding_Component;