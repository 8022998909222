import React from "react";
import { NavLink } from "react-router-dom";

const BlogBreadCrump_Component = () => {
  return (
    <>
      <div className="container my-5">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item ">
              <NavLink
                className=" text-decoration-none text-secondary"
                to="/NewEvents"
              >
                News & Events
              </NavLink>
            </li>
            <li
              className="breadcrumb-item active text-decoration-none text-secondary"
              aria-current="page"
            >
              Trade Blogs
            </li>
          </ol>
        </nav>
      </div>
    </>
  );
};
export default BlogBreadCrump_Component;
