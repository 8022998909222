/* eslint-disable react/jsx-pascal-case */
import React from "react";
import Comment_Component from "./Comment_Component";
import LeaveComment_Component from "./LeaveComment_Component";

const Blog_Component = ({ heading, articledate, fileHash, DataList, fileName }) => {

  let mime = fileName.split(".").pop()
  let sourceType = getSourceType(mime)

  function getSourceType(mime) {
    return ((mime === "png" || mime === "PNG") ? "data:image/png;base64," :
      (mime === "jpg" || mime === "JPG") ? "data:image/jpeg;base64," : "")
  }

  return (
    <>
      <div className="container">
        <h1 className="">{heading}</h1>
        <div className="row align-items-center">
          <div className="col-1 p-0">
            <nav className="nav ">
              <a
                className="text-dark text-decoration-none ms-3"
                aria-current="page"
                href="https://www.linkedin.com/"
                target="_blank"
                rel="noreferrer"
              >
                <span className="fs-5 fab fa-linkedin"></span>
              </a>
              <a
                className="text-dark text-decoration-none ms-2"
                href="https://www.facebook.com/"
                target="_blank"
                rel="noreferrer"
              >
                <span className="fs-5 fab fa-facebook-square"></span>
              </a>
              <a
                className="text-dark text-decoration-none ms-2"
                href="https://twitter.com/"
                target="_blank"
                rel="noreferrer"
              >
                <span className="fs-5 fab fa-twitter-square"></span>
              </a>
            </nav>
          </div>
          <div className="col-2 p-0">
            <p className="mb-0 text-info">{articledate}</p>
          </div>
        </div>
        {/* <img
          src={Image}
          className="img-fluid my-4"
          alt="..."
          style={{ width: "100%" }}
        /> */}
        <img className="img-fluid my-4" src={sourceType + encodeURI(fileHash ? fileHash : "")} alt={fileName} style={{ width: "100%" }} />
        <p>{DataList}</p>
        <LeaveComment_Component />
        <h4 className="fw-bold mb-5">Comments</h4>
        <Comment_Component
          name="Comment-1"
          date="Nov 25, 2020,  09:00 AM"
          comment="TradeTech UAE to launch Global Blockchain commodity trade platform TradeTech UAE to launch Global Blockchain commodity trade platform TradeTech UAE to launch Global Blockchain commodity trade platform"
          collapseid="id1"
        />
        <Comment_Component
          name="Comment-2"
          date="Nov 25, 2020,  09:00 AM"
          comment="TradeTech UAE to launch Global Blockchain commodity trade platform TradeTech UAE to launch Global Blockchain commodity trade platform TradeTech UAE to launch Global Blockchain commodity trade platform"
          collapseid="id2"
        />
      </div>
    </>
  );
};
export default Blog_Component;
