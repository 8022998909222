import React from "react";
import { NavLink } from "react-router-dom";
import HelpdeskContact_Component from "../Help Desk/HelpdeskContact_Component";


const ContactFormText_Component = () => {
    return (
        <>
            <HelpdeskContact_Component />
        </>
    );
}
export default ContactFormText_Component;