import React from "react";


const InspectCards_Component=({Image, Paragraph})=>{
    return(
        <>
            <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6  text-center cursor">
                <div className="card SolutionInspectsection slide p-3">
                    <div className="card-header CardHeader ">
                        <img src={Image} alt="Image" className="img-fluid " />
                    </div>
                    <div className="card-body ">
                        <p className="fw-bold">{Paragraph}</p>
                    </div>
                </div>
            </div>
        </>
    );
}
export default InspectCards_Component;