import React from "react";
import Image1 from "../../../images/Solution Page images/Image-11.png";
import Image2 from "../../../images/Solution Page images/Image-12.png";
import Image3 from "../../../images/Solution Page images/Image-13.png";
import Image4 from "../../../images/Solution Page images/Image-14.png";
import InspectCards_Component from "./InspectCards_Component";


const InspectTechSolution_Component=()=>{
    return(
        <>
            <div className="container my-5">
                <h5 className="text-center fw-bold text-uppercase">INSPECT TECH</h5>
                <h2 className="text-center fw-bold"> The  <span className="TextColor"> Fast </span> Changing  <span className="TextColor"> Technology</span></h2>
                <p className="text-center col-xl-7 col-lg-7 col-md-11 col-sm-11 my-2 text-secondary mx-auto">TradeReboot gives you the ability to innovate with access to trusted information from your entire logistics ecosystem.</p>
                <div className="col-xl-11 col-lg-11 col-sm-12 col-md-12 my-5 mx-auto">
                    <div className="" >
                        <div className="">
                            <div className="row ">
                                <InspectCards_Component Image={Image1} Paragraph="Innovate labour-intensive processes" />
                                <InspectCards_Component Image={Image2} Paragraph="Enhance partner collaboration" />
                                <InspectCards_Component Image={Image3} Paragraph="Get Digital Certificate from Inspection Agencies" />
                                <InspectCards_Component Image={Image4} Paragraph="Migrating to API" />
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default InspectTechSolution_Component;