import React from "react";
import SelectInput_Component from "../../Home/BookAppointment/SelectInput_Component";
import TextInput_Component from "../../Home/BookAppointment/TextInput_Component";
import BasicAccordion_Component from "./BasicAccordion_Component";
import TaxDetailsAccordion_Component from "./TaxDetailsAccordion_Component";



const BasicDetails_Component = ({ insuranceData }) => {
    return (
        <>
            <div className="container">
                <BasicAccordion_Component basicDetails={insuranceData[0]} />
                <TaxDetailsAccordion_Component taxDetails={insuranceData[0].taxDetails} />
            </div>

        </>
    );
}

export default BasicDetails_Component;