import React from "react";
import RateListCard from "./RateListCard";
import Trackdetails from "./Trackdetails";
import moment from 'moment';

const ShipRatesAccordian_Component = ({ accId, headerId, target, weekHeading, tarnsitTime, result, allData, data, isRateCalculated,
  getQuotation, condition }) => {

  console.log('weeksheading => ', weekHeading);
  const List_Data = [
    // {
    //   name: "Origin Port", selector: "origin_port", sortable: true
    // }, //0
    // { name: "Destination Port", selector: "destination_port", sortable: true }, //1
    // { name: "Mode", selector: "mode", sortable: true }, //
    // { name: "Mode Type", selector: "mode_type", sortable: true }, //
    { name: "Company Name", selector: "company_name", sortable: true, condition: "00:01:10:11:12" }, //1
    { name: "Container Type", selector: "container_type", sortable: true, condition: "00:01" }, //2
    { name: "Container No", selector: "container_no", sortable: true, condition: "00:01" }, //3
    { name: "Cargo Type", selector: "cargo_type", sortable: true, condition: "11:12" }, //4
    // { name: "Start Date (yyyy-mm-dd)", selector: "start_date", sortable: true }, //5
    // { name: "End Date (yyyy-mm-dd)", selector: "end_date", sortable: true }, //6
    // { name: "Load Volume (cubic meter)", selector: "load_volume", sortable: true, condition: "01" }, //7
    // { name: "Load Weight (Kg)", selector: "load_weight", sortable: true, condition: "01" }, //8
    { name: "Vessel Type", selector: "vessel_type", sortable: true, condition: "10:11:12" }, //9
    { name: "Commodity Type", selector: "commodity_type", sortable: true, condition: "10" }, //10
    { name: "Flexibility Days", selector: "flexibility_days", sortable: true, condition: "11" }, //11
    { name: isRateCalculated ? "Price ($) (tentative)" : "Price", selector: "price", sortable: true, condition: "00:01:10:11:12" }, //12
    { name: "Currency", selector: "currency", sortable: true, condition: "00:01:10:11:12" }, //13
    // { name: "Created At", selector: "created_at", sortable: true } //14
  ];

  return (
    <>
      <div className="accordion accordion-flush" id={accId}>
        <div className="accordion-item accordionitem mb-3">
          <h2 className="accordion-header" id={headerId}>
            <button className="accordion-button collapsed" type="button"
              data-bs-toggle="collapse" data-bs-target={`#${target}`}
              aria-expanded="false" aria-controls={target}>
              <div className="col-11">
                <div className="d-flex">
                  {/* <div className="col-2">
                    <p className="mb-0">{"Shipping Rate List"}</p>
                  </div> */}
                  <div className="col-7">
                    <p className="mb-0">Shipping Rates List</p>
                  </div>
                  <div className="col-5 text-end">
                    <p className="mb-0">{result} Results</p>
                  </div>
                </div>
              </div>
            </button>
          </h2>
          <div id={target} className="accordion-collapse collapse"
            aria-labelledby={headerId} data-bs-parent={`#${accId}`}>
            <div className="card mt-2">
              {data.map((item, index) => (
                <RateListCard accChildid={"accordionFlushExample" + index} AccChildHeader={"flush-headingOne" + index}
                  AccChildCollapse={"flush-collapseOne" + index}
                  startDate={moment(item.start_date).format("YYYY-MM-DD")} StartLoc={item.origin_port}
                  // NoOfDays={allData.tarnsitTime} 
                  endDate={item.end_date ? moment(item.end_date).format("YYYY-MM-DD") : 'NA'}
                  getQuotation={getQuotation}
                  EndLoc={item.destination_port} List_Data={List_Data} AdditionalData={item} isRateCalculated={isRateCalculated}
                  condition={condition} />
              ))}
              {/* <RateListCard accChildid="accordionFlushExample" AccChildHeader="flush-headingOne" AccChildCollapse="flush-collapseOne"
                startDate={allData.departureDate} StartLoc={allData.departurePort} NoOfDays={allData.tarnsitTime} endDate={allData.arrivalDate}
                EndLoc={allData.arrivalPort} List_Data={List_Data} /> */}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ShipRatesAccordian_Component;