import React from "react";
import Title from "../Home/Title";
import EthicsImage from '../../images/Ethics.svg';
import CustomerImage from '../../images/customer.svg';
import LogoImage from '../../images/logo_mini.png';
import FutureImage from '../../images/Future.svg';
import QualityImage from '../../images/Quality.svg';
import { Fade } from "react-reveal";

const CoreValues_Components = () => {
    return (
        <>
            <div className="bg-light py-3 mb-5">
                <div className="text-center mb-5 mt-5">
                    <Title
                        Title={"Core Values"}
                        
                    />
                </div>
                <div className="container mb-5">
                    <div className="row">
                        <Fade left>
                            <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                                <div className="card card-border mb-3 CoreValues">
                                    <div className="card-body">
                                        <div className="text-center">
                                            <img src={EthicsImage} alt="Graph image" height="50px" className="" />
                                            <p className="fw-bold fs-5 mt-3">Ethics</p>
                                            <p className="para">We believe in working ethically and fairly with our client, employees, colleagues, and even people we are not associated with.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="card mt-4 card-border mb-3 CoreValues">
                                    <div className="card-body ">
                                        <div className="text-center">
                                            <img src={CustomerImage} alt="Graph image" height="50px" className="" />
                                            <p className="fw-bold fs-5 mt-3">Customer Centric</p>
                                            <p className="para">The power of knowledge is the biggest gift to the mankind. For the same reason, we hire, respect and cherish people who are highly knowledgeable, learned, experienced and skilled in their field.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Fade>
                        <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 mb-3">
                            <div className="card card-border">
                                <div className="card-body">
                                    <div className="text-center">
                                        <img src={LogoImage} alt="Graph image" className="img-fluid MiddleCard" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Fade right>
                            <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 mb-3">
                                <div className="card card-border CoreValues">
                                    <div className="card-body ">
                                        <div className="text-center">
                                            <img src={FutureImage} alt="Graph image" className="" height="50px" />
                                            <p className="fw-bold fs-5 mt-3">Future Orientation</p>
                                            <p className="para">Great thinkers of today can give a positive future tomorrow. We are motivated to make the process easier / smooth for clients as well as for us.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="card mt-4 card-border mb-3 CoreValues">
                                    <div className="card-body ">
                                        <div className="text-center">
                                            <img src={QualityImage} alt="Graph image" className="" height="50px" />
                                            <p className="fw-bold fs-5 mt-3">Quality</p>
                                            <p className="para">We believe, a job which lacks the highest quality we expect from it, is a job half done. When it comes to quality of work – WE DO NOT COMPROMISE.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Fade>
                    </div>
                </div>
            </div>



        </>
    );
}
export default CoreValues_Components;
