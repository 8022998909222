import React from "react";
import Tracking_Component from "./Tracking_Component";
import moment from 'moment';
import { platformURL } from "../../config";

const RateListCard = ({ accChildid, AccChildHeader, AccChildCollapse, startDate, StartLoc, endDate, EndLoc, List_Data, AdditionalData, isRateCalculated,
  getQuotation, condition }) => {
  let NoOfDays = moment(endDate, "YYYY-MM-DD").diff(moment(startDate, "YYYY-MM-DD"), "days")
  return (
    <>
      <div className="accordion accordion-flush" id={accChildid}>
        <div className="accordion-item accordionfocus">
          <h2 className="accordion-header" id={AccChildHeader}>
            <button className="accordion-button collapsed" type="button"
              data-bs-toggle="collapse"
              data-bs-target={`#${AccChildCollapse}`} aria-expanded="false"
              aria-controls={`#${AccChildCollapse}`}>
              <div className="col-11 ">
                <div className="row">
                  <div
                    className="col-xl-9 col-lg-9 col-md-12 col-sm-12 border-end">
                    <div className="border-bottom ">
                      <img className="m-2" src="images/image.png"
                        height="20" alt="" />
                    </div>
                    <div className="d-flex mt-2">
                      <div className="col-4">
                        <p className="mb-0">{startDate}</p>
                        <p className="mb-0"
                          style={{ fontSize: "11px" }}>{StartLoc}
                        </p>
                      </div>
                      <div className="col-4 text-center">
                        {/* <p>{NoOfDays}</p> */}
                      </div>
                      <div className="col-4 text-end px-2">
                        <p className="mb-0">{endDate}</p>
                        <p className="mb-0"
                          style={{ fontSize: "11px" }}>{EndLoc}</p>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-xl-3 col-sm-12 col-md-12 col-lg-3 m-auto text-center">
                    <button onClick={() => {
                      isRateCalculated ? getQuotation() :
                        window.open(platformURL + "/login?mkt_ref=" + AdditionalData.id, "_blank")
                    }} type="button" className="btn btn-info">{
                        isRateCalculated ? "Get Quotation" : "Book Now"
                      }</button>
                  </div>
                </div>
              </div>
            </button>
          </h2>
          {/* <Tracking_Component /> */}
          <div id={AccChildCollapse} className="accordion-collapse collapse"
            aria-labelledby={`#${AccChildHeader}`}
            data-bs-parent={`#${accChildid}`}>
            <div className="">
              <div className="p-2">
                <div className="card-header cardheader">
                  Additional Details
                </div>
                <ul className="list-group list-group-flush">
                  {List_Data.map((key, index) => {
                    if (key.condition.includes(condition)) {
                      return (
                        <>
                          <li className="list-group-item">
                            <div className="d-flex">
                              <div className="col-6">
                                <span>{key.name} </span>
                              </div>
                              <div className="col-6 text-end">
                                <span className="fw-bold liRight">{AdditionalData[key.selector] || "NA"} </span>
                              </div>
                            </div>
                          </li>
                        </>
                      );
                    }
                  })
                  }
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default RateListCard;


