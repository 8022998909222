import React from "react";
import CarosuelItem_Component from "../Fin-Tech-component/CarosuelItem_Component";
import Image1 from "../../../images/Logi-tech/Image-1.png";
import Image2 from "../../../images/Logi-tech/Image-2.png";
import Image3 from "../../../images/Logi-tech/Image-3.png";
import Image4 from "../../../images/Logi-tech/Image-4.png";
import Image5 from "../../../images/Logi-tech/Image-5.png";
import Image6 from "../../../images/Logi-tech/Image-6.png";


const para1 = "Charter parties can connect directly to TradeReboot platform. The charterer and the ship-owner can pass on all the relevant information on platform once the smart contract has been executed and all the parties agrees to the contract the ship transports the cargo. Our platform helps to reengineering the whole End-to-end process in supply chain.";

const para2 = "The smart contract of TradeReboot helps to solve complex logistic ecosystem with its smart contracts once the conditions are written in our supply chain contract, it will be distributed across the network after performing the contract obligations our platform will execute the smart contracts once the conditions are met.";

const para3 = "On one click, our platform will help to quickly check the current commercial operation of a vessel. In addition to enabling Charterers to access vessel availability, TradeReboot platform can also be used to provide visibility to check whether cargoes have been loaded on the desired date and safely reached the required destination within the specified time frame.TradeReboot Platform can be used for real - time view of vessel activity.";

// const para4 = "TradeReboot platform provides real- time information on events and the status of shipment. Also our platform monitors performance history of carries and suppliers which will be helpful to create a trustworthy environment based on information of past performance.";

// const para5 = "TradeReboot have enabled L/C which may provide better document digitization because of its immutable functionality. When compared with traditional paper-intensive processing, our technology has the potential to present an innovative value from the perspective of cost and time reduction in financial processes.";

// const para6 = "Our platform gives an edge to create electronic bill of lading the same way as a regular BL, adding cargo description, the port, the vessel, etc. The final document is then uploaded on our platform with an attached Title Registry Instruction (TRI) – the record of the eBL that ensures that the data cannot be changed or copied. The eBL, including a TRI, is then electronically signed and sent to the shipper for review. When an eBL is surrendered, a carrier receives an email, allowing them to release the cargo at the discharge port immediately upon receipt.";

const FeaturesCarosuel_Component = () => {
  return (
    <>
      <div className="container-fluid ">
        <div className="py-5 text-center">
          <h3 className="fw-bold">Features</h3>
        </div>
        <div className="col-xl-9 col-md-10 col-lg-7 col-sm-10 mx-auto">
          <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
            <div className="carousel-inner">
              <div className="carousel-item active ">
                <CarosuelItem_Component Heading="Charter Parties Network/Management" Paragraph={para1} CarosuelImage={Image1} />
              </div>
              <div className="carousel-item">
                <CarosuelItem_Component Heading="Contract management" Paragraph={para2} CarosuelImage={Image2} />
              </div>
              <div className="carousel-item">
                <CarosuelItem_Component Heading="Time/Voyage Charter Management" Paragraph={para3} CarosuelImage={Image3} />
              </div>
              {/* <div className="carousel-item">
                <CarosuelItem_Component Heading="Real Time Tracking" Paragraph={para4} CarosuelImage={Image4} />
              </div>
              <div className="carousel-item">
                <CarosuelItem_Component Heading="LC Management" Paragraph={para5} CarosuelImage={Image5} />
              </div>
              <div className="carousel-item">
                <CarosuelItem_Component Heading="E-BL" Paragraph={para6} CarosuelImage={Image6} />
              </div> */}

            </div>
            <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
              <span className="iconify carousel-control-prev-icon text-dark" data-icon="bi:arrow-left-circle" aria-hidden="true"></span>
            </button>
            <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
              <span className=" iconify carousel-control-next-icon text-dark" data-icon="bi:arrow-right-circle" aria-hidden="true"></span>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
export default FeaturesCarosuel_Component;