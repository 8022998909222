import React from "react";
import ImageOne from "../../../images/Image-28.png";
import ImageTwo from "../../../images/Image-29.png";
import ImageThree from "../../../images/Image-30.png";
import { Fade } from "react-reveal";


const SetProcess_Component = () => {
  return (
    <>
      <div id="sec-1" className="container-fluid" >
        <div className="container pb-5">
          <h3 className="text-center py-5 text-light">Setup Process</h3>
          <div className="col-xl-9 col-md-12 col-sm-12 col-lg-10 mx-auto">
            <div className="tab-content" id="pills-tabContent ">
              <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                {Data_Cards.map((key, index) => {
                  return (
                    <Fade left>
                      <div className="card SetprocessCard my-5" key={index}>
                        <div className="row text-light">
                          <div className="col-xl-6 col-lg-6 col-md-10 col-sm-10 m-auto setProcessText py-3 px-4">
                            <h5>{key.Heading} </h5>
                            <p>{key.Paragraph}</p>
                          </div>
                          <div className="col-xl-5 col-lg-5 col-md-12 col-sm-12 SetprocessImage">
                            <img src={key.Image} alt="Step One" height="" width="" className="img-fluid" />
                          </div>
                        </div>
                      </div>
                    </Fade>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default SetProcess_Component;


const Data_Cards = [
  {
    Heading: "Finance",
    Paragraph: "Trade financing provides credit facilities in order to guarantee exchange of goods. TradeReboot has re- imagined how trade finance can operate leveraging a Blockchain based infrastructure to drive efficiencies, reduce cost base and open up new revenue opportunities, like newer models of credit and funding guarantees backing the trade.",
    Image: ImageOne,
  },
  {
    Heading: "LC Management",
    Paragraph: "Using blockchain, and LC can be modeled as a smart contract between the financier and the supplier to guarantee payment to the latter. Based on the documents submitted by the exporter, evaluating and verifying that the LC conditions meet specified shipment deadlines can be automated through program logic to indicate compliance or noncompliance for each case.",
    Image: ImageTwo,
  },
  {
    Heading: "Insurance & Inspection",
    Paragraph: "TradeReboot is one stop solution with smart contracts and decentralized applications, insurance can be conducted through blockchain account. It automates almost whole process and allow brokers, and regulates to share data securely in real-time and help in tracking and issue digital agreements and E- Certificates.",
    Image: ImageThree,
  },

];
