import React from "react";
import Title from "../Home/Title";
import Icondigital from "../../images/Navbar Icons/digital-icon.svg";
import Iconblockchain from "../../images/Navbar Icons/blockchain-icon.svg";
import Iconmarketplace from "../../images/Navbar Icons/marketplace-icon.svg";


const OurPlatform_Component = () => {
    return (
        <>

            <div className="Client text-center col-xl-6 mx-auto mt-5 mb-5">
                <Title
                    Title={"Our Platform"} />
            </div>
            <div className="container mt-5">
                <div className="row">
                    {platformData.map((key, index) => {
                        return (

                            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-10 mx-auto my-4 " key={index}>
                                <div className="card Card_Box ">
                                    <div className="card-body" >
                                        <div className="sec-4-icon">
                                            <img src={key.Icon} alt="" />
                                        </div>
                                        <p className="fw-bold mt-2">{key.Heading}</p>
                                        <p className="paragrapHeight">{key.Paragraph}</p>
                                    </div>
                                </div>
                            </div>

                        );
                    })}
                </div>
            </div>
        </>
    );
}
export default OurPlatform_Component;

const platformData = [
    {
        Icon: Icondigital,
        Heading: 'DIGITALIZED',
        Paragraph: 'Digitalization of whole trade process makes the whole process easy, seamless, hassle-free, faster, and completely eradicates paper documents from trade process. Out platform supports 100% paperless trade process. Digitalization makes it possible to store all documents digitally, process digital signature and electronic payments.',
    },
    {
        Icon: Iconblockchain,
        Heading: 'BLOCKCHAIN',
        Paragraph: 'Blockchain provides immutable data storage, which secures data from any kind of hacking a data loss. Blockchain encryption keeps all data private to the user where not even the admin has access to the data, Distributed Ledger Technology of blockchain provides dual benefits: Transparency & Trackability.',
    },
    {
        Icon: Iconmarketplace,
        Heading: 'MARKETPLACE',
        Paragraph: 'A single platform to bring all the stakeholders together – Traders, Shippers, Banks, Insurance & Inspection Agencies. A simpler way to connect, network and grow your trade.',
    },
];