/* eslint-disable react/jsx-pascal-case */
/* eslint-disable no-undef */
import React from "react";
import Input_Component from "../../Contact Us/Input_Component";

const LeaveComment_Component = () => {
  const astrix = <span className="required-field text-danger">*</span>;

  return (
    <>
      <div className="border my-5 rounded-3 p-5">
        <h3 className=" fw-bold">
          Leave a <span className="TextColor">Comment </span>
        </h3>
        <div className="row">
          <div className="col-6">
            <Input_Component
              Type="text"
              placeholder=" Name"
              label=" Name"
              id="FirstName"
              name="name"
              astrix={astrix}
            />
          </div>
          <div className="col-6">
            <Input_Component
              Type="email"
              placeholder="Email"
              label=" Email"
              id="Email"
              name="Email"
              astrix={astrix}
            />
          </div>
          <div className="form-floating FormFloating">
            <textarea
              className="form-control h-100"
              placeholder="Leave a comment here"
              name="comments"
              id="floatingTextarea"
            ></textarea>
            <label className="mx-3" htmlFor="floatingTextarea">
              Comments
            </label>
          </div>
        </div>

        <button className="btn Seemore mt-3">Post Comment</button>
      </div>
    </>
  );
};
export default LeaveComment_Component;
