/* eslint-disable react/jsx-pascal-case */
import React from "react";
import ShippingBanner_Component from "./ShippingBanner_Component";
// import ShippingSearch_Component from "./ShippingSearch_Component";
import ShippingSearch_Component from "./ShippingSearch_Component_V2";
import Image1 from "../../images/Shipping Images/Image-1.png";
import Image2 from "../../images/Shipping Images/Image-2.png";
import Image3 from "../../images/Shipping Images/Image-3.png";
import Image4 from "../../images/Shipping Images/Image-4.png";
import CardComponentLeftImg from "./CardComponentLeftImg";
import CardComponentRightImg from "./CardComponentRightImg";

const MarketPlaceLMain_Component = () => {
  return (
    <>
      <ShippingBanner_Component />
      <ShippingSearch_Component />
      <div className="container mt-5 ">
        <h2 className="fw-bold text-capitalize fs-1">Our Offerings</h2>
      </div>

      <CardComponentLeftImg CompImage={Image1} Data_List={List1}>
        <h2 className="fw-bold text-capitalize mb-4">
          World <span className="text-info">Largest</span> Shipping Platform
        </h2>
      </CardComponentLeftImg>
      <CardComponentRightImg Image={Image2} Data_List={List2}>
        <h2 className="fw-bold text-capitalize mb-4">
          {" "}
          Exclusive <span className="text-info">Discount</span> for Regular
          Importers/ Exporters{" "}
        </h2>
      </CardComponentRightImg>
      <CardComponentLeftImg CompImage={Image3} Data_List={List3}>
        <h2 className="fw-bold text-capitalize mb-4">
          Get Instant <span className="text-info">Lowest</span> Market{" "}
          <span className="text-info">Rate</span>
        </h2>
      </CardComponentLeftImg>
      <CardComponentRightImg Image={Image4} Data_List={List4}>
        <h2 className="fw-bold text-capitalize mb-4">
          {" "}
          Round the Clock <span className="text-info">Support</span>{" "}
        </h2>
      </CardComponentRightImg>
    </>
  );
};
export default MarketPlaceLMain_Component;

const List1 = [
  "TradeReboot provides all the services around shipping. If you dealing in Cargo or FCL or LCL ? TradeReboot is the one stop solution. Get the instant ocean and truck rates from biggest logistic companies across globe in your dashboard and negotiate with them.",
];
const List2 = [
  "As token of appreciation we provide exclusive discount for our regular customer. We provides special promo code depending on the orders and routes to our frequent Importers/ Exporters. To get the Promo Code Register on TradeReboot.com",
];
const List3 = [
  "On TradeReboot platform, You will get cheap rates to any country, from the world's top freight forwarders, saving you time and money. Our Team is working round the clock to get the best freight rates for the Importers/Exporters across the globe. ",
];
const List4 = [
  "We Provide Support 24 hours a day, 7 days a week, 365 days a year! We understand how important it is to support our customers while arranging their cargo movement, delivery and customs clearance. Our Customer Care Service is always available to support on all issues.",
];
