import React from "react";

const CarosuelItem_Component = ({ Heading, Paragraph, CarosuelImage }) => {
  return (
    <>
      <div className="col-xl-10 mx-auto my-5 CarosuelBg">
        <div className="bgImage">
          <div className="row">
            <div className="col-xl-10 col-lg-10 mx-auto col-md-10 col-sm-10 m-5 border CarosuelCard">
              <h4 className="my-3 text-light text-uppercase fw-bold">
                {Heading}
              </h4>
              <p>{Paragraph}</p>
            </div>
            {/* <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 m-auto">
              <img
                src={CarosuelImage}
                alt="CarosuelBg-img"
                className="img-fluid my-4"
              />
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};
export default CarosuelItem_Component;
