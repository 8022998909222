import React from "react";
import AboutUsBanner_Component from "./AboutUsBanner_Component";
import CoreValues_Components from "./CoreValues_Component";
import OurPlatform_Component from "./OurPlatform_Component";
import OurStory_Component from "./OurStory_Component";
import OurTeamSection_Component from "./OurTeamSection_Component";
import Whoweare_Component from "./Whoweare_Component ";



const AboutUsMain_Component = () => {
    return (
        <>
            <AboutUsBanner_Component />
            <Whoweare_Component />
            <OurPlatform_Component />
            <CoreValues_Components />
            <OurTeamSection_Component />
            <OurStory_Component />
        </>
    );
}

export default AboutUsMain_Component;