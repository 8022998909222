import React from "react";
import { Link } from "react-router-dom";
import FintechList_Component from "./FintechList_Component";
import Image1 from "../../../images/Solution Page images/Vector-1.png";
import Image2 from "../../../images/Solution Page images/Vector-2.png";
import Image3 from "../../../images/Solution Page images/Vector-3.png";
import Image4 from "../../../images/Solution Page images/Vector-4.png";
import Image5 from "../../../images/Solution Page images/Vector-5.png";
import Image6 from "../../../images/Solution Page images/Vector-6.png";


const FinTechSolution_Component = () => {
    return (
        <>
            <div className="container SolutionInsure">
                <div className="row">
                    <div className="col-xl-6 col-lg-5 col-md-10 col-sm-10 mx-auto">
                        <FintechList_Component Para="Seamless Third Party Integration" Image={Image1} />
                        <FintechList_Component Para="Flexible Product Attributes" Image={Image2} />
                        <FintechList_Component Para="Seamless Experience across Multiple Channel" Image={Image3} />
                        <FintechList_Component Para="Reusability of Standard Electronic Documents and Informations" Image={Image4} />
                        <FintechList_Component Para="Marketplace for Banks" Image={Image5} />
                        <FintechList_Component Para="High Level of Automated Workflow" Image={Image6} />
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-10 col-sm-10 m-auto ">
                        <h5 className=" fw-bold text-uppercase">FIN tech</h5>
                        <h2 className="fw-bold col-xl-8 col-lg-8 col-sm-12 col-md-12 mt-3 "> <span className="TextColor"> Ease </span> Of Technology During <span className="TextColor"> Payments</span></h2>
                        <p className=" my-2 text-secondary mx-auto mt-3">TradeReboot gives you the ability to innovate with access to trusted information from your entire logistics ecosystem.</p>
                        <ul className="ListStyleImage mt-4 mx-4">
                            <li className=" fs-6">Banks Can enhance their business on our Marketplace.</li>
                            <li className=" fs-6 mt-4">Setup Process</li>
                            <li className=" fs-6 mt-4">Onboarding</li>
                            <li className=" fs-6 mt-4">Features</li>
                        </ul>
                        <Link to="/solutions/fin-tech"><button className="Seemore mt-3">See More</button></Link>
                    </div>
                </div>
            </div>
        </>
    );
}
export default FinTechSolution_Component;
