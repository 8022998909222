import React from "react";
import CarosuelItem_Component from "../Fin-Tech-component/CarosuelItem_Component";
import Image1 from "../../../images/Trade-Tech Images/E-KYC-2.png";
import Image2 from "../../../images/Trade-Tech Images/E-KYC-3.png";
import Image3 from "../../../images/Trade-Tech Images/E-KYC-1.png";


const para1 = "Our platform data is on a decentralized network which can be accessed by parties after permission has been granted to them.";

const points1 = [
  "Distributed Data Collection",
  "Better Operational Efficiency",
  "Validation of Information Accuracy",
  "Real-Time Updated User Data"
]

const para2 = "TradeReboot redefines the invoice handling process, streamlines the workflows by eliminating the complexity & automating accounts payable. It helps organizations eliminate the paperwork by automating all the invoice management processes, including verification, validation, and reconciliation.";

const points2 = [
  "Save Times.",
  "Reduces cost.",
  "Reduce mistakes",
  "Reduce paper consumption",
  "Easier to keep track of invoices"
]

const para3 = "This platform helps to reduce the cost of shipping, improving transparency, ditching manual documentation and other conventional practices. TradeReboot exclusively offers inland carriers, ocean carriers, ports, harbors, terminals and freight forwarders spanning across three continents- Asia, Africa and Europe. It automates whole process and allow shipping agencies to share data securely in real - time and help in tracking and issue E- Bill of Lading(e - BL).";

const FeaturesCarosuel_Component = () => {
  return (
    <>
      <div className="container-fluid ">
        <div className="py-5 text-center">
          <h3 className="fw-bold">Features</h3>
        </div>
        <div className="col-xl-9 col-md-10 col-lg-7 col-sm-10 mx-auto">
          <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
            <div className="carousel-inner">
              <div className="carousel-item active ">
                <CarosuelItem_Component Heading="E-KYC" Paragraph={para1} CarosuelImage={Image1} Points={points1} />
              </div>
              <div className="carousel-item">
                <CarosuelItem_Component Heading="E-Invoice &MIS Reporting" Paragraph={para2} CarosuelImage={Image2} Points={points2} />
              </div>
              <div className="carousel-item">
                <CarosuelItem_Component Heading="Shipping" Paragraph={para3} CarosuelImage={Image3} />
              </div>

            </div>
            <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
              <span className="iconify carousel-control-prev-icon text-dark" data-icon="bi:arrow-left-circle" aria-hidden="true"></span>
            </button>
            <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
              <span className=" iconify carousel-control-next-icon text-dark" data-icon="bi:arrow-right-circle" aria-hidden="true"></span>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
export default FeaturesCarosuel_Component;