import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import Logo from '../../images/LogoWithName.png';
import validate from './FooterValidation';
import toastDisplay from '../ToastNotification';
import { Fade } from "react-reveal";
import { websiteBackendURL } from "../../config";

const Footer_Component = () => {

    const [data, setData] = useState({});
    const [error, setError] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const [refresh, setrefresh] = useState(0);

    const astrix = <span className="required-field text-danger">*</span>
    console.log('dataaa => ', data);

    useEffect(() => {
        if (Object.keys(error).length === 0 && isSubmitting) {
            handleSubmit()
        } else if (isSubmitting) {
            setIsSubmitting(false)
            // toastDisplay("Form Validation Error", "warn")
        }
    }, [error]);


    const handleChange = (event) => {
        event.persist();
        setData({ ...data, [event.target.name]: event.target.value });
        setError({ ...error, [event.target.name]: "" });
    }


    const preHandleSubmit = (event) => {
        if (event) event.preventDefault();
        setError(validate(data));
        setIsSubmitting(true)
    };

    function handleSubmit() {
        setShowLoader(true);

        fetch(websiteBackendURL + '/stayUpToDate', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        }).then((result) => {
            console.log('kkkkkkkkk ', result);
            setShowLoader(false);
            setrefresh(refresh + 1);
            toastDisplay('Inserted Successfully', "success");
        }).then((data) => {
            setShowLoader(false);
            console.log('kkkkkkkkk ', data)
        });
    }


    return (
        <>
            <Fade bottom>
                <footer id="sec-f">
                    <div className="container">
                        <div className="col-10 mx-auto">
                            <div className="row pt-5">
                                <div className="col-md-3 pl-5 px-0">
                                    <img className="" src={Logo} width="142" height="70" alt="Card image cap" />
                                    <div className="card-body px-0 py-5">
                                        <p className="card-text small mb-2">Copyright © 2021 TradeReboot.</p>
                                        <p className="card-text small">All rights reserved</p>
                                        <div className="IconsStyle my-4">
                                            <ul className="list-unstyled list-inline">
                                                <li className="list-inline-item render"> <a className="nav-link " href="https://www.instagram.com/tradereboot/" target="_blank"><span className="iconify icon-6" data-icon="ci:instagram" style={{ color: "white" }}></span></a></li>
                                                <li className="list-inline-item render">  <a className="nav-link" href="https://www.linkedin.com/company/trade-reboot/" target="_blank"><span className="iconify icon-6" data-icon="typcn:social-linkedin" style={{ color: "white" }}></span></a></li>

                                                <li className="list-inline-item render"> <a className="nav-link" href="https://twitter.com/tradereboot" target="_blank"><span className="iconify icon-6" data-icon="foundation:social-twitter" style={{ color: "white" }}></span></a></li>
                                                <li className="list-inline-item render"> <a className="nav-link" href="https://www.youtube.com/channel/UCi4UnoXXGCWnXcVq8Z-LIkA" target="_blank"><span className="iconify icon-6" data-icon="typcn:social-youtube" style={{ color: "white" }}></span></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-2" >
                                    <div className="">
                                        <div className="">
                                            <div className="nav-item pb-3">
                                                <h5>Company</h5>
                                            </div>
                                            <div className="nav-item pl-0 pb-3">
                                                <NavLink className="nav-link p-0" to="/about-us">About us</NavLink>
                                            </div>
                                            <div className="nav-item pl-0 pb-3">
                                                <NavLink className="nav-link p-0" to="/contact-us">Contact us</NavLink>
                                            </div>
                                            <div className="nav-item pl-0 pb-3">
                                                <NavLink className="nav-link p-0 " to="/coming_soon">MarketPlace</NavLink>
                                            </div>
                                            <div className="nav-item pl-0 pb-3">
                                                <NavLink className="nav-link p-0 " to="/NewEvents">Blog</NavLink>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="">
                                        <div className="">
                                            <div className="nav-item pb-3">
                                                <h5>Support</h5>
                                            </div>
                                            <div className="nav-item pl-0 pb-3">
                                                <a className="nav-link p-0 " href="./assets/docs/Terms_of_Use.pdf" target="_blank">Terms of service</a>
                                            </div>
                                            <div className="nav-item pl-0 pb-3">
                                                <a className="nav-link p-0 " href="./assets/docs/Privacy_&_Cookie_Policy.pdf" target="_blank">Privacy policy</a>
                                            </div>
                                            <div className="nav-item pl-0 pb-3">
                                                <NavLink className="nav-link p-0 " to="/help-desk" target="_blank">Help Desk</NavLink>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="">
                                        <div className="">
                                            <div className="nav-item pb-3">
                                                <h5>Solutions</h5>
                                            </div>
                                            <div className="nav-item pl-0 pb-3">
                                                <NavLink className="nav-link p-0 " to="/solutions/fin-tech">Fin-Tech</NavLink>
                                            </div>
                                            <div className="nav-item pl-0 pb-3">
                                                <NavLink className="nav-link p-0 " to="/solutions/trade-tech">Trade-Tech</NavLink>
                                            </div>
                                            <div className="nav-item pl-0 pb-3">
                                                <NavLink className="nav-link p-0 " to="/solutions/insure-tech">Insure-Tech</NavLink>
                                            </div>
                                            <div className="nav-item pl-0 pb-3">
                                                <NavLink className="nav-link p-0 " to="/solutions/inspect-tech">Inspect-Tech</NavLink>
                                            </div>
                                            <div className="nav-item pl-0 pb-3">
                                                <NavLink className="nav-link p-0 " to="/solutions/logi-tech">Logi-Tech</NavLink>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3" style={{ paddingLeft: "0px" }}>
                                    <div className="">
                                        <div className="">
                                            <div className="nav-item">
                                                <h5>Stay up to date</h5>
                                            </div>
                                            <div className="nav-item mt-4 pr-1 col-12">

                                                <div className="input-group search-foot">
                                                    <input type="email" className="form-control btn py-2 text-start" placeholder="Your Email Address" aria-label="Your Email Address" aria-describedby="email" name="email" value={data.email} onChange={handleChange} />
                                                    <button className="input-group-text icon-foot" type="button" onClick={(e) => preHandleSubmit(e)}><span className="iconify icon-butt" data-icon="feather:send"></span></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </Fade>
        </>
    );
}
export default Footer_Component;