/* eslint-disable react/jsx-pascal-case */
import React from "react";
import InsuranceBanner_Component from "./InsuranceBanner_Component";
import InsuranceSearch_Component from "../../MarketPlace/Insurance/InsuranceSearch_Component";
import Image1 from "../../../images/Insurance_Images/image1.jpg";
import Image2 from "../../../images/Insurance_Images/image2.jpg";
import Image3 from "../../../images/Insurance_Images/image3.jpg";
import Image4 from "../../../images/Insurance_Images/image4.jpg";
import Image5 from "../../../images/Insurance_Images/image5.jpg";
import Image6 from "../../../images/Insurance_Images/image6.jpg";
import CardComponentLeftImg from "../CardComponentLeftImg";
import CardComponentRightImg from "../CardComponentRightImg";

const MarketPlaceLMain_Component = () => {
  return (
    <>
      <InsuranceBanner_Component />
      <InsuranceSearch_Component />
      <div className="container mt-5 ">
        <h2 className="fw-bold text-capitalize fs-1">Our Offerings</h2>
      </div>

      <CardComponentLeftImg CompImage={Image1} Data_List={List1}>
        <h2 className="fw-bold text-capitalize mb-4">
          Why <span className="text-info">Marine Insurance</span> is required for your business?
        </h2>
      </CardComponentLeftImg>
      <CardComponentRightImg Image={Image2} Data_List={List2}>
        <h2 className="fw-bold text-capitalize mb-4">
          {" "}
          What all <span className="text-info">modes</span> covers in Marine Insurance?{" "}
        </h2>
      </CardComponentRightImg>
      <CardComponentLeftImg CompImage={Image3} Data_List={List3}>
        <h2 className="fw-bold text-capitalize mb-4">
          World Largest <span className="text-info">Insurance Marketplace</span> Platform{" "}
          <span className="text-info">Rate</span>
        </h2>
      </CardComponentLeftImg>
      <CardComponentRightImg Image={Image4} Data_List={List4}>
        <h2 className="fw-bold text-capitalize mb-4">
          {" "}
          Get Instant Lowest <span className="text-info">Market Rate</span>{" "}
        </h2>
      </CardComponentRightImg>
      <CardComponentLeftImg CompImage={Image6} Data_List={List5}>
        <h2 className="fw-bold text-capitalize mb-4">
          Exclusive <span className="text-info">Discount</span> for Regular Importers/ Exporters{" "}
          <span className="text-info">Rate</span>
        </h2>
      </CardComponentLeftImg>
      <CardComponentRightImg Image={Image5} Data_List={List6}>
        <h2 className="fw-bold text-capitalize mb-4">
          {" "}
          Round the  <span className="text-info">Clock</span> Support{" "}
        </h2>
      </CardComponentRightImg>
    </>
  );
};
export default MarketPlaceLMain_Component;

const List1 = [
  "If you’re a business person exporting your goods cross border, you’re likely to be worried about a lot of things already. Trade reboot Insurance marketplace Platform, Insuring your goods against any mishappening, while they are being transported & securing your business. Knowing that all your stuff is safe means you can breathe easy about this one thing at least. Your goods in transit will be protected against vehicle collision, overturning, derailment, or accidents happening anywhere from the source to destination.",
];
const List2 = [
  "All the modes of transportation namely, air, water, rail & road are covered under this policy. Goods being transported via courier services are also insured. This coverage can also be extended to loss or damage of goods due to theft, strike, riots, terrorism, and other hostile acts by human by opting for appropriate coverage as per one’s needs. any transport by which the property is transferred, acquired, or held between the points of origin and the final destination.",
];
const List3 = [
  "TradeReboot provides all the services around Marine Insurance. TradeReboot is the one stop solution. Get the instant Insurance rates from biggest Insurance companies across globe in your dashboard and negotiate with them.",
];
const List4 = [
  "On TradeReboot platform, You will get cheap rates to any country, from the world's top insurance companies, saving you time and money. Our Team is working round the clock to get the best freight rates for the Importers/Exporters across the globe.",
];
const List5 = [
  "As token of appreciation we provide exclusive discount for our regular customer. We provides special promo code depending on the orders and routes to our frequent Importers/ Exporters. To get the Promo Code Register on TradeReboot.com .",
];
const List6 = [
  "We Provide Support 24 hours a day, 7 days a week, 365 days a year! We understand how important it is to support our customers while arranging their cargo movement, delivery and customs clearance. Our Customer Care Service is always available to support on all issues.",
];