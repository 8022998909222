import React from "react";
import ImageOne from "../../../images/Insure-tech/Image-3.png";
import ImageTwo from "../../../images/Insure-tech/Image-4.png";
import ImageThree from "../../../images/Insure-tech/Image-5.png";

import InsureCard_Component from "./InsureCard_Component";

const ParagraphOne = "Insurance buyers digitise assets & contract rules for a digital experience via the Insure Tech platform. ";
const ParagraphTwo = "Participants are connected to digital insurance contracts, risk event data and the automation platform.";
const ParagraphThree = "Event-driven premium calculation,document generation, risk data sharing and renewal packaging.";

const EasySteps_Component = () => {
    return (
        <>
            <div className="container-fluid " >
                <div className="col-xl-9 col-lg-9 col-md-12 col-sm-12 mx-auto " >
                    <h3 className="text-center fw-bold ">Insure Tech In <span className="text-info">Three Easy</span> Steps</h3>
                    <div className="row my-5">
                        <InsureCard_Component CardImage={ImageOne} Heading="Digitise" Paragraph={ParagraphOne} />
                        <InsureCard_Component CardImage={ImageTwo} Heading="Connect" Paragraph={ParagraphTwo} />
                        <InsureCard_Component CardImage={ImageThree} Heading="Automate" Paragraph={ParagraphThree} />
                    </div>
                </div>
            </div>
        </>
    );
}
export default EasySteps_Component;