import React from "react";
import { NavLink } from "react-router-dom";

const PopularPost_Component = ({ fileId, BlogHeading, DateOfArticle, Paragraph, Bloglink, fileHash, fileName }) => {

  let mime = fileName.split(".").pop()
  let sourceType = getSourceType(mime)

  function getSourceType(mime) {
    return ((mime === "png" || mime === "PNG") ? "data:image/png;base64," :
      (mime === "jpg" || mime === "JPG") ? "data:image/jpeg;base64," : "")
  }

  return (
    <>
      <div className="my-4">
        <div className="card RightCard">
          <div className="px-2 d-flex align-items-center">
            <div className="col-3 BlogRightCardsImage">
              {/* <img
                src={ImagePost}
                alt="Blog "
                className="rounded-3 "
                height="75px"
              /> */}
              <img className="rounded-3 " width="75px" height="75px" src={sourceType + encodeURI(fileHash ? fileHash : "")} alt="Blog " />
            </div>
            <div className="col-9 RightCardText py-2 ps-3 align-items-center">
              <h6 className="fw-bold">{BlogHeading}</h6>
              <p className="mb-0">
                {Paragraph.slice(0, 50)}{" "}
                {/* {Paragraph}{" "} */}
                <span className="mt-2">
                  {" "}
                  <NavLink to={Bloglink} target="_blank" className="text-decoration-none ms-1" rel="noreferrer">
                    <a href={`https://webdev.fob.sofodev.co/NewEvents/blog-events?id=${fileId}`} target="_blank" className="text-decoration-none" rel="noreferrer" >Read More...</a>
                  </NavLink>
                </span>
              </p>
              <div>
                <p className="text-secondary Publisheddate">{DateOfArticle}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PopularPost_Component;
