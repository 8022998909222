import React from "react";
import ImageOne from "../../../images/Image-28.png";
import ImageTwo from "../../../images/Image-29.png";
import ImageThree from "../../../images/Image-30.png";
import { Fade } from "react-reveal";


const SetProcess_Component = () => {
    return (
        <>
            <div id="sec-1" className="container-fluid" >
                <div className="container pb-5">
                    <h3 className="text-center py-5 text-light">Setup Process</h3>
                    <div className="col-xl-9 col-md-12 col-sm-12 col-lg-10 mx-auto">
                        <ul className="nav nav-pills mb-3 justify-content-center" id="pills-tab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <button className="nav-link active p-1 mx-3" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">Credit limit </button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link p-1 mx-3" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Fund request  </button>
                            </li>
                        </ul>
                        <div className="tab-content" id="pills-tabContent ">
                            <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                                {Data_Cards.map((key, index) => {
                                    return (
                                        <Fade left>
                                            <div className="card SetprocessCard my-5" key={index}>
                                                <div className="row text-light">
                                                    <div className="col-xl-6 col-lg-6 col-md-10 col-sm-10 m-auto setProcessText py-3 px-4">
                                                        <h5>{key.Heading} </h5>
                                                        <p>{key.Paragraph}</p>
                                                    </div>
                                                    <div className="col-xl-5 col-lg-5 col-md-12 col-sm-12 SetprocessImage">
                                                        <img src={key.Image} alt="Step One" height="" width="" className="img-fluid" />
                                                    </div>
                                                </div>
                                            </div>

                                        </Fade>
                                    );
                                })}

                            </div>
                            <div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                                {Data_CardsTwo.map((key, index) => {
                                    return (
                                        <Fade left>
                                            <div className="card SetprocessCard my-5" key={index}>
                                                <div className="row text-light" >
                                                    <div className="col-xl-5 col-lg-5 col-md-12 col-sm-12 SetprocessImagetwo">
                                                        <img src={key.Image} alt="Step One" height="" width="" className="img-fluid" />
                                                    </div>
                                                    <div className="col-xl-6 col-lg-6 col-md-10 col-sm-10 m-auto setProcessText py-3">
                                                        <h5>{key.Heading} </h5>
                                                        <p>{key.Paragraph}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </Fade>
                                    );
                                })}
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </>
    );
}
export default SetProcess_Component;


const Data_Cards = [
    {
        Heading: "Provide documents ",
        Paragraph: "Applicant has to first provide all the documents and details necessary for credit limit setup .Applicant select a financier from the platform and send his details & desired credit limit .Seller give consent to Trade Reboot to send all his documents: KYC, Financial Statement, Bank statement, profile, Export details to the financier on behalf of him.",
        Image: ImageOne,
    },
    {
        Heading: "Application",
        Paragraph: "Applicant can select desired NBFC based on various factors such as country, base interest rate, other charges, etc. and send them application for credit limit . Applicant can accept or reject & ask for any changes.If applicant accepts, a credit limit of the applicant at financier for an agreed amount of time.",
        Image: ImageTwo,
    },
    {
        Heading: "Credit limit setup ",
        Paragraph: "Applicant will receive offer from various financier for credit limit setup, applicant can select or reject the offer, or request a new offer. After applicant pays the processing fees, and after receiving confirmation from the financier a credit limit will be setup in the platform. Financier reviews the application & if he approve the credit limit send the details & T&C to the applicant. Financier send details such as approved funding, funding percentage, tentative interest rate, additional fee & other T&C.",
        Image: ImageThree,
    },

];

const Data_CardsTwo = [
    {
        Heading: "Provide details ",
        Paragraph: "Applicant has to first provide all the documents and details necessary for finance such as : Buyer details, type of finance, etc.Seller select a financier with whom he have a credit limitSeller give consent to Trade Reboot to send all his documents: commodity contract details, Insurance detail, buyer information to the financier on behalf of him.",
        Image: ImageOne,
    },
    {
        Heading: "Application",
        Paragraph: "Applicant can select financier, he / she has credit limit with and ask for finance offer . Both seller & buyer have to agree with all the T&C, they can ask for changes or reject it alsoIf both accepts, they all: financier, buyer & seller have to digitally sign a legal binding document. Financier than can send funds to seller bank.",
        Image: ImageTwo,
    },
    {
        Heading: "Finance Approval ",
        Paragraph: "Applicant will receive offer from various financier for finance. Applicant can select or reject the offer or request for a new offer. After applicant accepts an offer, all other offers becomes void",
        Image: ImageThree,
    },

];
