import React from "react";


const InsureCard_Component = ({CardImage, Heading, Paragraph}) => {
    return (
        <>
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-10 mx-auto my-2">
                <div className="card EasyStepCard">
                    <div className="card-header CardHeader m-auto">
                        <img src={CardImage} alt="Image" className="img-fluid" />
                    </div>
                    <div className="card-body EasyStepCardBody pt-3 pb-4 text-center">
                        <h4 className="fw-bold">{Heading}</h4>
                        <p>{Paragraph}</p>
                    </div>
                </div>

            </div>
        </>
    );
}
export default InsureCard_Component;