import React from "react";

const Title=({Title, Description})=>{
    return(
        <>
            <div className="container text-center section-title">
                <h1 className="text-capitalize fw-bold">{Title}</h1>
                <h6>{Description}</h6>
            </div>
        </>
    );
}
export default Title;