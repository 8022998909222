/* eslint-disable react/jsx-pascal-case */

import React from "react";
import HelpDeskBanner_Component from './HelpDeskBanner_Component';
import HelpDeskSupportForm_Component from './HelpDeskSupport_Component';


const HelpDeskLanding_Component=()=>{
    return(
        <>
            
            <HelpDeskBanner_Component />
            <HelpDeskSupportForm_Component />
            
        </>
    );
}
export default HelpDeskLanding_Component;