import React from "react";
import ArrowOne from "../../../images/chevron-right-black.png";
import ArrowTwo from "../../../images/chevron-left-black.png";
import ImageOne from "../../../images/Image-15.png";
import ImageTwo from "../../../images/Image-16.png";
import ImageThree from "../../../images/Image-17.png";
import ImageFour from "../../../images/Image-18.png";
import CardsManage_Component from "../Logi-Tech-component/CardsManage_Component";


const ParagraphOne = "Trade Credit Insurance enables companies to extend more credit to customers while reducing the risk of non-payment, thereby promoting secured sales expansion, for example, expanding to new geographic areas and reducing dependence on L/C’s.";

const ParagraphTwo = "Credit insurance may be accepted as collateral to improve lending facilities with financial institutions. This can potentially deliver additional funding capabilities to support a company’s growth.";

const ParagraphThree = "Many public firms have used Trade Credit Insurance as a strategic solution to enable profitable growth and an assurance to shareholders that their large key assets on the balance sheet are protected. Accounts receivables may constitute upwards of 40% of assets on the balance sheet.";

const ParagraphFour = "Mitigate losses from buyer defaults caused by insolvency, non-payment or political events.";


const Benefits_Component = () => {
    return (
        <>
            <div className="container-fluid ">
                <h3 className="text-center fw-bold my-5">Benefits of <span className="text-info"> Trade Credit </span> Insurance</h3>
                <div className="col-xl-11 col-lg-11 col-sm-12 col-md-12 mx-auto">
                    <div id="carouselExampleInterval" className="carousel slide" data-bs-ride="carousel">
                        <div className="carousel-inner">
                            <div className="carousel-item active" data-bs-interval="10000">
                                <div className="col-xl-8 col-lg-9 col-md-10 col-sm-10 mx-auto">
                                    <div className="row">
                                        <CardsManage_Component ClassName="card insurecarosuel" CardImage={ImageOne} Heading="Increase sales and grow market share" Paragraph={ParagraphOne} />
                                        <CardsManage_Component ClassName="card insurecarosuel" CardImage={ImageTwo} Heading="Access to liquidity" Paragraph={ParagraphTwo} />
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item" data-bs-interval="10000">
                                <div className="col-xl-8 col-lg-9 col-md-10 col-sm-10 mx-auto">
                                    <div className="row">
                                        <CardsManage_Component ClassName="card insurecarosuel" CardImage={ImageThree} Heading="Create shareholder value" Paragraph={ParagraphThree} />
                                        <CardsManage_Component ClassName="card insurecarosuel" CardImage={ImageFour} Heading="Balance sheet protection" Paragraph={ParagraphFour} />
                                    </div>
                                </div>
                            </div>

                        </div>
                        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="prev">
                            <span className="" aria-hidden="true"><img src={ArrowTwo} alt="" /></span>
                        </button>
                        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="next">
                            <span className="" aria-hidden="true"><img src={ArrowOne} alt="" /></span>
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
}
export default Benefits_Component;