import React from "react";
import WhoweareCarosuel_Component from "./WhoweareCarosuel_Component";
import Image1 from "../../images/Aboutus/Whoweare-img-1.png";
import Image2 from "../../images/Aboutus/Whoweare-img-2.png";
import Image3 from "../../images/Aboutus/Whoweare-img-3.png";
import Image4 from "../../images/Aboutus/Whoweare-img-4.png";
import Image5 from "../../images/Aboutus/Whoweare-img-5.png";



const Para1 = "Trade Reboot Is A Trade Tech Company And Provides Digitized Solutions For The International Commodity Trade By Utilizing Technology Such As Blockchain, Artificial Intelligence, Machine Learning And By Integrating ICC Guidelines For Security Purposes.This Technology Brings All Related Intermediaries Like Buyers, Sellers, Miners, Traders, Shipping Agencies, Port Authorities, Stevedores, Banks, Insurance Companies, Inspecting Agencies, Finance Institutes On One Platform For A Seamless, Efficient, Secured And Easy Trade Process.";

const Para2 = "We Started Working On A 300-Year-Old Trade Process And Build Its Insights Into Trade Reboot. These Trade Reboot Based Products Enable Collaborative Commerce, Streamline Supply ChainBlockchain Based Financing Of Trade Tracking. At Trade Reboot, We Have Engineered The Tools To Realize This Vision. As This Will Be A Commodity Life Cycle From Start To Finish, We Will Also Be Having Individual Modules For All Parties In The Transaction On Our Platform Where Almost All Banks Will Be On Board To Deal With Clients On This Platform.";

const Para3 = "We Can Network Your Complex And Extended Supply Chain To Give You Real-Time Visibility Beyond Your Direct Suppliers, Distributors, Intermediaries, Etc. Our Approach Is Conceptually A Paradigm Shift In Connecting Enterprises. We Are An Enthusiastic Set Of Believers And We Have Built The Tools To Make It Happen. We Strive To Be The Best Blockchain Trade Commodity Platform Provider In Abu Dhabi. We Provide Digitalized Solutions To Traders, Banks, Financiers, Insurer, Shippers & Inspection Agencies For Seamless, Secured And Easy Trade Process.";

const Para4 = "We Aim To Bring All The Parties Involved In A Trade Process At One Place Through Our Blockchain Technology-Based Platform.Our Platform Is Also Integrated With Advanced Features Backed By Advanced Technology Such As RPA (Robotic Process Automation), ML (Machine Learning), OCR (Optical Character Recognition) And Deep Learning Which Make The Whole Process Autonomous And Smoother.";

const Para5 = "They Typically Have Low Margins And Goods Are Traded Cross Border In Jurisdictions Where There May Be A Lack Of Trust Between Counterparties. The Logistical Operations Include A Large Number Of Stakeholders Including The Importer, Exporter, Customs Clearance Agencies, Port Facilities, Shippers, Inspection Agencies And NVOCCs To Name But A Few. Currently, Each Of These Entities Maintain Their Own Independent Record Of The Activities, Which Must Be Manually Reconciled Upon The Receipt Of Physical Documents.";

const Whoweare_Component = () => {
    return (
        <>
            <div className="container-fluid ">
                <div className="">
                    <h3 className="text-center mt-5 fw-bold">Who We Are</h3>
                    <div className="container">
                        <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
                            <div className="carousel-inner">
                                <div className="carousel-item active ">
                                    <WhoweareCarosuel_Component Heading="Trade Reboot" Pargraph={Para1} CarosuelImage={Image1} />
                                </div>
                                <div className="carousel-item">
                                    <WhoweareCarosuel_Component Heading="Beginning" Pargraph={Para2} CarosuelImage={Image2} />
                                </div>
                                <div className="carousel-item">
                                    <WhoweareCarosuel_Component Heading="Network" Pargraph={Para3} CarosuelImage={Image3} />
                                </div>
                                <div className="carousel-item">
                                    <WhoweareCarosuel_Component Heading="Technology" Pargraph={Para4} CarosuelImage={Image4} />
                                </div>
                                <div className="carousel-item">
                                    <WhoweareCarosuel_Component Heading="Stakeholders" Pargraph={Para5} CarosuelImage={Image5} />
                                </div>

                            </div>
                            <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                                <span className="iconify carousel-control-prev-icon text-dark" data-icon="bi:arrow-left-circle" aria-hidden="true"></span>
                            </button>
                            <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                                <span className=" iconify carousel-control-next-icon text-dark" data-icon="bi:arrow-right-circle" aria-hidden="true"></span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default Whoweare_Component;