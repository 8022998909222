import React from "react";


const BlogCard_Component=({ImageBlog, HeadingBlog, BlogParagraph, RedirectLink })=>{
    return(
        <>
            
            <div className="card ">
                <div className="card-header blogHeadheight">
                    <img src={ImageBlog} alt=" Image" />
                </div>
                <div className="card-body BlogCardHeight">
                    <h4>{HeadingBlog}</h4>
                    <p>{BlogParagraph}</p>
                </div>
                <div className="card-footer text-center BlogFooter">
                    <a className="text-decoration-none" href={RedirectLink} target="_blank" > Read More... </a>
                </div>
            </div>
        </>
    );
}
export default BlogCard_Component;
