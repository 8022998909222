import React, { useState, useEffect } from "react";
import ShippingData_Component from "../MarketPlace/ShippingData_Component";
import Registration from "./Registration";
import SealineList from '../MarketPlace/SealineList.json';
import validate from '../MarketPlace/ShippingValidation';
import WeekAccordian_Component from "../MarketPlace/WeekAccordian_Component";
import PageNotFound_Component from "../Page-Not found/PageNotFound_Component";
import { TabComponent } from "../CommonComponent/TabComponent";
import ShipRatesAccordian_Component from "../MarketPlace/ShipRatesAccodian_Component";
import axios from "axios";
import { AutoCompleteSuggestion } from "../CommonComponent/AutoCompleteSuggestion";
import { googleApiKey, platformURL, websiteBackendURL } from "../../config";
// import {
//   withScriptjs,
//   withGoogleMap,
//   GoogleMap,
//   DirectionsRenderer, Polyline, Marker
// } from "react-google-maps";
// import { compose, withProps, lifecycle } from "recompose";
// import { InfoBox } from "react-google-maps/lib/components/addons/InfoBox";
// import { MarkerWithLabel } from 'react-google-maps/lib/components/addons/MarkerWithLabel';
import { calcHaversineDistance } from "../../utils/myFunctions";

const cargoTypeDD = [
  { "name": "Break Bulk" },
  { "name": "Bulk Cargo" },
  { "name": "Oversize Load" },
  { "name": "Liquid Cargo" },
  { "name": "Gas" },
  { "name": "Wheeled Carg" }
]

// const containerTypeDD = [
//   { "name": "20 Standard" },
//   { "name": "40 Standard" },
//   { "name": "40 High Cube" },
//   { "name": "45 High Cube" },
//   { "name": "20 Open Top" },
//   { "name": "40 Open Top" },
//   { "name": "20 Flatrack" },
//   { "name": "40 Flatrack" },
//   { "name": "20 Refrigerate" }
// ]

const vesselTypeDD = [
  { "name": "General Cargo" },
  { "name": "Bulk Carriers" },
  { "name": "Containership" },
  { "name": "Tanker Market" },
  { "name": "Specialized" }
]


const ShippingSearch_Component = () => {

  const [data, setData] = useState({});
  const [dbData, setdbData] = useState([]);
  const [error, setError] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [sealineData, setSealineData] = useState({ modal: false, data: {} });
  const [pageNotFound, setPageNotFound] = useState(false);
  const [modeTab, setModeTab] = useState(0)
  const [shippingTypeTab, setShippingTypeTab] = useState(0)
  const [hoverItem, sethoverItem] = useState(null)
  const [autoCompleteData, setautoCompleteData] = useState([])
  const [country, setCountry] = useState([])
  const [ports, setPorts] = useState({
    "origin": [],
    "destination": []
  })
  const [portsLoc, setPortsLoc] = useState({})
  const [commodityTypeDD, setCommodityTypeDD] = useState([])
  const [infobox, setinfobox] = useState({})
  const [isRateCalculated, setisRateCalculated] = useState(false)
  const [showMap, toggleMap] = useState(false)
  const [containerTypeDD, setContainerTypeDD] = useState([])

  const modeTabLabels = ["Container", "Vessel"]
  const containerTypeLabels = ["FCL", "LCL"]
  const vesselTypeLabels = ['Voyage Charter', 'Time Charter', 'Bareboat Charter']

  useEffect(() => {
    setShowLoader(true)
    // axios.get(websiteBackendURL + "/shippingMarketPlace/getCountry", {}).then(result => {
    //   setShowLoader(false);
    //   setCountry(result.data)
    // });
    // axios.get(websiteBackendURL + "/commodity/getCommodityCategory", {}).then(result => {
    //   setCommodityTypeDD(result.data)
    // })
    axios.post(websiteBackendURL + "/shippingMarketPlace/getContainerTypesFromRateMaster", {}).
      then(result => {
        setContainerTypeDD(result.data)
      });
    getPortDetailsByCountry()
  }, [])

  const getInfoBoxDetails = (type) => {
    axios.post(websiteBackendURL + "/shippingMarketPlace/getAddressByLatLng", { ...portsLoc[type] }).then(result => {
      // console.log("getinfoboxdetails", type, result);
      setinfobox({ [type]: { show: true, address: result.data.address } })
    });
  }

  // useEffect(() => {
  //   if (data.origin_country_ac) {
  //     setShowLoader(true)
  //     axios.post(websiteBackendURL + "/shippingMarketPlace/getCountryPorts", { country_id: country.filter(item => { if (item.name === data.origin_country_ac) { return item } })[0].id }).
  //       then(result => {
  //         setShowLoader(false);
  //         if (result.data && result.data.length) {
  //           setPorts(result.data)
  //         }
  //       });
  //   }
  // }, [data.origin_country_ac])

  const getPortDetailsByCountry = (country_id, type) => {
    setShowLoader(true)
    // axios.post(websiteBackendURL + "/shippingMarketPlace/getCountryPorts", { country_id }).
    //   then(result => {
    //     setShowLoader(false);
    //     setPorts({
    //       ...ports,
    //       [type]: result.data
    //     })
    //   });
    axios.post(websiteBackendURL + "/shippingMarketPlace/getPortsFromRateMaster", { type: "origin" }).
      then(result => {
        axios.post(websiteBackendURL + "/shippingMarketPlace/getPortsFromRateMaster", { type: "destination" }).
          then(result2 => {
            setShowLoader(false);
            setPorts({
              ...ports,
              origin: result.data,
              destination: result2.data
            })
          });
      });
  }

  useEffect(() => {
    // console.log("google", new google.maps.Map());
    if (Object.keys(error).length === 0 && isSubmitting) {
      handleSubmit()
    } else if (isSubmitting) {
      setIsSubmitting(false)
    }
  }, [error]);

  const handleChange = (event) => {
    event.persist();
    setData({ ...data, [event.target.name]: event.target.value });
    setError({ ...error, [event.target.name]: "" });
    if (event.target.name === "origin_country") {
      // setinfobox({})
      if (event.target.value) {
        // getPortDetailsByCountry(event.target.value, "origin")
      }
      else {
        setData({ ...data, "origin_port": "" })
        setPorts({ ...ports, "origin": [] })
        setPortsLoc({ ...portsLoc, origin: {} })
      }
    }
    if (event.target.name === "destination_country") {
      // setinfobox({})
      if (event.target.value) {
        // getPortDetailsByCountry(event.target.value, "destination")
      }
      else {
        setData({ ...data, "destination_port": "" })
        setPorts({ ...ports, "destination": [] })
        setPortsLoc({ ...portsLoc, destination: {} })
      }
    }
    if (event.target.name === "origin_port") {
      if (event.target.value) {
        setPortsLoc({ ...portsLoc, origin: ports.origin.filter(item => { if (item.name === event.target.value) { return item } })[0] })
      }
      else {
        setPortsLoc({ ...portsLoc, origin: {} })
      }
    }
    if (event.target.name === "destination_port") {
      if (event.target.value) {
        setPortsLoc({ ...portsLoc, destination: ports.destination.filter(item => { if (item.name === event.target.value) { return item } })[0] })
      }
      else {
        setPortsLoc({ ...portsLoc, destination: {} })
      }
    }
  }

  const preHandleSubmit = (event) => {
    if (event) event.preventDefault();
    setError(validate(data, modeTab, shippingTypeTab));
    setIsSubmitting(true)
  };

  function handleSubmit() {
    setShowLoader(true);
    let reqObj = {
      mode: modeTabLabels[modeTab],
      mode_type: modeTab === 0 ? containerTypeLabels[shippingTypeTab] : vesselTypeLabels[shippingTypeTab],
      distance: calcHaversineDistance(portsLoc.origin.lat, portsLoc.origin.lng, portsLoc.destination.lat, portsLoc.destination.lng)
    }
    Object.keys(data).forEach(key => { reqObj[key] = data[key] })
    axios.post(websiteBackendURL + "/shippingMarketPlace/getRateList", reqObj).then(result => {
      // console.log("getRateList data=>", result);
      setShowLoader(false)
      if (result.data.length) {
        setdbData(result.data)
        setisRateCalculated(false)
        setPageNotFound(false)
      }
      else {
        setPageNotFound(true)
        setdbData([])
      }
      // else {
      //   // setPageNotFound(true)
      //   axios.post(websiteBackendURL + "/shippingMarketPlace/getCalculatedRateList", reqObj).then(result => {
      //     setShowLoader(false)
      //     if (result.data.length) {
      //       setdbData(result.data)
      //       setisRateCalculated(true)
      //     }
      //   })
      // }
    })
  }

  // console.log('sealineData => ', sealineData);

  const getAutoCompleteSuggestion = (event) => {
    event.persist()
    setData({ ...data, [event.target.name]: event.target.value });
    setError({ ...error, [event.target.name]: "" });
    axios.post(websiteBackendURL + "/shippingMarketPlace/getRateListSuggestion", {
      [event.target.name]: event.target.value
    }).then(result => {
      setautoCompleteData(result.data)
      // console.log("autocoml data=>", result, autoCompleteData);
    })
  }

  const getCountriesAcData = (event) => {
    event.persist()
    setData({ ...data, [event.target.name]: event.target.value });
    setError({ ...error, [event.target.name]: "" });
    let temp = []

    let dataToFilter = event.target.name.includes("country") ? country : ports

    if (dataToFilter.length) {
      for (let i = 0; i < dataToFilter.length; i++) {
        if (dataToFilter[i]["name"].toLowerCase().includes(event.target.value.toLowerCase())) {
          temp.push({ [event.target.name]: dataToFilter[i]["name"] })
        }
      }
      if (temp.length) {
        setautoCompleteData(temp)
      }
      else {
        setautoCompleteData([{ [event.target.name]: "No Results Found" }])
      }
    }
    else {
      setautoCompleteData([{ [event.target.name]: "No Results Found" }])
    }
    if (!event.target.value) {
      setautoCompleteData([])
    }
  }

  const clearAutoCompleteSuggestion = () => {
    setTimeout(() => {
      setautoCompleteData([])
    }, 500);
  }

  const disableInfoModal = () => {
    if (infobox.origin && infobox.origin.show) {
      setinfobox({ origin: { show: false } })
    }
    if (infobox.destination && infobox.destination.show) {
      setinfobox({ destination: { show: false } })
    }
  }

  const getDefaultMapCenter = () => {
    if (portsLoc.origin && portsLoc.origin.lat) {
      return Number.parseFloat(portsLoc.origin.lat), Number.parseFloat(portsLoc.origin.lng)
    }
    else {
      return 20.5937, 78.9629
    }
  }

  const insertQuotationInMaster = () => {
    setShowLoader(true)
    let req = JSON.parse(JSON.stringify(data))
    req["mode"] = modeTab === 0 ? "Container" : "Vessel"
    req["mode_type"] = modeTab === 0 ? containerTypeLabels[shippingTypeTab] : vesselTypeLabels[shippingTypeTab]
    req["origin_country"] = country.filter(item => { if (item.id == data.origin_country) { return item } })[0].name
    req["destination_country"] = country.filter(item => { if (item.id == data.destination_country) { return item } })[0].name
    req["price"] = dbData[0].price

    axios.post(websiteBackendURL + "/shippingMarketPlace/insertAutoCalcShippingRate", req).then(result => {
      // console.log("result", result);
      setShowLoader(false)
      if (result.data.id) {
        window.open(platformURL + "/login?mkt_ref=" + result.data.id, "_blank")
      }
    })
  }

  // const MapWithADirectionsRenderer = compose(
  //   withProps({
  //     googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${googleApiKey}&v=3.exp&libraries=geometry,drawing,places`,
  //     loadingElement: <div style={{ height: `100%` }} />,
  //     containerElement: <div className="mt-4" style={{ height: `20rem`, "overflow-anchor": "none" }} />,
  //     mapElement: <div style={{ height: `100%` }} />,
  //   }),
  //   withScriptjs,
  //   withGoogleMap
  // )(props =>
  //   <GoogleMap
  //     defaultZoom={4}
  //     defaultCenter={new window.google.maps.LatLng(Number.parseFloat(portsLoc.origin.lat), Number.parseFloat(portsLoc.origin.lng))}
  //     onClick={disableInfoModal}
  //   >
  //     {portsLoc.origin && portsLoc.origin.lat ? (
  //       <Marker position={{ lat: Number.parseFloat(portsLoc.origin.lat), lng: Number.parseFloat(portsLoc.origin.lng) }}
  //       // icon={{
  //       //   url: 'http://maps.google.com/mapfiles/ms/icons/red-dot.png', // url
  //       //   scaledSize: new window.google.maps.Size(50, 40), // scaled size
  //       // }}
  //       // onClick={() => { getInfoBoxDetails("origin") }}
  //       >
  //         {/* {infobox.origin && infobox.origin.show || true ? (
  //           <InfoBox
  //           >
  //             <div
  //               style={{ backgroundColor: `yellow`, opacity: 0.75, padding: `12px` }}>
  //               <h5 style={{ fontSize: `18px`, fontColor: `#08233B` }}>
  //                 {data.origin_port}
  //               </h5>
  //               <label style={{ fontSize: `14px`, fontColor: `#08233B` }}>
  //                 {data.origin_port}
  //               </label>
  //             </div>
  //           </InfoBox>
  //         ) : null} */}
  //       </Marker>
  //     ) : null}
  //     {portsLoc.destination && portsLoc.destination.lat ? (
  //       <Marker
  //         position={{ lat: Number.parseFloat(portsLoc.destination.lat), lng: Number.parseFloat(portsLoc.destination.lng) }}
  //       // icon={{
  //       //   url: 'http://maps.google.com/mapfiles/ms/icons/green-dot.png', // url
  //       //   scaledSize: new window.google.maps.Size(50, 40), // scaled size
  //       //   fillColor: "red",
  //       //   strokeColor: "red"
  //       // }}
  //       // onClick={() => { getInfoBoxDetails("destination") }} 
  //       >
  //         {/* {infobox.destination && infobox.destination.show ? ( */}
  //         {/* <InfoBox
  //         >
  //           <div style={{ backgroundColor: `yellow`, opacity: 0.75, padding: `12px` }}>
  //             <h5 style={{ fontSize: `18px`, fontColor: `#08233B` }}>
  //                 {data.destination_port}
  //               </h5>
  //             <label style={{ fontSize: `14px`, fontColor: `#08233B` }}>
  //               {data.destination_port}
  //             </label>
  //           </div>
  //         </InfoBox> */}
  //         {/* ) : null} */}
  //       </Marker>
  //     ) : null}
  //     {
  //       portsLoc.origin && portsLoc.destination ? (
  //         <Polyline
  //           path={[
  //             { lat: Number.parseFloat(portsLoc.origin.lat), lng: Number.parseFloat(portsLoc.origin.lng) },
  //             { lat: Number.parseFloat(portsLoc.destination.lat), lng: Number.parseFloat(portsLoc.destination.lng) }]}
  //           options={{
  //             strokeColor: "#CC33FF",
  //             strokeOpacity: 1,
  //             strokeWeight: 2,
  //             icons: [{
  //               icon: "hello",
  //               offset: '0',
  //               repeat: '10px'
  //             }],
  //           }}
  //         />
  //       ) : null
  //     }
  //   </GoogleMap >
  // );

  console.log("errrorrrrrrrrrrrrrr", error);

  return (
    <>
      {showLoader && (<div className="loading-overlay"><span><img className="" src={process.env.PUBLIC_URL + "/assets/images/loader.gif"}
        alt="description" /></span></div>)}

      <div className="searchId py-4">
        <div className="row g-3 mb-4 justify-content-center">
          {/* <div className="col-md-4">
            <TabComponent title={"Mode"} tabs={modeTabLabels}
              selectedTab={modeTab} onTabChange={(index) => {
                setShippingTypeTab(0); setModeTab(index); setdbData([]); setPageNotFound(false);
              }} />
          </div> */}
          <div className="col-md-4">
            <TabComponent title={"Container Type"} tabs={modeTab === 1 ? vesselTypeLabels : containerTypeLabels}
              selectedTab={shippingTypeTab} onTabChange={(index) => {
                setShippingTypeTab(index); setdbData([]); setPageNotFound(false);
              }} />
          </div>
        </div>

        <form autocomplete="off" className="">
          <div className="row mx-3 g-3 justify-content-center">
            {/* <div className="col-md-2">
              <div className="input-group ">
                <select className="form-select p-3" name="origin_country" id="origin_country" onChange={handleChange}>
                  <option value="" selected>Select Origin Country</option>
                  {country.map(item => (
                    <option value={item.id}>{item.name}</option>
                  ))}
                </select>
              </div>
              {error.origin_country ? <span className="text-danger fontValid">{"Origin Country " + error.origin_country}</span> : ''}
            </div> */}
            <div className="col-md-2">
              <div className="input-group ">
                <select className="form-select p-3" name="origin_port" value={data.origin_port} id="origin_port" onChange={handleChange}>
                  <option value="" selected>Select Origin Port</option>
                  {ports.origin.map(item => (
                    <option value={item.name}>{item.name}</option>
                  ))}
                </select>
                <span className="iconify me-2 align-self-center" data-icon="bx:bx-current-location" id="validationTooltipUsernamePrepend" style={{ color: "#23A7B6" }} ></span>
              </div>
              {error.origin_port ? <span className="text-danger fontValid">{"Origin Port " + error.origin_port}</span> : ''}
            </div>
            {/* <div className="col-md-2">
              <div className="input-group ">
                <select className="form-select p-3" name="destination_country" id="destination_country" onChange={handleChange}>
                  <option value="" selected>Select Destination Country</option>
                  {country.map(item => (
                    <option value={item.id}>{item.name}</option>
                  ))}
                </select>
              </div>
              {error.destination_country ? <span className="text-danger fontValid">{"Destination Country " + error.destination_country}</span> : ''}
            </div> */}
            <div className="col-md-2">
              <div className="input-group ">
                <select className="form-select p-3" name="destination_port" value={data.destination_port} id="destination_port" onChange={handleChange}>
                  <option value="" selected>Select Destination Port</option>
                  {ports.destination.map(item => (
                    <option value={item.name}>{item.name}</option>
                  ))}
                </select>
                <span className="iconify me-2 align-self-center" data-icon="bx:bx-current-location" id="validationTooltipUsernamePrepend" style={{ color: "#23A7B6" }} ></span>
              </div>
              {error.destination_port ? <span className="text-danger fontValid">{"Destination Port " + error.destination_port}</span> : ''}
            </div>
            <div className="col-md-2">
              <div className="input-group ">
                <select className="form-select p-3" name="container_type" value={data.container_type} id="container_type" onChange={handleChange}>
                  <option value="" selected>Select Container Type</option>
                  {containerTypeDD.map(item => (
                    <option value={item.name}>{item.name}</option>
                  ))}
                </select>
              </div>
              {error.container_type ? <span className="text-danger fontValid">{"Container Type " + error.container_type}</span> : ''}
            </div>
          </div>

          {/* {portsLoc.origin && portsLoc.origin.lat ? (
            <>
              <MapWithADirectionsRenderer />
            </>
          ) : null} */}

          <div className="row mx-3 g-3 justify-content-center my-3">
            {/* {modeTab === 0 && shippingTypeTab === 0 ? ( */}
            <>
              {/* <div className="col-md-2">
                  <div className="input-group ">
                    <select className="form-select p-3" name="container_type" value={data.container_type} id="container_type" onChange={handleChange}>
                      <option value="" selected>Select Container Type</option>
                      {containerTypeDD.map(item => (
                        <option value={item.name}>{item.name}</option>
                      ))}
                    </select>
                  </div>
                  {error.container_type ? <span className="text-danger fontValid">{"Container Type " + error.container_type}</span> : ''}
                </div> */}

              <div className="col-md-2">
                <div className="input-group  d-flex align-items-center">
                  <input type="number" min="1" className="form-control fs-6 p-3" name="container_no" value={data.container_no} id="validationTooltipUsername" aria-describedby="validationTooltipUsernamePrepend"
                    placeholder="Container No" required onChange={handleChange} />
                </div>
                {/* {error.container_no ? <span className="text-danger fontValid">{"Container No " + error.container_no}</span> : ''} */}
              </div>
            </>

            {/* ) : null} */}


            {modeTab === 1 ? (
              <div className="col-md-2">
                <div className="input-group ">
                  <select className="form-select p-3" name="vessel_type" value={data.vessel_type} id="vessel_type" onChange={handleChange}>
                    <option value="" selected>Select Vessel Type</option>
                    {vesselTypeDD.map(item => (
                      <option value={item.name}>{item.name}</option>
                    ))}
                  </select>
                </div>
                {error.vessel_type ? <span className="text-danger fontValid">{"Vessel Type " + error.vessel_type}</span> : ''}
              </div>

            ) : null}

            {(modeTab === 1 && (shippingTypeTab === 1 || shippingTypeTab === 2)) ? (
              <div className="col-md-2">
                <div className="input-group ">
                  <select className="form-select p-3" name="cargo_type" value={data.cargo_type} id="cargo_type" onChange={handleChange}>
                    <option value="" selected>Select Cargo Type</option>
                    {cargoTypeDD.map(item => (
                      <option value={item.name}>{item.name}</option>
                    ))}
                  </select>
                </div>
                {error.cargo_type ? <span className="text-danger fontValid">{"Cargo Type " + error.cargo_type}</span> : ''}
              </div>

            ) : null}

            {modeTab === 1 && shippingTypeTab === 1 ? (
              <div className="col-md-2">
                <div className="input-group  d-flex align-items-center">
                  <input type="number" min="1" className="form-control fs-6 p-3" name="flexibility_days" value={data.flexibility_days} id="validationTooltipUsername" aria-describedby="validationTooltipUsernamePrepend"
                    placeholder="Flexibility Days" required onChange={handleChange} />
                </div>
                {/* {error.flexibility_days ? <span className="text-danger fontValid">{"Flexibility Days " + error.flexibility_days}</span> : ''} */}
              </div>
            ) : null}

            {modeTab === 0 && shippingTypeTab === 1 ? (
              <>
                {/* <div className="col-md-2">
                  <div className="input-group  d-flex align-items-center">
                    <input type="number" min="1" className="form-control fs-6 p-3" name="load_volume" value={data.load_volume} id="validationTooltipUsername" aria-describedby="validationTooltipUsernamePrepend"
                      placeholder="Load Volume (cubic meter)" required onChange={handleChange} />
                  </div>
                  {error.load_volume ? <span className="text-danger fontValid">{"Load Volume " + error.load_volume}</span> : ''}
                </div> */}

                {/* <div className="col-md-2">
                  <div className="input-group  d-flex align-items-center">
                    <input type="number" min="1" className="form-control fs-6 p-3" name="load_weight" value={data.load_weight} id="validationTooltipUsername" aria-describedby="validationTooltipUsernamePrepend"
                      placeholder="Load Weight (Kg)" required onChange={handleChange} />
                  </div>
                  {error.load_weight ? <span className="text-danger fontValid">{"Load Weight " + error.load_weight}</span> : ''}
                </div> */}
              </>
            ) : null}

            {modeTab === 1 && shippingTypeTab === 0 ? (
              <div className="col-md-2">
                <div className="input-group ">
                  <select className="form-select p-3" name="commodity_type" value={data.commodity_type} id="commodity_type" onChange={handleChange}>
                    <option value="" selected>Select Commodity Type</option>
                    {commodityTypeDD.map(item => (
                      <option value={item.category}>{item.category}</option>
                    ))}
                  </select>
                </div>
                {/* {error.commodity_type ? <span className="text-danger fontValid">{"Commodity type " + error.commodity_type}</span> : ''} */}
              </div>

            ) : null}

            <div className="col-md-2">
              {/* <label >Expected Date</label> */}
              <div className="input-group align-items-center">
                <input type="date"
                  className="form-control fs-6 p-3" name="exp_date" value={data.exp_date} id="validationTooltipUsername" aria-describedby="validationTooltipUsernamePrepend" placeholder="Date" required onChange={handleChange} />
              </div>
              {error.exp_date ? <span className="text-danger fontValid">{"Expected date " + error.exp_date}</span> : ''}
            </div>

          </div>

          <div className="row g-3 mx-3 justify-content-center">
            {/* <div className="col-md-2">
              <label >Start Date</label>
              <div className="input-group align-items-center">
                <input type="date"
                  className="form-control fs-6 p-3" name="start_date" value={data.start_date} id="validationTooltipUsername" aria-describedby="validationTooltipUsernamePrepend" placeholder="Date" required onChange={handleChange} />
              </div>
              {error.start_date ? <span className="text-danger fontValid">{"Start date " + error.start_date}</span> : ''}
            </div>

            <div className="col-md-2">
              <label >End Date</label>
              <div className="input-group align-items-center">
                <input type="date"
                  className="form-control fs-6 p-3" name="end_date" value={data.end_date} id="validationTooltipUsername" aria-describedby="validationTooltipUsernamePrepend" placeholder="Date" required onChange={handleChange} />
              </div>
              {error.end_date ? <span className="text-danger fontValid">{"End date " + error.end_date}</span> : ''}
            </div> */}



          </div>

          <div className="row mt-3 justify-content-center">
            <button className="my-2 my-sm-0 head-button2" type="button" onClick={() => preHandleSubmit()}> Submit
              <span className="iconify ms-2 fs-6" data-icon="akar-icons:search" ></span>
            </button>
          </div>
        </form >
      </div >
      {
        dbData && dbData.length ?
          <>
            <div className="container">
              <div className="row">
                {/* <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-xs-12">
                <ShippingData_Component shippingData={sealineData} />
              </div> */}

                <div className="col-xl-8  col-lg-8 col-md-12 col-sm-12 col-xs-12 mx-auto margintop mt-5 ">
                  <ShipRatesAccordian_Component accId="trackOne" headerId="Track-headingOne" target="Track-collapseOne"
                    weekHeading={""} tarnsitTime={""} result={dbData.length} data={dbData} isRateCalculated={isRateCalculated}
                    getQuotation={() => insertQuotationInMaster()} condition={"" + modeTab + shippingTypeTab + ""} />
                  {/* {dbData.map((key, value) => {
                  return (<ShipRatesAccordian_Component accId="trackOne" headerId="Track-headingOne" target="Track-collapseOne"
                    weekHeading={""} tarnsitTime={""} result="1" allData={key} key={value} />)
                })
                } */}
                </div>
              </div>
            </div>
            <Registration />
          </> : null
      }
      {pageNotFound && (
        <>
          <PageNotFound_Component />
          {/* <div>
            <label>Get Quotation</label>
          </div> */}
        </>
      )}
    </>
  );
};
export default ShippingSearch_Component;
