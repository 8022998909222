import React from "react";

const HelpDeskBanner_Component=()=>{
    return(
        <>
            <div id="HelpDesk-Bg" className="container-fluid pb-5 ">
                <div className="container text-center">
                    <div className=" text-light pt-5">
                        <h1 className="text-capitalize">Help Desk</h1>
                        <h6 className="col-xl-8 col-lg-9 col-sm-12 col-md-12 mx-auto">Whether You Are A Corporation, A Commodity Trader Or A Financial Institution Providing Solutions In Global Trade, We Would Love To Hear From You. </h6>
                    </div>
                </div>
                
            </div>
        </>
    );
}
export default HelpDeskBanner_Component;