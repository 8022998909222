import React, { useState, useEffect } from "react";
import SelectInput_Component from "./SelectInput_Component";
import TextInput_Component from "./TextInput_Component";
import validate from './BookAppointmentValidation';
import toastDisplay from './../../ToastNotification';
import IndustryList from './IndustryList.json';
import { Fade } from "react-reveal";
import { websiteBackendURL } from "../../../config";

const BookAppoinmentLanding_Component = () => {

    const [data, setData] = useState({});
    const [error, setError] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const [refresh, setrefresh] = useState(0);
    const [country, setCountry] = useState([]);

    const astrix = <span className="required-field text-danger">*</span>
    console.log('dataaa => ', data);

    useEffect(() => {
        if (Object.keys(error).length === 0 && isSubmitting) {
            handleSubmit()
        } else if (isSubmitting) {
            setIsSubmitting(false)
            // toastDisplay("Form Validation Error", "warn")
        }
    }, [error]);

    useEffect(() => {
        fetch(websiteBackendURL + '/getCountry', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        }).then((result) => {
            return result.json();
        }).then((data) => {
            // console.log('dataaa => ', data);
            setCountry(data);
        })
    }, [])


    const handleChange = (event) => {
        event.persist();
        setData({ ...data, [event.target.name]: event.target.value });
        setError({ ...error, [event.target.name]: "" });
    }


    const preHandleSubmit = (event) => {
        if (event) event.preventDefault();
        setError(validate(data));
        setIsSubmitting(true)
    };

    function handleSubmit() {
        setShowLoader(true);

        fetch(websiteBackendURL + '/bookAppointment', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        }).then((result) => {
            console.log('kkkkkkkkk ', result);
            setShowLoader(false);
            setrefresh(refresh + 1);
            toastDisplay('Inserted Successfully', "success");
        }).then((data) => {
        });
    }


    return (
        <>
            {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
            <div id="sec-1" className="container-fluid py-5">
                <div className="container my-4">
                    <div className="row sec-cen">
                        <Fade left>
                            <div className="col-xl-6 col-lg-6 col-sm-10 col-md-10 home-sec">
                                <h3>See Why Leading Trade and Logistics Companies Trust TradeReboot</h3>
                                <p>In this session, our experts will understand your challenges and walk you through an industry-best approach that will demonstrate how you can orchestrate & improve your logistics & supply chain operations using our industry’s first global trade and logistics management platform.</p>
                                <p>We would equip you with:</p>
                                <div className="row">
                                    {Data.map((key, index) => {
                                        return (

                                            <div className="col-xl-6 col-lg-6 col-md-10 col-sm-10 my-3" key={index}>
                                                <div className="row">
                                                    <div className="col-xl-2 col-lg-2 col-md-10 col-sm-10">
                                                        <span className="iconify BookIcons" data-icon={key.Icon}></span>
                                                    </div>
                                                    <div className="col-xl-10 col-lg-10 col-sm-10 col-md-10 ">
                                                        <h6>{key.Heading}</h6>
                                                        <p>{key.Paragraph}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </Fade>

                        <div className="col-xl-6 col-lg-6 col-sm-10 col-md-10 BookForm">
                            <div className="card p-4 BookFormCard">
                                <TextInput_Component Type="text" placeholder="First Name" label="First Name" id="FirstName" name="firstname" value={data.firstname} onChange={handleChange} astrix={astrix} validError={error.firstname} />
                                <TextInput_Component Type="text" placeholder="Last Name" label="Last Name" id="LastName" name="lastname" value={data.lastname} onChange={handleChange} astrix={astrix} validError={error.lastname} />
                                <TextInput_Component Type="email" placeholder="Email Id" label="Email Id" id="Email" name="email" value={data.email} onChange={handleChange} astrix={astrix} validError={error.email} />
                                <TextInput_Component Type="tel" placeholder="Phone Number" label="Phone Number" id="PhoneNo" name="phone" value={data.phone} onChange={handleChange} astrix={astrix} validError={error.phone} />
                                <TextInput_Component Type="text" placeholder="Company Name" label="Company Name" id="CompanyName" name="company_name" value={data.company_name} onChange={handleChange} astrix={astrix} validError={error.company_name} />
                                <TextInput_Component Type="text" placeholder="Job Title" label="Job Title" id="JobTitle" name="job_title" value={data.job_title} onChange={handleChange} astrix='' />
                                <div className="col-10 mx-auto FormStyling">
                                    <div className={`form-floating my-3  ${error.industry === 'Field Missing' ? 'validation-input' : 'FormFloating'}`}>
                                        <select className="form-select" id={"industry"} aria-label="" name="industry" value={data.industry} onChange={handleChange}>
                                            <option selected>{"Select Industry"}</option>
                                            {IndustryList.map((key, value) => {
                                                return (< option value={key.key} > {key.name}</option>)
                                            })}
                                        </select>
                                        <label htmlFor={`#industry`}>Industry {astrix}</label>
                                        {error.industry === 'Field Missing' ? <span className="text-danger fontValid"> Field Missing</span> : ''}
                                    </div>
                                </div>
                                <div className="col-10 mx-auto FormStyling">
                                    <div className={`form-floating my-3  ${error.mentor === 'Field Missing' ? 'validation-input' : 'FormFloating'}`}>
                                        <select className="form-select" id={"mentor"} aria-label="" name="mentor" value={data.mentor} onChange={handleChange}>
                                            <option selected>{"Select Mentor"}</option>
                                            <option defaultValue="arpit">Arpit Kadakia</option>
                                            <option defaultValue="ayushi">Ayushi Shetty</option>
                                        </select>
                                        <label htmlFor={`#mentor`}>Select Mentor {astrix}</label>
                                        {error.mentor === 'Field Missing' ? <span className="text-danger fontValid"> Field Missing</span> : ''}
                                    </div>
                                </div>
                                <div className="col-10 mx-auto FormStyling">
                                    <div className={`form-floating my-3  ${error.country === 'Field Missing' ? 'validation-input' : 'FormFloating'}`}>
                                        <select className="form-select" id={"country"} aria-label="" name="country" value={data.country} onChange={handleChange}>
                                            <option selected>{"Select Country"}</option>
                                            {country.map((key, value) => {
                                                return (<option defaultValue={key.sortname}>{key.name}</option>)
                                            })}
                                        </select>
                                        <label htmlFor={`#country`}>Country {astrix}</label>
                                        {error.country === 'Field Missing' ? <span className="text-danger fontValid"> Field Missing</span> : ''}
                                    </div>
                                </div>
                                <div className="mx-5 my-4">
                                    <Fade>
                                        <div className="col-xl-4 col-lg-3 col-sm-10 col-md-10 px-3 ">
                                            <button className="btn Seemore" onClick={() => preHandleSubmit()}> Submit </button>
                                        </div>
                                    </Fade>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    );
}
export default BookAppoinmentLanding_Component;


const Data = [
    {
        Icon: "codicon:symbol-method",
        Heading: "ICC & UCP Compliant",
        Paragraph: "The whole platform is created by integrating ICC guidelines, all contact formed are ICC compliant, payment processes such as e-LC are eUCP compliant.",

    },
    {
        Icon: "bx:bx-code-block",
        Heading: "Network Management",
        Paragraph: "Add your networks: partner traders, investigation agencies, shipping agencies, bank; company employees & set there ACL.",

    },
    {
        Icon: "carbon:iot-platform",
        Heading: "Service History",
        Paragraph: "Data & documents from every service avail or provided are stored in the blockchain & cab be accessed afterwards. Data is also graphically represented on dashboard.",

    },
    {
        Icon: "icon-park-outline:endpoint-displacement",
        Heading: "Real Time Tracking",
        Paragraph: "The platform allows complete real time tracking of documents and goods not only just at nodes but through the whole process.",
    },
    {
        Icon: "gridicons:cog",
        Heading: "Automatic Process",
        Paragraph: "Through smart contracts of blockchain technology, we made most of the process on platform automatic, e.g. penalty calculation.",
    },
    {
        Icon: "carbon:iot-platform",
        Heading: "Doc-Check",
        Paragraph: "Derived from OCR, Doc-check, checks draft contracts for changes and highlight them and create a log, also check documents for duplicity.",

    },
    {
        Icon: "icon-park-outline:endpoint-displacement",
        Heading: "Audit Trail",
        Paragraph: "The complete process is recorded and stored in blockchain. Every detail, every transaction, every update is stored & can be accessed.",
    },
    {
        Icon: "gridicons:cog",
        Heading: "Go-Contracts",
        Paragraph: "Go contracts provide standard editable draft contracts based on requirement which are compliant with ICC & governmental guidelines and standard practices.",
    },
];