import React from "react";

import TradeCards_Component from "./TradeCards_Component";

const TradeTechSolution_Component = () => {
    return (
        <>
            <div className="container my-5 SolutionInsure">
                <h5 className="text-center fw-bold text-uppercase">TRADE TECH</h5>
                <h2 className="text-center fw-bold">Deal With <span className="TextColor">  Payment Part </span>  Of Trade</h2>
                <p className="text-center col-xl-7 col-lg-8 col-md-12 col-sm-12 my-2 text-secondary mx-auto">Are you a trader ,Importer or exporter ,looking for advanced digital solutions to the age-old trade process?</p>
                <div className="col-xl-11 col-lg-11 col-sm-12 col-md-12 my-5 mx-auto">
                    <div className="slider overflow-hidden position-relative" >
                        <div className="slide-track">
                            <div className="d-flex">
                                <TradeCards_Component Paragraph="Smart Contract" />
                                <TradeCards_Component Paragraph="LC Management" />
                                <TradeCards_Component Paragraph="E Payment" />
                                <TradeCards_Component Paragraph="Contractual Ambiguities and Errors" />
                                <TradeCards_Component Paragraph="Early Discovery" />
                                <TradeCards_Component Paragraph="Digitizing Workflow" />
                                <TradeCards_Component Paragraph="E KYC" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default TradeTechSolution_Component;