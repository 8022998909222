import React from "react";
import Contact from "../../images/Navbar Icons/call.svg";
import location from "../../images/Navbar Icons/location.svg";
import email from "../../images/Navbar Icons/direct-inbox.svg";

const HelpdeskContact_Component = () => {
    return (
        <>
            <div className="card contactUsBg text-start p-5">
                <h4 className="mb-3 fw-bold">Contact Us</h4>
                <div className="locationIframe">
                    <iframe className="img-fluid" title="Location" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d60303.55045188583!2d72.84367842707029!3d19.15270713313371!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7b65a7edbbdb9%3A0xf447c38599143078!2sGoregaon%2C%20Mumbai%2C%20Maharashtra!5e0!3m2!1sen!2sin!4v1635946649831!5m2!1sen!2sin" loading="lazy"></iframe>
                </div>
                <p className="fw-light mt-3">Trade Reboot Is A Trade Tech Company Providing Digitalized Solutions To Traders, Banks, Financiers, Insurer, Shippers & Inspection Agencies For A Seamless, Secured And Easy Trade Process.</p>
                <ul className="list-unstyled fw-light">
                    {/* <li className="my-3">
                        <div className="d-flex text-decoration-none text-light cursor">
                            <img className="me-2" src={Contact} alt="" />
                            <span className="">+1(512) 518-0065</span>
                        </div>
                    </li> */}
                    <li className="my-3">
                        <div className="d-flex text-decoration-none text-light cursor">
                            {/* <span className="iconify me-2" data-icon="el:globe"></span> */}
                            <img className="me-2" src={email} alt="" />
                            <span className="">info@tradereboot.com</span>
                        </div>
                    </li>
                    <li className="my-3">
                        <div className="d-flex text-decoration-none text-light cursor">
                            {/* <span className="iconify me-2" data-icon="carbon:location-filled"></span> */}
                            <img className="me-2" src={location} alt="" />
                            <span className="">Goregaon, Mumbai, Maharashtra 400063</span>
                        </div>
                    </li>
                </ul>
            </div>
        </>
    );
}
export default HelpdeskContact_Component;