import React from "react";

import ContactBanner_Component from "./ContactBanner_Component";
import ContactForm_Component from "./ContactForm_Component";


const ContactUsLanding_Component = () => {
    return (
        <>
            <ContactBanner_Component />
            <ContactForm_Component />
            
            {/* <div className="col-8 mx-auto Location my-5">
                <h3 className="text-center my-5 ">Our Location</h3>
                <iframe className="col-12 mx-auto" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d60303.55045188583!2d72.84367842707029!3d19.15270713313371!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7b65a7edbbdb9%3A0xf447c38599143078!2sGoregaon%2C%20Mumbai%2C%20Maharashtra!5e0!3m2!1sen!2sin!4v1635946649831!5m2!1sen!2sin" width="600" height="450" style={{ border: "0" }} loading="lazy"></iframe>
            </div> */}
        </>
    );
}
export default ContactUsLanding_Component;