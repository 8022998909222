import React from "react";


const TextInput_Component = ({ Type, placeholder, label, id, name, value, onChange, astrix, validError }) => {
    return (
        <>
            <div className="col-10 mx-auto FormStyling">
                <div className={`form-floating my-3  ${validError === 'Field Missing' ? 'validation-input' : 'FormFloating'}`}>
                    <input type={Type} className="form-control" id={id} placeholder={placeholder} name={name} value={value} onChange={onChange} />
                    <label htmlFor={`$#{id}`}>{label} {astrix}</label>
                </div>
                {validError === 'Field Missing' ? <span className="text-danger fontValid"> Field Missing</span> : ''}
            </div>
        </>
    );
}
export default TextInput_Component;