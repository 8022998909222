import React from "react";
import FintechImage from "../../../images/Fintech/Fin-tech-onboarding.png";
import { Fade } from "react-reveal";

const OnBoarding_Component = () => {
    return (
        <>
            <div className="container py-5">
                <div className="col-xl-11 col-lg-11 col-sm-10 col-md-11 mx-auto ">
                    <h3 className="fw-bold">Onboarding </h3>
                    <p className="my-3">Applicant also has to provide confirmation from the buyer which can be done by the buyer offline (signed consent application), through email or by joining the platform . Financier reviews the application & if he approve the fund request, sends the details & T&C to both seller & buyer Financier send details such as approved funding, funding percentage, tentative interest rate, additional fee & other T&C.
                    </p>
                </div>
                <div className="col-xl-11 col-lg-11 col-sm-10 col-md-11 mx-auto py-5 ">
                    <div className=" ">
                        <div className="row">
                            <Fade left>
                                <div className="Col-xl-6 col-lg-6 col-md-12 col-sm-12 ">
                                    <img className="my-3 img-fluid" src={FintechImage} alt="FinTech" width="350px" height="350px" />
                                </div>
                            </Fade>
                            <div className="Col-xl-6 col-lg-6 col-md-12 col-sm-12 documentslist">
                                <p className="my-3">As now we are only supporting Export Finance. If an Exporter want to avail e-Finance services, he must provide following information. When exporter apply for the funding, he must provide the following information of buyer on his behalf. All documents authenticity will be verified by third-parties which are partnered with our platform.</p>
                                <li>KYC </li>
                                <Fade left>
                                    <hr className="OnbordingHr" />
                                </Fade>
                                <li>Company profile  </li>
                                <Fade left>
                                    <hr className="OnbordingHr" />
                                </Fade>
                                <li>Last 6 month export details</li>
                                <Fade left>
                                    <hr className="OnbordingHr" />
                                </Fade>
                                <li>Last 3 year financial report</li>
                                <Fade left>
                                    <hr className="OnbordingHr" />
                                </Fade>
                                <li>Last 6 months bank statements</li>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default OnBoarding_Component;