import React from "react";

const Tracking_Component = () => {
    return (
        <>
            <div className="mt-2 col-xl-8 col-md-10 col-sm-12 col-lg-10"
                style={{ marginLeft: "30px" }}>
                <div style={{ marginLleft: "-7px" }}>
                    <a href="#"
                        className="translate-middle text-decoration-none text-info tracking-place">ODI</a>
                    <a href="#"
                        className="translate-middle text-decoration-none text-info tracking-place">ODI</a>
                    <a href="#"
                        className="translate-middle text-decoration-none text-info tracking-place">ODI</a>
                    <a href="#"
                        className="translate-middle text-decoration-none text-info tracking-place">ODI</a>
                    <a href="#"
                        className="translate-middle text-decoration-none text-info tracking-place">GDZ</a>
                </div>
                <div className="progress">
                    <div className="progress-bar" role="progressbar"
                        style={{ width: "80%" }} aria-valuenow="50" aria-valuemin="0"
                        aria-valuemax="100"></div>
                </div>
                <button type="button"
                    className="translate-middle btn btn-info rounded-pill border-info border-3 rounded-pill tracking-circle">
                </button>
                <button type="button"
                    className=" translate-middle btn btn-info rounded-pill border-info border-3 rounded-pill tracking-circle"></button>
                <button type="button"
                    className="translate-middle btn btn-info rounded-pill border-info border-3 rounded-pill tracking-circle"></button>
                <button type="button"
                    className="translate-middle btn btn-info rounded-pill border-info border-3 rounded-pill tracking-circle"></button>
                <button type="button"
                    className="translate-middle btn btn-light border-info border-3 rounded-pill tracking-circle"></button>
            </div>
        </>
    );
}
export default Tracking_Component;