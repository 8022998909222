import React from "react";


const WhoweareCarosuel_Component = ({ Heading, CarosuelImage, Pargraph }) => {
    return (
        <>
            <div className="col-xl-10 col-lg-10 col-md-10 col-sm-10 col-xs-10 mx-auto my-5 Whowearecarosuel">
                <div className="mx-5">
                    <div className="row">
                        <div className="col-xl-6 m-auto">
                            <img src={CarosuelImage} alt="Image One" className="img-fluid my-5" />
                        </div>
                        <div className="col-xl-6 m-auto">
                            <h4 className="my-3 text-info text-uppercase fw-bold">{Heading}</h4>
                            <p>{Pargraph}</p>
                        </div>

                    </div>
                </div>

            </div>
        </>
    );
}
export default WhoweareCarosuel_Component;