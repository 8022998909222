export default function validate(values) {

  let errors = {};

  if (!values.name) {
    errors.name = 'Field Missing'
  }
  if (!values.email) {
    errors.email = 'Field Missing';
  }
  if (!values.telephone) {
    errors.telephone = 'Field Missing';
  }
  if (!values.subject) {
    errors.subject = 'Field Missing';
  }
  if (!values.comments) {
    errors.comments = 'Field Missing';
  }
  if(!values.support){
    errors.support="Field Missing";
  }
  console.log(errors);
  return errors;
}