import React from "react";
import CompAdavatage_Component from "./CompAdavtage_Component";
import SolutionBannerSection_Component from "./SolutionBannerSection_Component";
import TradePractices_Components from "./TradePractices_Components";
import EaseofTechnology_Component from "./EaseofTechnology_Component";
import ImageOne from "../../images/Image-1.png";
import ImageTwo from "../../images/Image-2.png";
import ImageThree from "../../images/Image-3.png";
import ImageFour from "../../images/Image-4.png";


const InsureTechPara = "TradeReboot brings together leading insurance companies from across the globe.";

const FinTechpara = "TradeReboot FinTech enables the banks and NBFCs to access exporters and shipping companies to provide export financing LC Discounting .";

const InspectTechpara = "Our platform is built to allow traders to send inspection nominations to surveyors and for the latter to contribute their results and invoice their clients seamlessly. ";

const SolutionsLanding_Component = () => {
    return (
        <>
            <SolutionBannerSection_Component />
            <div style={{ backgroundColor: "#F5F5F5" }}>
                <TradePractices_Components CompImage={ImageOne} Heading="Secured trade practices" Paragraph={InsureTechPara} TechName="LOGI-TECH" RedirectTo="/solutions/logi-tech" />
                <EaseofTechnology_Component CompImage={ImageTwo} Heading="Ease Of Technology During Payments" Paragraph={FinTechpara} TechName="INSURE-TECH" RedirectTo="/solutions/insure-tech" />
                <TradePractices_Components CompImage={ImageThree} Heading="The Fast Changing Technology" Paragraph={InspectTechpara} TechName="FIN-TECH" RedirectTo="/solutions/fin-tech" />
                <EaseofTechnology_Component className="py-5" CompImage={ImageFour} Heading="Deal With Payment Part Of Trade" Paragraph={FinTechpara} TechName="INSPECT-TECH" RedirectTo="/solutions/inspect-tech" />
            </div>


        </>
    );
}
export default SolutionsLanding_Component;