import React, { useState, useEffect } from "react";
import ContactFormText_Component from "./ContactFormText_Component";
import Input_Component from "./Input_Component";
import validate from './ContactValidation';
import toastDisplay from '../ToastNotification';
import { Fade } from "react-reveal";
import { websiteBackendURL } from "../../config";


const ContactForm_Component = () => {

    const [data, setData] = useState({});
    const [error, setError] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const [refresh, setrefresh] = useState(0);

    const astrix = <span className="required-field text-danger">*</span>
    console.log('dataaa => ', data);

    useEffect(() => {
        if (Object.keys(error).length === 0 && isSubmitting) {
            handleSubmit()
        } else if (isSubmitting) {
            setIsSubmitting(false)
            // toastDisplay("Form Validation Error", "warn")
        }
    }, [error]);


    const handleChange = (event) => {
        event.persist();
        setData({ ...data, [event.target.name]: event.target.value });
        setError({ ...error, [event.target.name]: "" });
    }


    const preHandleSubmit = (event) => {
        if (event) event.preventDefault();
        setError(validate(data));
        setIsSubmitting(true)
    };

    function handleSubmit() {
        setShowLoader(true);

        fetch(websiteBackendURL + '/setContactDetail', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        }).then((result) => {
            console.log('kkkkkkkkk ', result);
            setShowLoader(false);
            setrefresh(refresh + 1);
            toastDisplay('Inserted Successfully', "success");
        }).then((data) => {
            setShowLoader(false);
            console.log('kkkkkkkkk ', data)
        });
    }

    return (
        <>
            {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
            <div className="container-fluid my-5">
                <div className="col-xl-10 col-lg-10 col-sm-10 col-md-10 mx-auto ContactCard">
                    <div className="row">
                        <Fade left>
                            <div className="col-xl-6 col-lg-6 col-md-10 col-sm-10 text-center m-auto">
                                <ContactFormText_Component />
                            </div>
                        </Fade>

                        <div className="col-xl-6 col-lg-6 col-md-10 col-sm-10 m-auto ">
                            <h2 className="fw-bold text-capitalize mb-3"><span className="text-info">Get in</span> Touch</h2>
                            <h5 className="mt-2 mb-5 fw-bold">Feel Free To Drop Us A Line Below</h5>
                            <form >
                                <Input_Component Type="text" placeholder=" Name" label=" Name" id="FirstName" name="name" value={data.name} onChange={handleChange} astrix={astrix} validError={error.name} />
                                <Input_Component Type="email" placeholder="Email" label="Email" id="email" name="email" value={data.email} onChange={handleChange} astrix={astrix} validError={error.email} />
                                <Input_Component Type="tel" placeholder="Phone" label="Phone" id="phone" name="telephone" value={data.telephone} onChange={handleChange} astrix={astrix} validError={error.telephone} />
                                <Input_Component Type="text" placeholder="Subject" label="Subject" id="subject" name="subject" value={data.subject} onChange={handleChange} astrix={astrix} validError={error.subject} />
                                <div className="form-floating FormFloating">
                                    <textarea className="form-control" placeholder="Leave a comment here" name="comments" value={data.comments} onChange={handleChange} id="floatingTextarea"></textarea>
                                    <label htmlFor="floatingTextarea">Comments</label>
                                </div>
                                <Fade>
                                    <button className="btn Seemore my-3" onClick={(e) => preHandleSubmit(e)}> Submit </button>
                                </Fade>
                            </form>
                        </div>

                    </div>
                </div>
            </div>
        </>
    );
}
export default ContactForm_Component;