import React from "react";
import { Fade } from "react-reveal";
import LogiTechImage from "../../../images/Insurance_Images/Shipping_Banner.jpg";

const InsuranceBanner_Component = () => {
  return (
    <>
      <div className="container-fluid p-0" id="Fintech-bg">
        <div className="col-xl-10 col-md-10 col-lg-10 col-sm-10 mx-auto py-5">
          <div className="row">
            <Fade left>
              <div className="col-xl-6 col-lg-6 col-md-10 col-sm-10 m-auto">
                <h4 className="text-light fw-bold text-uppercase">
                  Insurance-marketplace
                </h4>
                <h3 className="text-light fw-bold">
                  One Stop <span className="TextColor">Marine Insurance </span>
                  Marketplace
                </h3>
                <ul className="ListStyleImage mt-4">
                  <li className="text-light fs-5">
                    Marine insurance covers the loss or damage of ships, cargo,
                    terminals & any transport by which the property is transferred, acquired,
                    or held between the points of origin and the final destination.
                  </li>
                  <li className="text-light fs-5 mt-3">
                    Cover your valuable goods, freight and other business interests against
                    loss or damage to goods from the time it leaves the seller's
                    warehouse until they reach the buyers Warehouse.
                    For exclusive offers, Please register on our platform.
                  </li>
                </ul>
                <a href="https://tech.tradereboot.com/" target={"_blank"}>
                  <button className="btn Seemore my-2 my-sm-0 ml-3" type="submit">Login / Register</button></a>
              </div>
            </Fade>
            <div className="col-xl-6 col-lg-6 col-md-10 col-sm-10 m-auto text-center ">
              <Fade right>
                <img
                  src={LogiTechImage}
                  alt="Competetive"
                  className="img-fluid"
                />
              </Fade>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default InsuranceBanner_Component;