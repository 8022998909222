export default function validate(values) {

  let errors = {};

  if (!values.dealId) {
    errors.dealId = 'Field Missing';
  }
  if (!values.taxEffectiveDate) {
    errors.taxEffectiveDate = 'Field Missing';
  }
  if (!values.policyStartDate) {
    errors.policyStartDate = 'Field Missing';
  }
  if (!values.policyEndDate) {
    errors.policyEndDate = 'Field Missing';
  }
  if (!values.sumInsured) {
    errors.sumInsured = 'Field Missing';
  }
  if (!values.limitPerSending) {
    errors.limitPerSending = 'Field Missing';
  }
  if (!values.limitsPerLocation) {
    errors.limitsPerLocation = 'Field Missing';
  }
  if (!values.typeOfCargo) {
    errors.typeOfCargo = 'Field Missing';
  }
  if (!values.excessPercentage) {
    errors.excessPercentage = 'Field Missing';
  }
  if (!values.coverageType) {
    errors.coverageType = 'Field Missing';
  }
  if (!values.commodityName) {
    errors.commodityName = 'Field Missing';
  }
  if (!values.partyStateName) {
    errors.partyStateName = 'Field Missing';
  }
  if (!values.modeOfTransit) {
    errors.modeOfTransit = 'Field Missing';
  }

  console.log(errors);
  return errors;
}