import React from "react";
import { NavLink } from "react-router-dom";

const BlogSection_Component = ({ fileId, BlogHeading, DateOfArticle, Paragraph, Bloglink, fileHash, fileName }) => {

  let mime = fileName.split(".").pop()
  let sourceType = getSourceType(mime)

  function getSourceType(mime) {
    return ((mime === "png" || mime === "PNG") ? "data:image/png;base64," :
      (mime === "jpg" || mime === "JPG") ? "data:image/jpeg;base64," : "")
  }

  return (
    <>
      <div className="card my-4 BlogCards p-3">
        <div className="row">
          <div className="col-xl-3 col-lg-5 col-md-8 col-sm-10 logCardsImage m-auto text-center">
            <img src={sourceType + encodeURI(fileHash ? fileHash : "")} alt={fileName} />
          </div>
          <div className="col-xl-9 col-lg-6 col-md-10 col-sm-10  mx-auto BlogcardstextData">
            <h5 className="mt-4 fw-bold">{BlogHeading}</h5>
            <div className="mt-2 mb-0 published-description">
              <p className="my-2">
                {Paragraph.slice(0, 358)}
                <NavLink to={Bloglink} target="_blank" className="text-decoration-none ms-1" rel="noreferrer">
                  <a href={`https://webdev.fob.sofodev.co/NewEvents/blog-events?id=${fileId}`} target="_blank" className="text-decoration-none" rel="noreferrer" >Read More...</a>
                </NavLink>
              </p>
            </div>
            <div>
              <p className="text-secondary Publisheddate mt-2">
                {DateOfArticle}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default BlogSection_Component;
