import React from "react";


const Input_Component = ({ Type, placeholder, label, id, name, value, onChange, astrix, validError }) => {
    return (
        <>
            <div className="mx-auto FormStyling my-3">
                <div className={`form-floating  ${validError === 'Field Missing' ? 'validation-input' : 'FormFloating'}`}>
                    <input type={Type} className="form-control" id={id} placeholder={placeholder} name={name} value={value} onChange={onChange} />
                    <label htmlFor={`$#{id}`}>{label}{astrix}</label>
                </div>
                {validError === 'Field Missing' ? <span className="text-danger fontValid"> Field Missing</span> : ''}
            </div>
        </>
    );
}
export default Input_Component;