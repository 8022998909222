import React from "react";

import SolutionImage from "../../images/solution-Banner.png";
import Document_Component from "./Document_Component";

const BannerData="TradeReboot gives you the ability to innovate with access to trusted information from your entire logistics ecosystem.";

const SolutionBannerSection_Component = () => {
    return (
        <>
            <div className="container-fluid p-0" id="SolutionBanner-bg">
                <div className="SolutionNewBanner">
                    <div className="text-center text-light pt-5 pb-3">
                        <h1 className="text-capitalize">Our Solution</h1>
                        <h6  className="text-info fs-5">We Are Problem Solver</h6>
                    </div>
                    <div className="col-xl-6 col-lg-7 col-sm-11 col-md-10 my-5 mx-auto card ImageBanner bg-transparent">
                        <Document_Component  Heading="Enhance supply chain efficiency" Paragraph={BannerData} ImageName={SolutionImage} />
                    </div>
                </div>
            </div>

        </>
    );
}
export default SolutionBannerSection_Component;