import React, { useState, useEffect } from "react";
import TextInput_Component from "../../Home/BookAppointment/TextInput_Component";
import validate from './InsuranceValidation';
import BasicDetails_Component from './BasicDetails_Component';
import { websiteBackendURL } from "../../../config";

const InsuranceSearch_Component = () => {

    const [data, setData] = useState({});
    const [error, setError] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const [sealineData, setSealineData] = useState({ modal: false, data: {} })

    const astrix = <span className="required-field text-danger">*</span>

    useEffect(() => {
        if (Object.keys(error).length === 0 && isSubmitting) {
            handleSubmit()
        } else if (isSubmitting) {
            setIsSubmitting(false)
            // toastDisplay("Form Validation Error", "warn")
        }
    }, [error]);


    const handleChange = (event) => {
        event.persist();
        setData({ ...data, [event.target.name]: event.target.value });
        setError({ ...error, [event.target.name]: "" });
    }

    const preHandleSubmit = (event) => {
        if (event) event.preventDefault();
        setError(validate(data));
        setIsSubmitting(true)
    };

    function handleSubmit() {
        setShowLoader(true);

        fetch(websiteBackendURL + '/getInsuranceQuote', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        }).then((result) => {

            console.log('resultttt of getInsuranceQuote ===> ', result);
            return result.json();
        }).then((data) => {
            setShowLoader(false);
            console.log('kkkkkkkkk ', data)
            setSealineData({ modal: true, data: data });
        });
    }
    return (
        <>

            <div className="container py-5">
                <div className=" InsuranceCard">
                    <h4 className="text-center my-3 fw-bold">Search Insurance Details</h4>
                    <div className="" id="">
                        <div className="col-11 mx-auto ">
                            <div className="row">
                                <div className="col-xl-3 col-lg-3 col-md-6 col-sm-10 mx-auto">
                                    <TextInput_Component Type="text" placeholder="Deal Id" label="Deal Id" id="DealId" name="dealId" value={data.dealId} onChange={handleChange} astrix={astrix} validError={error.dealId} />
                                </div>
                                <div className="col-xl-3 col-lg-3 col-md-6 col-sm-10 mx-auto">
                                    <TextInput_Component Type="text" placeholder="Party State Name" label="Party State Name" id="partyStateName" name="partyStateName" value={data.partyStateName} onChange={handleChange} astrix={astrix} validError={error.partyStateName} />
                                </div>
                                <div className="col-xl-3 col-lg-3 col-md-6 col-sm-10 mx-auto">
                                    <TextInput_Component Type="text" placeholder="Mode Of Transit" label="Mode Of Transit" id="modeOfTransit" name="modeOfTransit" value={data.modeOfTransit} onChange={handleChange} astrix={astrix} validError={error.modeOfTransit} />
                                </div>
                                <div className="col-xl-3 col-lg-3 col-md-6 col-sm-10 mx-auto">
                                    <TextInput_Component Type="date" placeholder="Tax Effective Date" label="Tax Effective Date" id="TaxEffectiveDate" name="taxEffectiveDate" value={data.taxEffectiveDate} onChange={handleChange} astrix={astrix} validError={error.taxEffectiveDate} />
                                </div>
                                <div className="col-xl-3 col-lg-3 col-md-6 col-sm-10 ">
                                    <TextInput_Component Type="date" placeholder="Policy Start Date" label="Policy Start Date" id="PolicyStartDate" name="policyStartDate" value={data.policyStartDate} onChange={handleChange} astrix={astrix} validError={error.policyStartDate} />
                                </div>
                                <div className="col-xl-3 col-lg-3 col-md-6 col-sm-10 ">
                                    <TextInput_Component Type="date" placeholder="Policy End Date" label="Policy End Date" id="PolicyEndDate" name="policyEndDate" value={data.policyEndDate} onChange={handleChange} astrix={astrix} validError={error.policyEndDate} />
                                </div>
                            </div>
                        </div>
                        <div className="col-11 mx-auto">
                            <h5 className="my-3 text-center fw-bold">Product Details</h5>
                            <div className="">
                                <div className="row">
                                    <div className="col-xl-3 col-lg-3 col-md-6 col-sm-10 mx-auto">
                                        <TextInput_Component Type="number" placeholder="Sum Insured" ValueNum="5500000" label="Sum Insured" id="SumInsured" name="sumInsured" value={data.sumInsured} onChange={handleChange} astrix={astrix} validError={error.sumInsured} />
                                    </div>
                                    <div className="col-xl-3 col-lg-3 col-md-6 col-sm-10 mx-auto">
                                        <TextInput_Component Type="number" placeholder="Limit Per Sending" ValueNum="5500000" label="Limit Per Sending" id="LimitPerSending" name="limitPerSending" value={data.limitPerSending} onChange={handleChange} astrix={astrix} validError={error.limitPerSending} />
                                    </div>
                                    <div className="col-xl-3 col-lg-3 col-md-6 col-sm-10 mx-auto">
                                        <TextInput_Component Type="number" placeholder="Limits Per Location" ValueNum="5500000" label="Limits Per Location" id="LimitsPerLocation" name="limitsPerLocation" value={data.limitsPerLocation} onChange={handleChange} astrix={astrix} validError={error.limitsPerLocation} />
                                    </div>
                                    <div className="col-xl-3 col-lg-3 col-md-6 col-sm-10 mx-auto">
                                        <TextInput_Component Type="text" placeholder="Type Of Cargo" ValueNum="FMCG" label="Type Of Cargo" id="TypeOfCargo" name="typeOfCargo" value={data.typeOfCargo} onChange={handleChange} astrix={astrix} validError={error.typeOfCargo} />
                                    </div>
                                    <div className="col-xl-3 col-lg-3 col-md-6 col-sm-10">
                                        <TextInput_Component Type="number" placeholder="Excess Percentage" ValueNum="0.0" label="Excess Percentage" id="ExcessPercentage" name="excessPercentage" value={data.excessPercentage} onChange={handleChange} astrix={astrix} validError={error.excessPercentage} />
                                    </div>
                                    <div className="col-xl-3 col-lg-3 col-md-6 col-sm-10 ">
                                        <TextInput_Component Type="text" placeholder="Coverage Type" ValueNum="ITC(A)" label="Coverage Type" id="CoverageType" name="coverageType" value={data.coverageType} onChange={handleChange} astrix={astrix} validError={error.coverageType} />
                                    </div>
                                    <div className="col-xl-3 col-lg-3 col-md-6 col-sm-10">
                                        <TextInput_Component Type="text" placeholder="Commodity Name" ValueNum="FMCG commodities" label="Commodity Name" id="CommodityName" name="commodityName" value={data.commodityName} onChange={handleChange} astrix={astrix} validError={error.commodityName} />
                                    </div>
                                    <div className="col-xl-12 mt-3 text-end">
                                        <button type="button" className="btn Seemore" onClick={() => preHandleSubmit()}>   {showLoader && (<span class="spinner-border spinner-border-sm mx-2" role="status" aria-hidden="true"></span>)} {!showLoader ? "Search" : " Loading"} </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {sealineData.modal &&
                    <BasicDetails_Component insuranceData={sealineData.data} />
                }
            </div>
        </>
    );
}
export default InsuranceSearch_Component;