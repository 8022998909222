import React, { useState, useEffect } from "react";
import Input_Component from "../Contact Us/Input_Component";
import { Fade } from "react-reveal";
import toastDisplay from '../ToastNotification';
import validate from './HelpDeskValidation.js';
import { websiteBackendURL } from "../../config";

const HelpDeskForm_Component = ({ helpDesk }) => {

    const [data, setData] = useState({});
    const [error, setError] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const [refresh, setrefresh] = useState(0);

    const astrix = <span className="required-field text-danger">*</span>

    useEffect(() => {
        if (Object.keys(error).length === 0 && isSubmitting) {
            handleSubmit()
        } else if (isSubmitting) {
            setIsSubmitting(false)
            // toastDisplay("Form Validation Error", "warn")
        }
    }, [error]);


    const handleChange = (event) => {
        event.persist();
        setData({ ...data, [event.target.name]: event.target.value });
        setError({ ...error, [event.target.name]: "" });
    }


    const preHandleSubmit = (event) => {
        if (event) event.preventDefault();
        setError(validate(data));
        setIsSubmitting(true)
    };

    function handleSubmit() {
        setShowLoader(true);
        data.helpDesk = helpDesk;

        fetch(websiteBackendURL + '/setHelpDeskDetail', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        }).then((result) => {
            console.log('kkkkkkkkk ', result);
            setShowLoader(false);
            setrefresh(refresh + 1);
            toastDisplay('Inserted Successfully', "success");
        }).then((data) => {
            setShowLoader(false);
            console.log('kkkkkkkkk ', data)
        });
    }


    return (
        <>
            <div className="">
                <h5>Feel Free To Drop A Line Below </h5>
                <form>
                    <div className="">
                        <Input_Component Type="text" placeholder=" First Name" label=" First Name" id="FirstName" name="firstName" value={data.firstName} onChange={handleChange} astrix={astrix} validError={error.firstName} />
                    </div>
                    <div className="">
                        <Input_Component Type="text" placeholder="Last Name" label="Last Name" id="LastName" name="lastName" value={data.lastName} onChange={handleChange} astrix={astrix} validError={error.lastName} />
                    </div>
                    <div className="">
                        <Input_Component Type="email" placeholder="Enter Email" label="Email" id="Email" name="email" value={data.email} onChange={handleChange} astrix={astrix} validError={error.email} />
                    </div>
                    <div className="">
                        <Input_Component Type="tel" placeholder="Phone Number" label="Phone Number" id="PhoneNumber" name="telephone" value={data.telephone} onChange={handleChange} astrix={astrix} validError={error.telephone} />
                    </div>
                    <div>
                        <Input_Component Type="text" placeholder="Subject" label="Subject" id="Subject" name="subject" value={data.subject} onChange={handleChange} astrix={astrix} validError={error.subject} />
                    </div>
                    <div className="form-floating FormFloating">
                        <textarea className="form-control" placeholder="Leave a comment here" name="comments" value={data.comments} onChange={handleChange} id="floatingTextarea"></textarea>
                        <label htmlFor="floatingTextarea">Comments</label>
                    </div>
                    <Fade>
                        <button className="btn Seemore my-3" onClick={(e) => preHandleSubmit(e)}> Submit </button>
                    </Fade>
                </form>
            </div>
        </>
    );
}
export default HelpDeskForm_Component;