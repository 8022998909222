import React from "react";
import { Link } from "react-router-dom";
import Image from "../../../images/Solution Page images/InspectImage.png";

const InsureTechSolution_Component = () => {
    return (
        <>
            <div className="container py-5 SolutionInsure">
                <div className="row">
                    <div className="col-xl-6 col-lg-6 col-sm-10 col-md-5 text-center ">
                        <img src={Image} alt="Image" height="500px" className="img-fluid" />
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-7 col-sm-10 m-auto">
                        <h5 className=" fw-bold text-uppercase">INSURE tech</h5>
                        <h2 className="fw-bold col-xl-8 col-lg-8 col-md-10 col-sm-12 mt-3 "> <span className="TextColor"> Secured  </span> Trade Practices</h2>
                        <p className=" my-2 text-secondary mx-auto mt-3">Tradereboot is a global leader in insurance broking and innovative risk management solutions.</p>
                        <ul className="ListStyleImage mt-4 mx-4">
                            <li className=" fs-6">Longstanding carrier relationships</li>
                            <li className=" fs-6 mt-4">Recognized Credit Specialty Practice in the industry</li>
                            <li className=" fs-6 mt-4">Global footprint to enable ease of business</li>
                            <li className=" fs-6 mt-4">Benefits of Trade Credit Insurance</li>
                        </ul>
                        <Link to="/solutions/insure-tech"><button className="Seemore mt-3">See More</button></Link>
                    </div>
                </div>
            </div>
        </>
    );
}
export default InsureTechSolution_Component;