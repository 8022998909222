import React from "react";



const FintechList_Component=({Para, Image})=>{
    return(
        <>
            <div className="col-xl-9 mx-auto mb-4" >
                <div className="row">
                    <div className="col-10 FintechList py-4 px-4">
                        <p className="mb-0">{Para}</p>
                    </div>
                    <div className="col-2 FintechListIcon text-center py-4">
                        <img src={Image} alt="Image" className="img-fluid " />
                    </div>
                </div>
            </div>
        </>
    );
}
export default FintechList_Component;