import React from "react";
import Image from "../../images/Shipping Images/Register-img.png";

const Registration = () => {
  return (
    <>
      <div className="container Register mb-5">
        <div className="row">
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 p-0">
            <img src={Image} alt="" className="img-fluid" />
          </div>
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 text-light m-auto">
            <h3 className="col-8">
              Register to get most competitive rates & exclusive offers
            </h3>
            <a href="https://tech.tradereboot.com/registration" target="_blank"><button className="mt-3 head-button2" type="register">
              Register
            </button></a>
          </div>
        </div>
      </div>
    </>
  );
};
export default Registration;
