import React from "react";

const TradeCards_Component=({ Paragraph })=>{
    return(
        <>
            <div className="col-xl-2 col-lg-2 col-md-3 col-sm-4 mx-3 text-center cursor">
                <div className="card SolutionLogisection slide align-items-center">
                    <div className="card-body ">
                        <p className="fw-bold ">{Paragraph}</p>
                    </div>
                </div>
            </div>
        </>
    );
}
export default TradeCards_Component;