import React from "react";
import { Fade } from "react-reveal";
import LogiTechImage from "../../images/Shipping Images/Shipping_Banner.png";

const ShippingBanner_Component = () => {
  return (
    <>
      <div className="container-fluid p-0" id="Fintech-bg">
        <div className="col-xl-10 col-md-10 col-lg-10 col-sm-10 mx-auto py-5">
          <div className="row">
            <Fade left>
              <div className="col-xl-6 col-lg-6 col-md-10 col-sm-10 m-auto">
                <h4 className="text-light fw-bold text-uppercase">
                  Shipping-marketplace
                </h4>
                <h3 className="text-light fw-bold">
                  One Stop <span className="TextColor">Shipping </span>
                  Marketplace
                </h3>
                <ul className="ListStyleImage mt-4">
                  <li className="text-light fs-5">
                    Get the instant freight rates from more than 500,000 most
                    reputed companies and get the latest international shipping
                    rates from any carrier.
                  </li>
                  <li className="text-light fs-5 mt-3">
                    Our marketplace bring transparency in international trade.
                  </li>
                  <li className="text-light fs-5 mt-3">
                    You get a digital platform for your supply chain department
                    with effectiveness, control and transparency of your
                    business.
                  </li>
                </ul>
              </div>
            </Fade>
            <div className="col-xl-6 col-lg-6 col-md-10 col-sm-10 m-auto text-center ">
              <Fade right>
                <img
                  src={LogiTechImage}
                  alt="Competetive"
                  className="img-fluid"
                />
              </Fade>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ShippingBanner_Component;
