import React from "react";
import Title from "../Home/Title";

import { Fade } from "react-reveal";

const OurStory_Component = () => {
  return (
    <>
      <div className="OurStory">
        <div className="OurStory_Top">
          <Title
            Title={"Our Story"}
            Description={" "}
          />
        </div>

        <div className="OurStory_Box">
          <div className="OurStory_TimeLine">
            <Timeline
              Year={"21 October 2020"}
              Description={
                "TradeReboot has a global launch while having a conference named 'TradeReboot Conference'."
              }
            />
            <Timeline Year={"Sep 2020"} Description={"At this time we executed our platform in pilot mode."} />
            <Timeline Year={"August 2020"} Description={"Here, we completed our second platform prototype of version V 1.0"} />
            <Timeline Year={"July 2020"} Description={"In this period, we operated our platform in India."} />
            <Timeline Year={"March 2020"} Description={"At the time of March 2020 we completed our platform prototype of version V 0.5"} />
            <Timeline Year={"Dec 2019"} Description={"In this duration, our company incorporated with UAE."} />
            <Timeline
              Year={"April 2018"}
              Description={"In the starting month of 2018, we conceived the idea of TradeReboot."}
            />
          </div>
          <div className="OurStory_Images">
            <Fade right>
              <div>
                <img
                  src="https://cdn.pixabay.com/photo/2016/03/09/09/22/meeting-1245776__340.jpg"
                  alt="globe"
                />
              </div>
            </Fade>
            <Fade right>
              <div>
                <img
                  src="https://cdn.pixabay.com/photo/2015/01/09/11/09/meeting-594091__340.jpg"
                  alt="globe"
                />
              </div>
            </Fade>
            <Fade right>
              <div>
                <img
                  src="https://cdn.pixabay.com/photo/2017/05/04/16/37/meeting-2284501__340.jpg"
                  alt="globe"
                />
              </div>
            </Fade>
            <Fade right>
              <div>
                <img
                  src="https://cdn.pixabay.com/photo/2015/01/08/18/11/laptops-593296__340.jpg"
                  alt="globe"
                />
              </div>
            </Fade>
          </div>
        </div>
      </div>

    </>
  );
}
export default OurStory_Component;

const Timeline = ({ Year, Description }) => {
  return (
    
      <div>
        <h2>{Year}</h2>
        <span></span>
        <p>{Description}</p>
      </div>

  );
};