/* eslint-disable react/jsx-pascal-case */
import React, { useState, useEffect } from "react";
import BlogSection_Component from "./BlogSection_Component";
import PostOne from "../../images/Newsevents/Image-1.png";
import PostTwo from "../../images/Newsevents/Image-2.png";
import Pagination_Component from "./Pagination_Component";
import PopularPost_Component from "./PopularPost_Component";
import NewsEventsBanner_Component from "./NewsEventsBanner_Component";
import { Fade } from "react-reveal";
import axios from 'axios';
import { websiteBackendURL } from "../../config";


const NewEventsMain_Component = () => {


  const [blogsList, setBlogsList] = useState({ modal: false, data: [] });
  const [paginationNumber, setPaginationNumber] = useState([]);


  useEffect(() => {
    axios.post(websiteBackendURL + '/getNewsBlogsList', {}).then(response => {
      setBlogsList({ modal: true, data: response.data });

      let recentBlogs = [];
      for (let b = 0; b < response.data.length; b++) {
        if (response.data[b].postType == 'recent') {
          recentBlogs.push(response.data[b]);
        }
      }

      const subArrays = [];
      for (let i = 0; i < recentBlogs.length; i += 2) {
        const chunk = recentBlogs.slice(i, i + 2);
        subArrays.push(chunk);
      }
      setPaginationNumber(subArrays);
      console.log('subArrays => ', subArrays);
    });


  }, []);


  return (
    <>
      <NewsEventsBanner_Component />
      <div className="container my-5">
        <div className="row">
          <div className="col-xl-9 col-sm-12 col-md-8 col-lg-12 mx-auto">
            <div className="">
              <h4 className="fw-bolder">Recent Posts</h4>
              {blogsList.modal && blogsList.data.length > 0 && blogsList.data.map((key, value) => {
                return (
                  <Fade left>
                    {key.postType == 'recent' &&
                      <BlogSection_Component fileId={key.id} BlogHeading={key.heading} DateOfArticle={key.created_at} Paragraph={key.description} Bloglink={key.url} fileHash={key.fileHash} fileName={key.file_name} />
                    }
                  </Fade>
                )
              })}
            </div>
            {/* <Pagination_Component /> */}
          </div>
          <div className="col-xl-3 col-lg-12 col-md-12 col-sm-12  mx-auto">
            <h4 className="fw-bolder">Popular Posts</h4>
            {blogsList.modal && blogsList.data.length > 0 && blogsList.data.map((key, value) => {
              return (
                <Fade right>
                  {key.postType == 'popular' &&
                    <PopularPost_Component fileId={key.id} BlogHeading={key.heading} DateOfArticle={key.created_at} Paragraph={key.description} Bloglink={key.url} fileHash={key.fileHash} fileName={key.file_name} />
                  }
                </Fade>
              )
            })}
          </div>
        </div>
      </div>
    </>
  );
};
export default NewEventsMain_Component;
