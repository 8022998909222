import React from "react";
import TextInput_Component from "../../Home/BookAppointment/TextInput_Component";
import GstServiceDetails_Component from "./GstServiceDetails_Component";
import GstTradedetails_Component from "./GstTradedetails_Component";

const TaxDetailsAccordion_Component = ({ taxDetails }) => {
    return (
        <>
            <div class="accordion accordion-flush pb-5" id="accordionExampleTwo">
                <div class="accordion-item AccordionInsurance">
                    <h2 class="accordion-header" id="headingTwo">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
                            <b className="fs-5">Tax Details</b>
                        </button>
                    </h2>
                    <div id="collapseTwo" class="accordion-collapse collapse " aria-labelledby="headingTwo" data-bs-parent="#accordionExampleTwo">
                        <div class="accordion-body">
                            <div className="col-xl-3 col-lg-3 col-md-6 col-sm-10">
                                <TextInput_Component Type="text" placeholder="Tax Type" label="Tax Type" id="taxType" name="taxType" value={taxDetails.taxType} onChange="" astrix="" disabledText='true' />
                            </div>
                            <GstServiceDetails_Component taxDetails={taxDetails} />
                            <GstTradedetails_Component taxDetails={taxDetails} />
                        </div>
                    </div>
                </div>

            </div>
        </>
    );
}
export default TaxDetailsAccordion_Component;