import React from "react";
import FinTechSolution_Component from "./FinTechSolution_Component";
import InspectTechSolution_Component from "./InspectTechSolution_Component";
import InsureTechSolution_Component from "./InsureTechSolution_Component";
import LogiTechSolution_Component from "./LogiTechSolution_Component";
import SolutionBanner_Component from "./SolutionBanner_Component";
import TradeTechSolution_Component from "./TradeTechSolution_Component";

const SolutionLanding_Component=()=>{
    return(
        <>
            <SolutionBanner_Component />
            <LogiTechSolution_Component />
            <InsureTechSolution_Component />
            <InspectTechSolution_Component />
            <FinTechSolution_Component />
            <TradeTechSolution_Component />
        </>
    );
}
export default SolutionLanding_Component;