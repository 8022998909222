/* eslint-disable react/jsx-pascal-case */
import React, { useState, useEffect } from "react";
import axios from 'axios';
import TextInput_Component from "./BookAppointment/TextInput_Component";
import validate from './BlogValidation';
import toastDisplay from '../ToastNotification';
import { websiteBackendURL } from "../../config";

const BlogTemplates_Component = () => {

    const [data, setData] = useState({});
    const [error, setError] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const [refresh, setrefresh] = useState(0);

    const astrix = <span className="required-field text-danger">*</span>
    console.log('dataaa => ', data);

    useEffect(() => {
        if (Object.keys(error).length === 0 && isSubmitting) {
            handleSubmit()
        } else if (isSubmitting) {
            setIsSubmitting(false)
            // toastDisplay("Form Validation Error", "warn")
        }
    }, [error]);


    const handleChange = (event) => {
        event.persist();
        setData({ ...data, [event.target.name]: event.target.value });
        setError({ ...error, [event.target.name]: "" });
    }

    const handleFile = (event) => {
        event.persist();
        setData(data => ({ ...data, [event.target.name]: event.target.files[0] }));
        setError(error => ({ ...error, [event.target.name]: "" }))
    }
    const preHandleSubmit = (event) => {
        if (event) event.preventDefault();
        setError(validate(data));
        setIsSubmitting(true)
    };

    function handleSubmit() {
        setShowLoader(true);

        const formData = new FormData();
        Object.keys(data).forEach((key) => {
            formData.append(key, data[key]);
        });

        axios.post(websiteBackendURL + '/setNewsBlogsTemplate', formData).then(response => {
            console.log('response => ', response);
            setShowLoader(false);
        })
    }


    return (
        <>
            <div id="sec-1" className="container-fluid pb-5 p-0 NewsBanner">
                <div className="text-center text-light pt-5">
                    <h1 className="text-capitalize">News And Blogs</h1>
                    <h6>Peoples Loves Us A Lot, Please Check Out Our Team Members</h6>
                </div>
            </div>
            <div className="container my-5 InsuranceCard">
                <form>
                    <div className="">
                        <h4 className="text-center my-4">Add News/Blogs</h4>

                        <div className="col-8 mx-auto FormStyling">
                            <div className={`form-floating my-3  ${error.postType === 'Field Missing' ? 'validation-input' : 'FormFloating'}`}>
                                <select className="form-select" id={"postType"} aria-label="" name="postType" value={data.postType} onChange={handleChange}>
                                    <option selected>Select Post Type</option>
                                    <option value={"recent"}>{"Recent Post"}</option>
                                    <option value={"popular"}>{"Popular Post"}</option>
                                </select>
                                {error.postType === 'Field Missing' ? <span className="text-danger fontValid"> Field Missing</span> : ''}
                            </div>
                        </div>
                        <div className="col-10 mx-auto">
                            <TextInput_Component Type="text" placeholder="Enter Heading" label="Heading" id="Heading" name="heading" value={data.heading} onChange={handleChange} astrix={astrix} validError={error.heading} />
                        </div>
                        <div className="col-10 mx-auto FormStyling mb-3">
                            <div className={`form-floating col-10 mx-auto ${error.description === 'Field Missing' ? 'validation-input' : 'FormFloating'}`}>
                                <textarea className="form-control" placeholder="Leave a comment here" id="floatingTextarea2" name="description" value={data.description} onChange={handleChange} style={{ height: "100px" }}></textarea>
                                <label htmlFor="floatingTextarea2">Description {astrix} </label>
                                {error.description === 'Field Missing' ? <span className="text-danger fontValid"> Field Missing</span> : ''}
                            </div>
                        </div>
                        <div className=" col-10 mx-auto FormStyling">
                            <div className="form-floating col-10 mx-auto FormFloating">
                                <input type="file" accept=".png,.jpg,.jpeg" className="form-control p-3" placeholder="Banner Image" id="bannerimages" name="doc" onChange={handleFile} multiple />
                                {error.doc === 'Field Missing' ? <span className="text-danger fontValid"> Field Missing</span> : ''}
                            </div>
                        </div>
                        <div className=" FormStyling">
                            <div className="form-floating col-10 mx-auto FormFloating">
                                <TextInput_Component Type="url" placeholder="Enter URL" label="Enter URL" id="bannerimages" name="url" value={data.url} onChange={handleChange} astrix={astrix} validError={error.url} multiple />
                            </div>
                        </div>
                        <div className="col-10 text-end my-4">
                            <button className="btn Seemore" onClick={(e) => preHandleSubmit(e)}>{showLoader && (<span class="spinner-border spinner-border-sm mx-2" role="status" aria-hidden="true"></span>)} {!showLoader ? "Upload" : " Loading"}</button>
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
}
export default BlogTemplates_Component;