import React from "react";
import { NavLink } from "react-router-dom";
import Title from "./Title";
import TradeTech from "../../images/oursolution-home.png";
import LogiTech from '../../images/sec-3a.png';
import InsureTech from '../../images/sec-3b.png';
import FinTech from '../../images/sec-3c.png';
import InspectTech from '../../images/sec-3d.png';

const OurSolution_Component = () => {
    return (
        <>
            <div className="mt-5 mb-5">
                <Title Title="Our Solutions" Description="We Are A Problem Solver" />
            </div>
            <div className="container text-center">

                <div className="row">
                    {Data.map((key, index) => {
                        return (
                            <div className="col-xl-3 col-lg-5 col-md-6 col-sm-8 my-2" key={index} >
                                <div className="card solutioncard" >
                                    <div className="card-header CardHeader " >
                                        <img className="img-fluid" src={key.Image} alt="Card image cap" />
                                    </div>
                                    <div className="card-body ">
                                        <h5 className="">{key.Heading}</h5>
                                        <p className="">{key.Para}</p>
                                        <div className="pb-3">
                                            <NavLink to={key.Link} className="text-decoration-none">Read more..</NavLink>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        );
                    })}

                </div>

            </div>

        </>
    );
}
export default OurSolution_Component;

const Data = [
    {
        Image: TradeTech,
        Heading: 'Trade-Tech',
        Para: 'Are you a Trader, Importer or Exporter, looking for advanced digital solutions to the age-old trade process?',
        Link: '/solutions/trade-tech'
    },
    {
        Image: LogiTech,
        Heading: 'Logi-Tech',
        Para: 'Are you a Shipping Company wanting to connect with trusted customers & achieve process efficiency with the latest technology.',
        Link: '/solutions/logi-tech'
    },
    {
        Image: InsureTech,
        Heading: 'Insure-Tech',
        Para: 'Are you an Insurance Company worried about the privacy of your data or the risk of false claims?',
        Link: '/solutions/insure-tech'
    },
    {
        Image: InspectTech,
        Heading: 'Inspect-Tech',
        Para: 'Are you an Inspection Agency troubled with the fast changing technology?',
        Link: '/solutions/inspect-tech'
    },
    {
        Image: FinTech,
        Heading: 'Fin-Tech',
        Para: 'Are you a Bank, Independent Investor or NBFC looking for ease of technology during payments?',
        Link: '/solutions/fin-tech'
    }
];