/* eslint-disable react/jsx-pascal-case */
import React from "react";
import CompAdavatage_Component from "../CompAdavtage_Component";
import FintechBanner_Component from "./FintechBanner_Component";
import ImageOne from "../../../images/Fintech/Image-benefits.png";
import SetProcess_Component from "./SetProcess_Component";
import FeaturesCarosuel_Component from "./FeaturesCarosuel_Component";
import OnBoarding_Component from "./OnBoarding_Component";

const List1 = [
  "Banks and financiers will have access to all documents uploaded by applicant.",
  "They can generate KYC report, Credit Analysis Report, Credit Rating on our platform which will be done by partnered third parties. ",
  "They can also use their own system for analysis. ",
  "These analysis will help to take decision on Finance Limit, Fund amount, interest rate, percentage of trade to be funded, other parameters and T&C.",
  "We also perform our own instantaneous Analysis report: AIA, to determine credit-worthiness of applicants, banks & Financier will also have access to that report.",
];

const FinTechLanding_Component = () => {
  return (
    <>
      <FintechBanner_Component />
      <CompAdavatage_Component CompImage={ImageOne} Data_List={List1}>
        <h2 className="fw-bold text-capitalize mb-4">
          Banks Can enhance their business on{" "}
          <span className="text-info"> our Marketplace. </span>{" "}
        </h2>
      </CompAdavatage_Component>
      <SetProcess_Component />
      <OnBoarding_Component />
      <FeaturesCarosuel_Component />
    </>
  );
};
export default FinTechLanding_Component;
