import React from "react";
import TradeTechBanner_Component from "./TradeTechBanner_Component";
import CompAdavatage_Component from "../CompAdavtage_Component";
import SetProcess_Component from "./SetProcess_Component";
import FeaturesCarosuel_Component from "./FeaturesCarosuel_Component";
import ImageOne from "../../../images/Trade-Tech Images/E-KYC-1.png";

const List = [
    "Our Contract management solutions helps to automate the execution of an agreement so that all participants can immediately come to certain outcome, without any intermediary’s involvement or time loss.",
];
const Points = [
    "Transparent and Auditable",
    "Secure, Private, and Indelible",
    "Reduces Cost, Risk & Delays Of Regulatory Processes",
    "Digitalized means Quicker"
]

const TradetechLanding_Component = () => {
    return (
        <>
            <TradeTechBanner_Component />
            <CompAdavatage_Component CompImage={ImageOne} Data_List={List} Points={Points}>
                <h2 className="fw-bold text-capitalize mb-4">Contract Management</h2>
            </CompAdavatage_Component>
            <SetProcess_Component />
            <FeaturesCarosuel_Component />
        </>
    );
}
export default TradetechLanding_Component;