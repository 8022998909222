/* eslint-disable react/jsx-pascal-case */
import React from "react";
import { Link } from "react-router-dom";
import HelpdeskContact_Component from "./HelpdeskContact_Component";
import HelpDeskForm_Component from "./HelpDeskForm_Component";

const HelpDeskSupportForm_Component = () => {
    return (
        <>
            <div className="container mb-5">
                <div className="col-12 mx-auto helpcompoenentbg">
                    <ul className="nav nav-pills NavPills mb-3 " id="pills-tab" role="tablist">
                        <li className="nav-item col-6 NavItem" role="presentation">
                            <Link className="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">Technical Support</Link>
                        </li>
                        <li className="nav-item col-6 NavItem" role="presentation">
                            <Link className="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Business Support</Link>
                        </li>
                    </ul>
                    <div className="tab-content" id="pills-tabContent">
                        <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                            <div className="row">
                                <div className="col-xl-6 col-lg-6 col-sm-12 col-md-12 mx-auto p-5">
                                    <HelpdeskContact_Component />
                                </div>
                                <div className="col-xl-6 col-lg-6 col-sm-12 col-md-12 m-auto p-5">
                                    <HelpDeskForm_Component helpDesk="technical" />
                                </div>
                            </div>

                        </div>
                        <div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                            <div className="row">
                                <div className="col-xl-6 col-lg-6 col-sm-12 col-md-12 m-auto p-5">
                                    <HelpDeskForm_Component helpDesk="bussiness" />
                                </div>
                                <div className="col-xl-6 col-lg-6 col-sm-12 col-md-12 m-auto p-5">
                                    <HelpdeskContact_Component />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        </>
    );
}
export default HelpDeskSupportForm_Component;