import React from "react";
import ImageOne from "../../../images/Image-28.png";
import ImageTwo from "../../../images/Image-29.png";
import ImageThree from "../../../images/Image-30.png";
import { Fade } from "react-reveal";


const SetProcess_Component = () => {
  return (
    <>
      <div id="sec-1" className="container-fluid" >
        <div className="container pb-5">
          <h3 className="text-center py-5 text-light">Setup Process</h3>
          <div className="col-xl-9 col-md-12 col-sm-12 col-lg-10 mx-auto">
            <div className="tab-content" id="pills-tabContent ">
              <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                {Data_Cards.map((key, index) => {
                  return (
                    <Fade left>
                      <div className="card SetprocessCard my-5" key={index}>
                        <div className="row text-light">
                          <div className="col-xl-6 col-lg-6 col-md-10 col-sm-10 m-auto setProcessText py-3 px-4">
                            <h5>{key.Heading} </h5>
                            <p>{key.Paragraph}</p>
                          </div>
                          <div className="col-xl-5 col-lg-5 col-md-12 col-sm-12 SetprocessImage">
                            <img src={key.Image} alt="Step One" height="" width="" className="img-fluid" />
                          </div>
                        </div>
                      </div>
                    </Fade>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default SetProcess_Component;


const Data_Cards = [
  {
    Heading: "Real Time Tracking",
    Paragraph: "TradeReboot platform provides real- time information on events and the status of shipment. Also our platform monitors performance history of carries and suppliers which will be helpful to create a trustworthy environment based on information of past performance.",
    Image: ImageOne,
  },
  {
    Heading: "LC Management",
    Paragraph: "TradeReboot have enabled L/C which may provide better document digitization because of its immutable functionality. When compared with traditional paper-intensive processing, our technology has the potential to present an innovative value from the perspective of cost and time reduction in financial processes.",
    Image: ImageTwo,
  },
  {
    Heading: "E-BL",
    Paragraph: "Our platform gives an edge to create electronic bill of lading the same way as a regular BL, adding cargo description, the port, the vessel, etc. The final document is then uploaded on our platform with an attached Title Registry Instruction (TRI) – the record of the eBL that ensures that the data cannot be changed or copied. The eBL, including a TRI, is then electronically signed and sent to the shipper for review. When an eBL is surrendered, a carrier receives an email, allowing them to release the cargo at the discharge port immediately upon receipt.",
    Image: ImageThree,
  },

];