import React from "react";
import SelectInput_Component from "../../Home/BookAppointment/SelectInput_Component";
import TextInput_Component from "../../Home/BookAppointment/TextInput_Component";

const BasicAccordion_Component = ({ basicDetails }) => {
    return (
        <>
            <div class="accordion py-4" id="accordionExample">
                <div class="accordion-item AccordionInsurance">
                    <h2 class="accordion-header" id="headingOne">
                        <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                            <b className="fs-5">Basic Details</b>
                        </button>
                    </h2>
                    <div id="collapseOne" class="accordion-collapse collapse show " aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                            <div className="row">
                                <div className="col-xl-3 col-lg-3 col-md-6 col-sm-10 mx-auto">
                                    <TextInput_Component Type="text" placeholder="Policy Status" label="Policy Status" id="status" name="policyStatus" value={basicDetails.policyStatus} onChange="" astrix="" disabledText='true' />
                                </div>
                                <div className="col-xl-3 col-lg-3 col-md-6 col-sm-10 mx-auto">
                                    <TextInput_Component Type="text" placeholder="Total Premium" label="Total Premium" id="totalPremium" name="totalPremium" value={basicDetails.totalPremium} onChange="" astrix="" disabledText='true' />
                                </div>
                                <div className="col-xl-3 col-lg-3 col-md-6 col-sm-10 mx-auto">
                                    <TextInput_Component Type="number" placeholder="Rack Rate" label="Rack Rate" id="rackRate" name="rackRate" value={basicDetails.rackRate} onChange="" astrix="" disabledText='true' />
                                </div>
                                <div className="col-xl-3 col-lg-3 col-md-6 col-sm-10 mx-auto">
                                    <TextInput_Component Type="number" placeholder="Rack Amount" label="Rack Amount" id="rackAmount" name="rackAmount" value={basicDetails.rackAmount} onChange="" astrix="" disabledText='true' />
                                </div>
                                <div className="col-xl-3 col-lg-3 col-md-6 col-sm-10 mx-auto">
                                    <TextInput_Component Type="number" placeholder="Net Rate" label="Net Rate" id="netRate" name="netRate" value={basicDetails.netRate} onChange="" astrix="" disabledText='true' />
                                </div>
                                <div className="col-xl-3 col-lg-3 col-md-6 col-sm-10 mx-auto">
                                    <TextInput_Component Type="number" placeholder="Stamp Duty" label="Stamp Duty" id="stampDuty" name="stampDuty" value={basicDetails.stampDuty} onChange="" astrix="" disabledText='true' />
                                </div>
                                <div className="col-xl-3 col-lg-3 col-md-6 col-sm-10 mx-auto">
                                    <TextInput_Component Type="number" placeholder="Net Premium" label="Net Premium" id="netPremium" name="netPremium" value={basicDetails.netPremium} onChange="" astrix="" disabledText='true' />
                                </div>
                                <div className="col-xl-3 col-lg-3 col-md-6 col-sm-10 mx-auto">
                                    <TextInput_Component Type="number" placeholder="Ninety Five Percent Premium" label="Ninety Five Percent Premium" id="ninetyFivePercentPremium" name="ninetyFivePercentPremium" value={basicDetails.ninetyFivePercentPremium} onChange="" astrix="" disabledText='true' />
                                </div>
                                <div className="col-xl-3 col-lg-3 col-md-6 col-sm-10 mx-auto">
                                    <TextInput_Component Type="number" placeholder="Ninety Five Percent Rate" label="Ninety Five Percent Rate" id="ninetyFivePercentRate" name="ninetyFivePercentRate" value={basicDetails.ninetyFivePercentRate} onChange="" astrix="" disabledText='true' />
                                </div>
                                <div className="col-xl-3 col-lg-3 col-md-6 col-sm-10 mx-auto">
                                    <TextInput_Component Type="number" placeholder="Five Percent Premium" label="Five Percent Premium" id="fivePercentPremium" name="fivePercentPremium" value={basicDetails.fivePercentPremium} onChange="" astrix="" disabledText='true' />
                                </div>
                                <div className="col-xl-3 col-lg-3 col-md-6 col-sm-10 mx-auto">
                                    <TextInput_Component Type="number" placeholder="Five Percent Rate" label="Five Percent Rate" id="fivePercentRate" name="fivePercentRate" value={basicDetails.fivePercentRate} onChange="" astrix="" disabledText='true' />
                                </div>
                                <div className="col-xl-3 col-lg-3 col-md-6 col-sm-10 mx-auto">
                                    <TextInput_Component Type="number" placeholder="PML Limit" label="PML Limit" id="pmlLimit" name="pmlLimit" value={basicDetails.pmlLimit} onChange="" astrix="" disabledText='true' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    );
}
export default BasicAccordion_Component;