import React from "react";
import LogiCards_Component from "./LogiCards_Component";
import Image1 from "../../../images/Solution Page images/Image-1.png";
import Image2 from "../../../images/Solution Page images/Image-2.png";
import Image3 from "../../../images/Solution Page images/Image-3.png";
import Image4 from "../../../images/Solution Page images/Image-4.png";
import Image5 from "../../../images/Solution Page images/Image-5.png";
import Image6 from "../../../images/Solution Page images/Image-6.png";
import Image7 from "../../../images/Solution Page images/Image-7.png";
import Image8 from "../../../images/Solution Page images/Image-8.png";
import Image9 from "../../../images/Solution Page images/Image-9.png";

const LogiTechSolution_Component = () => {
    return (
        <>
            <div className="container my-5">
                <h5 className="text-center fw-bold text-uppercase">Logi tech</h5>
                <h2 className="text-center fw-bold"> <span className="TextColor"> Logistic </span> Part of Trade</h2>
                <p className="text-center col-xl-7 col-lg-8 col-md-12 col-sm-12 my-2 text-secondary mx-auto">TradeReboot gives you the ability to innovate with access to trusted information from your entire logistics ecosystem.</p>
                <div className="col-xl-11 col-lg-11 col-sm-12 col-md-12 mt-5 mx-auto">
                    <div className="slider overflow-hidden position-relative" >
                        <div className="slide-track">
                            <div className="d-flex">
                                <LogiCards_Component Image={Image1} Paragraph="Increase event and document reliability" />
                                <LogiCards_Component Image={Image2} Paragraph="Build competitive advantage" />
                                <LogiCards_Component Image={Image3} Paragraph="Enhance partner collaboration" />
                                <LogiCards_Component Image={Image4} Paragraph="Manage documents digitally" />
                                <LogiCards_Component Image={Image5} Paragraph="Innovate labour intensive process" />
                                <LogiCards_Component Image={Image6} Paragraph="E-Bill of Lading" />
                                <LogiCards_Component Image={Image7} Paragraph="Migrating to API" />
                                <LogiCards_Component Image={Image8} Paragraph="Increase event and document reliability" />
                                <LogiCards_Component Image={Image9} Paragraph="Take Control of detection changess" />
                            </div>
                        </div>
                    </div>
                </div>                
            </div>
        </>
    );
}
export default LogiTechSolution_Component;