import React from "react";
import Pulse from 'react-reveal/Pulse';

const NewsEventsBanner_Component = () => {
    return (
        <>
            <div id="NewsBanner" className="container-fluid py-5 NewsBanner">
                <Pulse>
                    <div className="text-center ">
                        <div className=" text-light my-4">
                            <h1 className="text-capitalize">News & Events</h1>
                            <h6>Peoples Loves Us A Lot, Please Check Out Our Team Members</h6>
                        </div>
                    </div>
                </Pulse>
            </div>

        </>
    );
}
export default NewsEventsBanner_Component;