import React from "react";

import { Fade } from "react-reveal";
import LogiTechImage from "../../../images/Logi-tech/LOGI-TECH-IMG.gif";

const LogiBanner_Component = () => {
    return (
        <>
            <div className="container-fluid p-0" id="LogiTech-Bg" >

                <div className="col-xl-10 col-md-10 col-lg-10 col-sm-10 mx-auto py-5">
                    <div className="row">
                        <Fade left>
                            <div className="col-xl-6 col-lg-6 col-md-10 col-sm-10 m-auto my-5">
                                <h4 className="text-light fw-bold text-uppercase">Logi-Tech</h4>
                                <h3 className="text-light fw-bold"><span className="TextColor">Increase</span> event and document  <span className="TextColor">reliability</span> </h3>
                                <ul className="ListStyleImage mt-4">
                                    <li className="text-light fs-5">Logistic enables Shipper to send shipping quotation, make shipping contracts, manage laycan sheets, generate and manage digital Bill of lading</li>
                                    <li className="text-light fs-5 mt-3">Also, For Inspection agency, to receive service request, approve service request, send inspection details</li>
                                </ul>
                                <div className="pt-4">
                                    <a href="https://tech.tradereboot.com/login" target="_blank"> <button className="btn Seemore my-2 my-sm-0 ml-3" type="submit">Get Started</button></a>
                                </div>
                            </div>
                        </Fade>
                        <div className="col-xl-6 col-lg-6 col-md-10 col-sm-10 m-auto text-center ">
                            <Fade right>
                                <img src={LogiTechImage} alt="Competetive Image" className="img-fluid" />
                            </Fade>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default LogiBanner_Component;

