export default function validate(values) {

  let errors = {};

  if (!values.firstname) {
    errors.firstname = 'Field Missing'
  }
  if (!values.lastname) {
    errors.lastname = 'Field Missing'
  }
  if (!values.email) {
    errors.email = 'Field Missing';
  }
  if (!values.phone) {
    errors.phone = 'Field Missing';
  }
  if (!values.company_name) {
    errors.company_name = 'Field Missing';
  }
  if (!values.industry) {
    errors.industry = 'Field Missing';
  }
  if (!values.mentor) {
    errors.mentor = 'Field Missing';
  }
  if (!values.country) {
    errors.country = 'Field Missing';
  }

  console.log(errors);
  return errors;
}