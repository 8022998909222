import React from "react";
import Pulse from 'react-reveal/Pulse';

const ContactBanner_Component=()=>{
    return(
        <>
            <div id="ContactBanner-bg" className="container-fluid py-5 ">
                <div className="">
                    <Pulse>
                    <div className=" text-light text-center pt-5 ">
                        <h1 className="text-capitalize">Contact Us</h1>
                        <h6 className="col-xl-8 col-lg-9 col-sm-12 col-md-12 mx-auto">Whether You Are A Corporation, A Commodity Trader Or A Financial Institution Providing Solutions In Global Trade, We Would Love To Hear From You. </h6>
                    </div>
                    </Pulse>
                </div>
            </div>
        </>
    );
}
export default ContactBanner_Component;