export default function validate(values) {

  let errors = {};

  if (!values.heading) {
    errors.heading = 'Field Missing';
  }
  if (!values.description) {
    errors.description = 'Field Missing';
  }
  // if (!values.url) {
  //   errors.url = 'Field Missing';
  // }
  if (!values.postType) {
    errors.postType = 'Field Missing';
  }
  if (!values.doc) {
    errors.doc = 'Field Missing';
  }

  console.log(errors);
  return errors;
}