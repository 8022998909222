import React from "react";
import Image from "../../images/speaker.jpg";

const PageNotFound_Component = () => {

  return (
    <>
      <div className="container my-5">
        <div className="row">

          <div className="avatar text-center my-5 m-auto">
            <img src={Image} alt="Image" className="img-fluid" />
            <h3 className="my-3">COMING SOON</h3>
            {/* <p className="fs-5">Results Not Found</p> */}
            {/* <p className="fs-5 mt-3">The page you are looking for does not exist.
                            How you got here is a mystery. But you can click the button below
                            to go back to the homepage.
                        </p> */}
            {/* <div className="col-xl-5 mx-auto">
                            <button className=" mt-2 btn body-button">Go Back To Home</button>
                        </div> */}
          </div>
        </div>
      </div>
    </>
  );
}
export default PageNotFound_Component;
