import React from "react";

const LogiCards_Component = ({ Image, Paragraph }) => {
    return (
        <>
            <div className="col-xl-2 col-lg-2 col-md-5 col-sm-6 col-xs-6 mx-3 text-center cursor">
                <div className="card SolutionLogisection slide">
                    <div className="card-header CardHeader ">
                        <img src={Image} alt="Image" className="img-fluid " />
                    </div>
                    <div className="card-body ">
                        <p className="fw-bold">{Paragraph}</p>
                    </div>
                </div>
            </div>
        </>
    );
}
export default LogiCards_Component;