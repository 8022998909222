import React from "react";
import { Link } from "react-router-dom";


const TradePractices_Components = ({ CompImage, Heading, Paragraph, TechName, RedirectTo }) => {
    return (
        <>
            <div className="container py-5">
                <div className="col-xl-10 col-lg-11 col-sm-12 col-md-12 mx-auto">
                    <div className="row">
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 ">
                            <img src={CompImage} alt="Competetive Image" className="img-fluid" />
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 mx-auto mr-auto my-4">
                            <div className="col-xl-7 col-lg-7 col-md-10 col-sm-12 px-3 mx-auto">
                                <h6 className="text-info fs-5">{TechName}</h6>
                                <h4 style={{ fontSize: "32px" }}>{Heading}</h4>
                                <hr style={{ width: "20%", height: "2px" }} />
                                <p >{Paragraph}</p>
                                <div className="mt-5">
                                   <Link to={RedirectTo}> <button className="btn Readbtn">  Read More </button></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default TradePractices_Components;