import React from "react";
import { Fade } from "react-reveal";
import LogiTechImage from "../../../images/SolutionPage_Image.svg";

const SolutionBanner_Component = () => {
    return (
        <>
            <div className="container-fluid p-0" id="SolutionBanner-Bg" >
                <div className="col-xl-10 col-md-10 col-lg-10 col-sm-10 mx-auto py-5">
                    <div className="row">
                        <Fade left>

                            <div className="col-xl-6 col-lg-6 col-md-10 col-sm-10 m-auto ">
                                <h4 className="text-light fw-bold text-uppercase">Our solution</h4>
                                <h3 className="text-light fw-bold">We Are  <span className="TextColor"> Problem </span>Solver</h3>
                                <ul className="ListStyleImage mt-4">
                                    <li className="text-light fs-5">TradeReboot Is A Trade Tech Company And Provides Digitalized Solutions For The International Commodity Trade By Utilizing Technology Such As Blockchain, Artificial Intelligence, Machine Learning And By Integrating ICC Guidelines For Security Purposes.</li>
                                </ul>
                            </div>
                        </Fade>
                        <div className="col-xl-6 col-lg-6 col-md-10 col-sm-10 m-auto text-center ">
                            <Fade right>
                                <img src={LogiTechImage} alt="Competetive Image" className="img-fluid" />
                            </Fade>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default SolutionBanner_Component;