import React from "react";
import Inspection_Banner_Component from "./Inspection_Banner_Component";
import Inspection_Search_Component from "./Inspection_Search_Component";

const List = [
  "Our Contract management solutions helps to automate the execution of an agreement so that all participants can immediately come to certain outcome, without any intermediary’s involvement or time loss.",
];
const Points = [
  "Transparent and Auditable",
  "Secure, Private, and Indelible",
  "Reduces Cost, Risk & Delays Of Regulatory Processes",
  "Digitalized means Quicker"
]

const Inspection_Landing_Component = () => {
  return (
    <>
      <Inspection_Banner_Component />
      <Inspection_Search_Component />
    </>
  );
}
export default Inspection_Landing_Component;