import React from "react";
import { Fade } from "react-reveal";
import LogiTechImage from "../../../images/FIN-TECH-IMG.gif";

const FintechBanner_Component = () => {
  return (
    <>
      <div className="container-fluid p-0" id="Fintech-bg">
        <div className="col-xl-10 col-md-10 col-lg-10 col-sm-10 mx-auto py-5">
          <div className="row">
            <Fade left>
              <div className="col-xl-6 col-lg-6 col-md-10 col-sm-10 m-auto my-3">
                <h4 className="text-light fw-bold text-uppercase">Fin-Tech</h4>
                <h3 className="text-light fw-bold">
                  Ease Of Technology <span className="TextColor">During </span>
                  Payments
                </h3>
                <ul className="ListStyleImage mt-4">
                  <li className="text-light fs-5">
                    Fintech brings merchants and bankers together to address the
                    global trade finance gap of $1.5tn in global supply chain
                    financing.
                  </li>
                  <li className="text-light fs-5 mt-3">
                    It allows banks and NBFCs to provide export finance, LC
                    Discount, and freight financing to exporters and shipping
                    businesses to satisfy their working capital needs.
                  </li>
                  <li className="text-light fs-5 mt-3">
                    With our e-KYC function, we've created the platform to
                    assist banks and NBFCs in identifying trusted suppliers from
                    a worldwide supplier list based on their trustworthiness.
                  </li>
                </ul>
              </div>
            </Fade>
            <div className="col-xl-6 col-lg-6 col-md-10 col-sm-10 m-auto text-center ">
              <Fade right>
                <img
                  src={LogiTechImage}
                  alt="Competetive"
                  className="img-fluid"
                />
              </Fade>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default FintechBanner_Component;
