import { useCallback, useState } from "react";

const useNumber = (ArrayLeangth) => {
  const [number, setnumber] = useState(0);
  //Handler
  const increase_Handler = useCallback(() => {
    if (number >= ArrayLeangth) {
      setnumber(0);
    } else {
      setnumber((pre) => pre + 1);
    }
  }, [number]);
  //Handler
  const decrease_Handler = useCallback(() => {
    if (number <= 0) {
      setnumber(ArrayLeangth);
    } else {
      setnumber((pre) => pre - 1);
    }
  }, [number]);
  return { number, increase_Handler, decrease_Handler };
};

export default useNumber;
