/* eslint-disable react/jsx-pascal-case */
import React from "react";
import BlogCard_Component from "./BlogCard_Component";
import BlogOne from "../../images/blog1.png";
import BlogTwo from "../../images/youtube-thumb.jpg";


const NewsEventsCards_Component = () => {
    return (
        <>
            <div className="container my-5">
                <h3 className="text-center my-5 fw-bold">News Events</h3>
                <div className="slider overflow-hidden position-relative" >
                    <div className="slide-track">
                        <div className="d-flex">
                            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-10  ms-5 me-4">
                                <BlogCard_Component ImageBlog={BlogOne} HeadingBlog="UAE Trade" BlogParagraph="TradeTech UAE Will Be Launching A Global Blockchain Platform For Commodity Trade In Q2 Of 2020. UNLOCK Interviewed Mr.Dheeraj Khandelwal," RedirectLink="https://www.unlock-bc.com/news/2020-04-21/tradetech-uae-to-launch-global-blockchain-commodity-trading-platform" />
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-10 me-4">
                                <BlogCard_Component ImageBlog={BlogTwo} HeadingBlog="Zee Business" BlogParagraph="TradeReboot launched its platform in October 2020 on it's first global conference inaugurated by Mansukh Mandaviya. It was covered by Zee Business with a huge viewership," RedirectLink="https://www.youtube.com/watch?v=7QFRlIZapME" />
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-10  me-4">
                                <BlogCard_Component ImageBlog={BlogOne} HeadingBlog="UAE Trade" BlogParagraph="TradeTech UAE Will Be Launching A Global Blockchain Platform For Commodity Trade In Q2 Of 2020. UNLOCK Interviewed Mr.Dheeraj Khandelwal," RedirectLink="https://www.unlock-bc.com/news/2020-04-21/tradetech-uae-to-launch-global-blockchain-commodity-trading-platform" />
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-10 me-4">
                                <BlogCard_Component ImageBlog={BlogTwo} HeadingBlog="Zee Business" BlogParagraph="TradeReboot launched its platform in October 2020 on it's first global conference inaugurated by Mansukh Mandaviya. It was covered by Zee Business with a huge viewership," RedirectLink="https://www.youtube.com/watch?v=7QFRlIZapME" />
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default NewsEventsCards_Component;