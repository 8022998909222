/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { NavLink } from "react-router-dom";
import Logo from "../../../images/LogoWithName.png";
import profile from "../../../images/News Blog Page/Profile-img.png";
import IconLinkedin from "../../../images/News Blog Page/Linkedin.svg";
import IconTwitter from "../../../images/News Blog Page/Twitter.svg";
import IconFb from "../../../images/News Blog Page/Facebook.svg";
import logout from "../../../images/Navbar Icons/login.svg";

const BlogNav_Component = () => {
  return (
    <>
      <div className="container-fluid" id="sec-1">
        <header className="navbar-expand-lg  d-flex flex-wrap align-items-center justify-content-center justify-content-md-between mx-4">
          <NavLink
            to="/"
            className="d-flex align-items-center col-md-3 mb-2 mb-md-0 text-dark text-decoration-none"
          >
            <img src={Logo} width="142" height="70" alt="Logo TradeReboot" />
          </NavLink>

          <ul className="nav col-12 col-md-auto mb-2 align-items-center justify-content-center mb-md-0">
            <li className="nav-item">
              <NavLink to="/" className="nav-link px-4 nav-menu">
                <img src={IconLinkedin} alt="" className="me-2 mb-1" />
                Linkedin
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/" className="nav-link px-4 nav-menu">
                <img src={IconFb} alt="" className="me-2 mb-1" />
                Facebook
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/" className="nav-link px-4 nav-menu">
                <img src={IconTwitter} alt="" className="me-2 mb-1" />
                Twitter
              </NavLink>
            </li>
          </ul>

          <div className="col-md-3 text-end dropdown">
            <NavLink
              to="/"
              className=" me-2 "
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <img src={profile} alt="" />
            </NavLink>
            <ul
              class="dropdown-menu mt-3"
              aria-labelledby="dropdownMenuButton1"
            >
              <li>
                <a class="dropdown-item" href="#">
                  <img src={logout} alt="" /> Logout
                </a>
              </li>
            </ul>
          </div>
        </header>
      </div>
    </>
  );
};
export default BlogNav_Component;
